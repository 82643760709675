import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { deleteProductCatalogue } from "../../api/CatalogueApi";
import { getAllProducts, getSearchedOfferProduct } from "../../api/Product";
import MultiOfferModal from "../../components/ModalComponenr/MultiOfferModal";
import Pagination from "../../components/Pagination/Pagination";
import useProductCtx from "../../hooks/useProductCtx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

//CSS
import "./CateloguePage.css";

// Component definition
const CataloguePage = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const { allProducts, setAllProducts, currentPage, setCurrentPage } = useProductCtx();

  const [searchText, setSearchText] = useState("");
  const [productsToShow, setProductsToShow] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;
  const [totalProducts, setTotalProducts] = useState(1);

  useEffect(() => {
    let prods;
    prods = [...allProducts.products];
    setProductsToShow(prods);
  }, [allProducts.loaded, allProducts]);

  useEffect(() => {
    getSearchedOfferProduct(`limit=${productsPerPage}&page=${currentPage}`).then((res) => {
      setAllProducts({
        loaded: true,
        no_of_products: res.no_of_products,
        products: res.products,
      });
      setTotalProducts(res.total_products);
    });
  }, [currentPage]);

  const handleDeleteProduct = async (product) => {
    let text = `Are you sure you want to delete ${product?.item?.title}`;
    if (window.confirm(text) === true) {
      deleteProductCatalogue(product?.item?._id).then((res) =>
        res
          ? getSearchedOfferProduct(`limit=${productsPerPage}&page=${currentPage}`).then((res) => {
              setAllProducts({
                loaded: true,
                no_of_products: res.no_of_products,
                products: res.products,
              });
              setTotalProducts(res.total_products);
            })
          : ""
      );
      alert(`${product?.item?.title} is Deleted`);
    } else {
      alert("Product Not Deleted");
    }
  };

  const handleSearchProduct = (e) => {
    e.preventDefault();
    if (searchText) {
      if (isNaN(searchText)) {
        let searchTerm = "search=" + searchText;
        getSearchedOfferProduct(searchTerm)
          .then((res) => {
            setProductsToShow(res.products);
            setTotalProducts(res.total_products);
          })
          .catch((err) => setProductsToShow([]));
      } else {
        let searchTerm = "ean=" + searchText;
        getSearchedOfferProduct(searchTerm)
          .then((res) => {
            setProductsToShow(res.products);
            setTotalProducts(res.total_products);
          })
          .catch((err) => setProductsToShow([]));
      }
    } else {
      getSearchedOfferProduct(`limit=${productsPerPage}&page=${currentPage}`)
        .then((res) => {
          setProductsToShow(res.products);
          setTotalProducts(res.total_products);
        })
        .catch((err) => setProductsToShow([]));
    }
  };

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();
  return (
    <>
      <Outlet />
      <div className="">
        <button
          onClick={() => navigate(-1)}
          style={{
            all: "unset",
            marginBottom: "25px",
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} /> Product Page
        </button>
        <div className="catelogue_Page section_Wrapper">
          <div className="catelogue_Page_Header">
            <h4 className="catelogue_Page_Heading">Catelogue Page</h4>
            <div className="catelogue_Header_Buttons">
              <Link to={"/admin/catelogue-page/add-offers"} className={"button-Container"}>
                <button type="submit" className="submit-button">
                  <p>Add Multiple Offers</p>
                </button>
              </Link>
            </div>
          </div>
          <br />
          <div className="d-flex catelogue_Search_Input">
            <input type="text" className="input-field" placeholder="Search EAN Number" onChange={(e) => setSearchText(e.target.value)} />
            <div className={"button-Container"}>
              <button type="submit" className="submit-button" onClick={handleSearchProduct}>
                <p>Search Product</p>
              </button>
            </div>
          </div>
          <br />
          <div className="catelogue_Page_List">
            {allProducts.loaded ? (
              allProducts.no_of_products > 0 ? (
                productsToShow.length > 0 ? (
                  <>
                    {productsToShow.map((item, index) => (
                      <>
                        <div className="catalogue_List_Item" key={index}>
                          <div className="catalogue_List_Content">
                            {item.ean && <p>{item.ean}</p>}
                            {item.title && <p>{item.title}</p>}
                            {/* {product.price.mop && (<p>{product.price.mop}</p>)} */}
                          </div>
                          <div className="catalogue_List_Buttons">
                            <Link to={"/admin/catelogue-page/add-offers"} state={(item = { item })} className="catalogue_Edit">
                              Edit
                            </Link>
                            <div className="catalogue_Delete" onClick={() => handleDeleteProduct(item)}>
                              Delete
                            </div>
                          </div>
                        </div>
                        <div className="pagination_Container"></div>
                      </>
                    ))}
                    <Pagination productsPerPage={productsPerPage} totalProducts={totalProducts} pageChange={handlePageChange} currentPage={currentPage} />
                  </>
                ) : (
                  <div>No Such Product Exists In Catelogue </div>
                )
              ) : (
                <div>No Products in Database</div>
              )
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>
      <MultiOfferModal allProducts={allProducts} modalShow={modalOpen} setModalShow={setModalOpen} />
    </>
  );
};

export default CataloguePage;
