import React from "react";
import { ListGroup } from "react-bootstrap";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { currencyFormat } from "../utils";

function CouponModal(props) {
  return (
    <Dialog open={props.show} onClose={props.onHide} scroll={"body"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" className="coupn-dialog-box">
      <DialogTitle id="scroll-dialog-title d-flex justify-content-between" style={{ alignItems: "center", justifyContent: "space-between", display: "flex" }}>
        Active Coupons
        <IconButton aria-label="close" className="close-button" onClick={props.onHide}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          overflowY: "initial",
        }}
      >
        <DialogContentText>
          {props.coupons.length > 0 ? (
            <div className="">
              {props.coupons.map((item, index) => (
                <div key={index} className="coupon-list-item d-flex align-items-center cursor-pointer p-1" onClick={() => props.updateCoupon(item._id)}>
                  <input style={{ width: "auto" }} checked={item.status === "applied"} type="checkbox" aria-label="Checkbox for following text input" className="mr-2" />
                  <div className="coupon-info" style={{ color: `${item.status === "applied" ? "green" : ""}` }}>
                    <span className="coupon-title">Save {currencyFormat(item.value)}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              <p>Opps! No Coupon Available</p>
            </div>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default CouponModal;
