import React from "react";
import NothingImage from "../../assets/png/your_order_image.png";
import Rating from "@mui/material/Rating";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { currencyFormat } from "../../utils";
import { CircularProgress } from "@mui/material";
// Utils
import { CANCELLED_STATUSES } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

function YourOrderCart({ orderDetails, loading, isMobile, totalOrders, trackOrder, cancelOrder, statusSelected, handleInvoiceDownload, handleOpen, productRating }) {
  const { t } = useTranslation();
  /* const getProductPrice = (product, quantity = 1) => {
        let price = product?.price?.mop;
        return price * quantity;
    } */
  const getProdMrpPrice = (product, quantity = 1) => {
    let price = parseFloat(product?.price?.mrp);
    return price * quantity;
  };

  const getOnsiteGoCertificates = (data) => {
    const parsedData = data;

    if (parsedData.plans.length) {
      return parsedData.plans.map((plan) => {
        return (
          <a href={plan?.certificate?.toString()?.replace("http:", "https:")} key={plan.osid} className="onsitego-text d-inline-block me-2" download>
            {parsedData.plan_invoice_number + "_" + plan.plan_id + "_" + plan.osid}
            {/**/}
          </a>
        );
      });
    }

    return "";
  };

  const navigate = useNavigate();

  const handleCreateInvoice = (consg) => {
    const queryParams = new URLSearchParams();
    queryParams.set("id", consg._id);
    const url = `/invoice?${queryParams.toString()}`;
    const newWindow = window.open(url, "_blank");
    if (!newWindow) {
      // Fallback to navigating in the same tab if opening in new tab is blocked
      navigate("/invoice");
    }
  };

  // Main renderer
  return loading ? (
    <div className="loader-div">
      <CircularProgress color="inherit" />
    </div>
  ) : (
    orderDetails.map((placedOrders, index) => (
      <div className="your_order_cart" key={"orderPlaced-" + index}>
        <p className="text-capitalize">
          {t("order-page.orderId")}: {placedOrders.orderId}
        </p>
        {placedOrders.consignments?.map((consg, i) => (
          <>
            <div
              key={placedOrders._id + i}
              style={{
                marginBottom: isMobile ? "1rem" : "",
              }}
            >
              <div className="your_order_cart_detailed d-flex flex-column" style={{ gap: "30px" }}>
                <div className="d-flex flex-row" style={{ height: "180px", width: "470px", alignItems: "center", display: "flex", justifyContent: "center" }}>
                  <Link to={`/product/${consg?.product?.slug}`} className="your_order_cart_detailed_1" style={{ height: "100%", alignItems: "center", display: "flex", justifyContent: "center" }}>
                    <img src={consg?.product?.images?.[0] ?? NothingImage} alt={`yourCart-${i}`} />
                  </Link>
                  <div className="">
                    <Link to={`/product/${consg?.product?.slug}`} className="your_order_cart_detailed_2">
                      <h1 className="your_order_name">{consg?.product?.title}</h1>
                      <div className="your_order_cart_detailed_2_container">
                        <div className="your_order_cart_detailed_2_div">
                          <h1>
                            {t("color")}: {consg?.product?.color}
                          </h1>
                          <h1>
                            {t("quantity")}: {consg?.quantity}
                          </h1>
                        </div>

                        {isMobile && (
                          <div className="your_order_cart_detailed_3">
                            {getProdMrpPrice(consg?.product, consg?.quantity) > consg?.computed_selling_price * consg?.quantity && (
                              <p className="text-decoration-line-through original-price">{currencyFormat(getProdMrpPrice(consg?.product, consg?.quantity))}</p>
                            )}
                            <h1>{currencyFormat(consg?.computed_selling_price * consg?.quantity)}</h1>
                          </div>
                        )}
                      </div>
                    </Link>
                    <div className="ms-2">
                      <Rating name="read_only" value={productRating?.find((e) => e._id === consg?.product?._id)?.rating ?? 0} readOnly sx={{ fontSize: isMobile ? "17px" : "" }} />
                      <p>
                        {t("orderAt")}: {moment(consg?.createdAt).fromNow()}
                      </p>
                    </div>
                  </div>
                </div>
                {/** UI for child product in combo offer for this consignment */}
                {consg?.combo_child_product && (
                  <div className="your_order_cart_detailed" style={{ justifyContent: "center" }}>
                    <Link to={`/product/${consg?.product?.slug}`} className="your_order_cart_detailed_1" style={{ height: !isMobile ? "200px" : "" }}>
                      <img src={consg?.combo_child_product?.images?.[0] ?? NothingImage} alt={`yourCart-${i}`} />
                      <div className=" tag-best-seller fs-6 mt-2 d-flex justify-content-center text-center combo-product-text">{t("ComboProduct")}</div>
                    </Link>
                    <Link to={`/product/${consg?.product?.slug}`} className="your_order_cart_detailed_2" style={{ marginRight: "20px" }}>
                      <h1 className="your_order_name">{consg?.combo_child_product?.title}</h1>
                      <div className="your_order_cart_detailed_2_container">
                        <div className="your_order_cart_detailed_2_div">
                          <h1>
                            {t("color")}: {consg?.combo_child_product?.color}
                          </h1>
                          <h1>{t("quantity")}: 1 </h1>
                          <h1 className="text-decoration-line-through">{currencyFormat(consg?.combo_child_product?.price?.mrp)}</h1>
                          <Rating name="read_only" value={productRating.find((e) => e._id === consg?.combo?.product)?.rating ?? 0} readOnly sx={{ fontSize: isMobile ? "17px" : "" }} />
                        </div>
                        {isMobile && (
                          <div className="your_order_cart_detailed_3">
                            <h1>{currencyFormat(consg?.combo_child_price)}</h1>
                          </div>
                        )}
                      </div>
                    </Link>
                    {!isMobile && (
                      <div className="your_order_cart_detailed_3 ms-4">
                        <h1>{currencyFormat(consg?.combo_child_price)}</h1>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div>
                {!isMobile && (
                  <div className="your_order_cart_detailed_3 ms-4">
                    {getProdMrpPrice(consg?.product, consg?.quantity) > consg?.computed_selling_price * consg?.quantity && (
                      <p className="text-decoration-line-through original-price">{currencyFormat(getProdMrpPrice(consg?.product, consg?.quantity))}</p>
                    )}
                    <h1>{currencyFormat(consg?.computed_selling_price * consg?.quantity)}</h1>
                  </div>
                )}
              </div>

              {!CANCELLED_STATUSES.includes(consg.status) && (
                <div className="your_order_cart_buttons">
                  <div className="your_order_cart_buttons_1">
                    <button
                      type="submit"
                      className="your_order_submit_button"
                      style={{
                        background: "#1B325E",
                        maxWidth: "300px",
                      }}
                      onClick={() => trackOrder(`${consg?.order}-${consg?.product?._id}`)}
                    >
                      <p
                        style={{
                          color: "white",
                        }}
                      >
                        {t("order-page.trackPackage")}
                      </p>
                    </button>
                    {consg?.status === "DELIVERY_SUCCESS" && <p className="text-success text-bold text-uppercase delivered-text">{t("delivered")}</p>}
                    {consg?.status !== "DELIVERY_SUCCESS" && (
                      <button className="your_order_cancel_button">
                        <CancelPresentationIcon
                          style={{
                            fontSize: "24px",
                            fontWeight: "200",
                          }}
                        />{" "}
                        &nbsp;
                        <span className="your_order_cart_buttons_1_cancel" onClick={() => cancelOrder(`${consg?.order}-${consg?.product?._id}`)}>
                          {t("order-page.cancelItem")}
                        </span>
                      </button>
                    )}
                  </div>

                  <div className="d-flex justify-content-center align-items-center " style={{ flex: 1 }}>
                    {consg?.voucherId ? (
                      <a
                        style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
                        // href={`${process.env.REACT_APP_PDFURL}/${consg?.voucherId}.pdf`}
                        // target="_blank"
                        // download="invoice.pdf"
                        // rel="noreferrer"
                        onClick={
                          () => handleCreateInvoice(consg)

                          // window.open(`${process.env.REACT_APP_PDFURL}/${details.voucherId}.pdf`, "_blank");
                        }
                      >
                        <div className="your_cart_downloadicon_container">
                          <FileDownloadIcon className="your_cart_downloadicon" />
                        </div>
                        <div className="your_order_cart_buttons_2_text">
                          <p className="cart_texts">{t("order-page.download")}</p>
                          <p className="cart_texts">{t("order-page.invoice")}</p>
                        </div>
                      </a>
                    ) : (
                      <div className="text-center font-weight-bold invoice-not-generated-text">{t("order-page.invoiceNotGenerated")}</div>
                    )}
                  </div>
                </div>
              )}

              {CANCELLED_STATUSES.includes(consg.status) && (
                <div className={!consg?.combo_child_product ? "package-canceled" : "your_order_cart_buttons package-canceled w-auto justify-content-center"}>
                  <strong className="mt-1">{t("order-page.packageCancelled")}</strong>
                </div>
              )}
            </div>
            {consg.status === "DELIVERY_SUCCESS" && (
              <div
                style={{
                  fontSize: "1rem",
                }}
                onClick={() => handleOpen(consg.product._id, consg?.consignor)}
                className="review_btn"
              >
                {t("shareYourReview")}
              </div>
            )}
            {consg?.onsitego_data?.length > 0 && (
              <div className="d-flex flex-column">
                <h6 className="mb-0 onsitego-heading">{t("OnsitegoCertificates")}</h6>
                {consg?.onsitego_data?.map((data, idx) => (
                  <div key={idx} className={!isMobile ? "mt-2" : ""}>
                    {getOnsiteGoCertificates(data)}
                    <span> - {data?.planDetails?.productType}</span>
                  </div>
                ))}
              </div>
            )}
            {placedOrders?.has_onsitego?.length > 0 && (
              <div className="d-flex flex-column">
                {placedOrders?.has_onsitego?.map((data, idx) => (
                  <>
                    {data?.product?._id === consg?.product?._id && (
                      <>
                        <h6 className="mb-0 onsitego-heading">ONSITEGO Added</h6>
                        <div key={idx} className={!isMobile ? "mt-2" : ""}>
                          {/* <div>Amount :- {data.planAmount}</div> */}
                          <b> Warranty :-</b> {data.planTitle}
                        </div>
                      </>
                    )}
                  </>
                ))}
                {i !== placedOrders.consignments.length - 1 && <hr />}
              </div>
            )}
          </>
        ))}
      </div>
    ))
  );
}

export default YourOrderCart;
