// top level imports
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FormControlLabel, Switch } from "@mui/material";

// Components
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import Pagination from "../../components/Pagination/Pagination";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";

// API calls
import { bulkDeleteEans, deleteProductAPI, getNewEans, getSearchedProduct, saveEanToMaster, updateEan } from "../../api/Product";

// Utils
import { currencyFormat, truncatedText } from "../../utils";
import { Button } from "react-bootstrap";
import { DEFAULT_GST_PERCENTAGE } from "../../utils/Constants";
import { Link, useLocation, useNavigate } from "react-router-dom";

// Component definition
export function NewEan() {
  // Constants

  // state definitions
  const [allProducts, setAllProducts] = useState([]);
  const [selectedEan, setSelectedEan] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEans, setTotalEans] = useState(1);
  const [loading, setLoading] = useState(false);

  // ComponentDidMount
  useEffect(() => {
    fetchNewEans();
  }, [currentPage]);

  /** Handler functions - starts */
  const location = useLocation();

  const deletProduct = async (id) => {
    let text = "Are you sure for delete?";
    if (window.confirm(text) === true) {
      deleteProductAPI(id).then((res) => {
        if (res?.status === 401) {
          toast.error("Unauthorized");
          localStorage.removeItem(process.env.REACT_APP_ADMIN_TOKEN_KEY);
          nav("/admin-login");
        } else setAllProducts(allProducts.filter((message) => message._id !== id));
      });
    }
  };

  const productsPerPage = 30;
  const fetchNewEans = async () => {
    showLoader();
    try {
      const params = new URLSearchParams(location.search);
      if (params.get("search") === "") return;

      // if (sortingName === "descending") {
      //   params.append("sort", "descending");
      // }
      // if (sortingName === "ascending") {
      //   params.append("sort", "ascending");
      // }
      params.append("limit", productsPerPage);
      params.append("page", currentPage);
      params.append("isLive", false);

      //   if (searchText && isNaN(searchText)) {
      //     params.append("search", searchText);
      //   }

      getSearchedProduct(params).then((res) => {
        const products = res.products;
        console.log(products);
        setAllProducts(products);
        setTotalEans(res.total_eans);
      });
    } finally {
      hideLoader();
    }
  };

  //   const onEanEdit = (ean) => {
  //     setSelectedEan(newEans.find((el) => el.ean === ean));
  //   };

  // saves new EAN product into master
  const onEanSave = async (newProd) => {
    showLoader();
    try {
      const response = await saveEanToMaster(newProd);
      const payload = response.data;
      if (payload.status === "success") {
        toast.success("Product added to master successfully!");
        fetchNewEans();
      }
    } finally {
      hideLoader();
    }
  };

  const handleEanInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedEan((prev) => ({ ...prev, [name]: value }));
  };

  const updateNewEans = async () => {
    const toBeUpdatedEan = selectedEan;
    if (toBeUpdatedEan.gstpercentage < 18 || toBeUpdatedEan.gstpercentage > 28) {
      toast.error("Invalid GST rate");
      return;
    }

    setSelectedEan(null);
    showLoader();
    try {
      const response = await updateEan(toBeUpdatedEan);
      const payload = response.data;
      if (payload.status === "updated") {
        toast.success("Product info updated successfully!");
        fetchNewEans();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message ?? "Something went wrong!");
    } finally {
      hideLoader();
    }
  };

  const showLoader = () => setLoading(true);

  const hideLoader = () => setLoading(false);

  // copies reference url to clipboard
  const copyReferenceUrl = (reference_url) => {
    navigator.clipboard.writeText(reference_url);
    toast.info("Reference url copied to clipboard", {});
  };

  async function handleBulkDeleteEans() {
    try {
      if (window.confirm("Confirm?")) {
        showLoader();
        const response = await bulkDeleteEans();
        const payload = await response.data;
        if (payload.status === "deleted") {
          fetchNewEans();
          toast.success("Eans Deleted Successfully");
        }
      }
    } catch (error) {
      toast.error("Something Went Wrong");
    } finally {
      hideLoader();
    }
  }

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  /** Handler functions - ends */

  const nav = useNavigate();

  // Main renderer
  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-4">
        <h4>New Product OPINS</h4>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered " style={{ fontSize: "small", overflowX: "auto" }}>
          <thead>
            <tr style={{ width: "100%", border: "1px solid", background: "#e9ecef" }}>
              <th scope="col" style={{ background: "#e9ecef" }}>
                Sr. No.
              </th>
              <th scope="col">Name</th>
              <th scope="col">OPIN</th>
              <th scope="col">Price MRP</th>
              <th scope="col">Price MOP</th>
              <th scope="col">Uploaded At</th>
              <th scope="col">Actions</th>
              <th scope="col">Approved Status</th>
            </tr>
          </thead>
          <tbody>
            {allProducts?.length > 0 &&
              allProducts?.map((item, index) => (
                <tr key={index} className={`${!item.goLive ? "bg-light" : ""}`} style={{ border: "1px solid" }}>
                  <td>{(currentPage - 1) * productsPerPage + index + 1}</td>
                  <td style={{ width: "320px" }} className={`title ${!item.goLive ? "bg-light" : ""}`}>
                    {item?.title}
                  </td>
                  <td>{item?.ean}</td>
                  <td>{currencyFormat(item?.price?.mrp)}</td>
                  <td>{currencyFormat(item?.price?.mop)}</td>
                  <td style={{ width: "100px" }}>
                    {item?.createdAt
                      ? new Intl.DateTimeFormat("en-IN", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "Asia/Calcutta",
                        }).format(new Date(item?.createdAt))
                      : "-"}
                  </td>

                  <td>
                    <Link to={"/admin/admin-add-product"} state={(item = { item })} className="btn p-0">
                      <FontAwesomeIcon className={"table-icon"} icon={faPenToSquare} />
                    </Link>
                    <button className="btn p-0 " onClick={() => deletProduct(item.item._id)}>
                      <FontAwesomeIcon className={"table-icon text-danger"} icon={faTrashCan} />
                    </button>
                  </td>
                  <td>{/* <FormControlLabel control={<Switch checked={item?.goLive} />} onChange={(e) => handleGoLiveToggle(item?._id, e, item?.goLive)} /> */}</td>
                </tr>
              ))}
          </tbody>
          {/* {!allProducts.length && (
                  <tbody>
                    <tr>
                      <td className="text-center" colSpan={6}>
                        No products found!
                      </td>
                    </tr>
                  </tbody>
                )} */}
        </table>
      </div>

      <div className="pagination_Container mt-2">
        <Pagination productsPerPage={productsPerPage} totalProducts={totalEans} pageChange={handlePageChange} currentPage={currentPage} />
      </div>

      {selectedEan?.ean && (
        <div className="mt-4">
          <h4>Edit EAN</h4>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="ean" className="form-label">
                EAN
              </label>
              <input type="number" className="form-control" id="ean" name="ean" value={selectedEan?.ean} disabled />
            </div>
            <div className="col-md-6">
              <label htmlFor="product_name" className="form-label">
                Product Name
              </label>
              <input type="text" className="form-control" id="product_name" name="product_name" value={selectedEan?.product_name} onChange={handleEanInputChange} />
            </div>
            <div className="col-md-6">
              <label htmlFor="hsn" className="form-label">
                HSN
              </label>
              <input type="text" className="form-control" id="hsn" name="hsn" value={selectedEan?.HSN} disabled />
            </div>
            <div className="col-md-6">
              <label htmlFor="reference_url" className="form-label">
                Reference URL
              </label>
              <input type="text" className="form-control" id="reference_url" name="reference_url" value={selectedEan?.reference_url ?? ""} onChange={handleEanInputChange} />
            </div>

            <div className="col-md-6">
              <label htmlFor="gstpercentage" className="form-label">
                GST rate
              </label>
              <input type="number" min="18" max="28" className="form-control" id="gstpercentage" name="gstpercentage" value={selectedEan?.gstpercentage ?? ""} onChange={handleEanInputChange} />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12 text-end">
              <button className="btn btn-success me-3" onClick={updateNewEans}>
                Update
              </button>
              <button className="btn btn-danger" onClick={() => setSelectedEan(null)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
