import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./styles/dashAddproduct.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import ScrollBanner from "../../components/AddHomePageSection/ScrollBanner";
import ScrollCard from "../../components/AddHomePageSection/ScrollCard";
import StaticCard from "../../components/AddHomePageSection/StaticCard";
import StaticBanner from "../../components/AddHomePageSection/StaticBanner";
import StaticDeals from "../../components/AddHomePageSection/StaticDeals";

// toast.configure();

// Component definition
const DashboardAddHomeSection = () => {
  const nav = useNavigate();
  const location = useLocation();

  return (
    <>
      {true && (
        <div className="container">
          <button
            onClick={() => {
              nav("../admin-home-page");
            }}
            style={{ all: "unset", cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faChevronLeft} /> Back
          </button>

          <section style={{ minHeight: "200px", marginTop: "30px" }}>
            {location.state.type === "static_banner" && <StaticBanner />}
            {(location.state.type === "scroll_banner" || location.state.type === "static_poster") && <ScrollBanner />}
            {(location.state.type === "scroll_card" || location.state.type === "scroll_card_mobile") && <ScrollCard />}
            {/* {location.state.type === "static_card" && <StaticCard />} */}
            {(location.state.type === "static_deals" || location.state.type === "static_deals_mobile") && <StaticDeals />}
          </section>
        </div>
      )}
    </>
  );
};

export default DashboardAddHomeSection;
