import React, { useEffect, useMemo, useRef, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { deletPoint, getAllTerms, saveOrUpdateTerms } from "../../api/AdminApis/Admin.terms";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { FaTrashAlt } from "react-icons/fa";
import "./styles/dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import JoditEditor from "jodit-react";

function TermsAndCondtions() {
  const [heading, setHeading] = useState("");
  const [points, setPoints] = useState([""]);
  const [newSection, setNewSection] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getTermsAndCondition();
  }, []);
  const getTermsAndCondition = async () => {
    const terms = await getAllTerms();
    if (terms) {
      setData(terms?.terms);
    }
  };

  const handleHeadingChange = (index, text) => {
    const newHeading = [...data];
    newHeading[index].heading = text;
    // setPoints(newPoints);
    setData(newHeading);
  };

  const handleSubmit = async () => {
    const data = {
      heading: heading,
      points: content, // Remove empty points
    };
    console.log(data);

    try {
      const res = await saveOrUpdateTerms([data]);
      if (res) {
        if (res.status === "success") {
          toast.success(res?.message);
          setNewSection(false);
          setContent("");
          setHeading("");
          getTermsAndCondition();
        } else {
          toast.error(res?.data.message + " error");
        }
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleUpdate = async () => {
    const udpatedData = [...data];
    console.log(udpatedData); // Send data to backend or perform other actions here
    try {
      const res = await saveOrUpdateTerms(udpatedData);
      if (res) {
        if (res.status === "success") {
          toast.success(res?.message);
          getTermsAndCondition();
        }
      }
    } catch (error) {
      toast.error(error?.message);
      console.log("error ", error);
    }
  };

  const handleDeleteHeading = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this terms and conditions!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#a5a5a5",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Call function to delete terms and conditions
        deleteTermsAndConditions(id);
      } else {
        return;
      }
    });
  };

  const deleteTermsAndConditions = async (id) => {
    const obj = { id };
    try {
      const res = await deletPoint(obj);
      if (res.status === "success") {
        toast.success(res?.message);
        getTermsAndCondition();
      }
    } catch (error) {
      console.log("error ", error);
    }
  };
  // get All Terms Condition (get) =>     /admin/termsAndConditions
  // save or update All Terms Condition  (post)=>     /admin/termsAndConditions

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "Start typing...",
    }),
    []
  );

  const handleChangePoint = (text, term) => {
    setData((prevData) =>
      prevData.map((item) => {
        if (item._id === term._id) {
          return { ...item, points: text }; // Update points property for matching term
        }
        return item; // Return unchanged item if not matching
      })
    );
  };

  return (
    <div>
      {newSection ? (
        <>
          <div>
            <div className="d-flex w-100 mb-3">
              <span className="cursor-pointer mx-2">
                <FontAwesomeIcon icon={faChevronLeft} onClick={() => setNewSection(false)} />
              </span>
              Go Back
            </div>
            <label className="font-bold" htmlFor="heading">
              New Heading:
            </label>
            <input className="p-1" type="text" id="heading" value={heading} onChange={(e) => setHeading(e.target.value)} />
            <br />
            <label htmlFor="points">New Points:</label>
            {/* {points.map((point, index) => (
              <div key={index} className="d-flex m-1 align-items-center">
                <textarea style={{ height: "140px" }} className="p-2" type="text" value={point} onChange={(e) => handlenewPointChange(index, e.target.value)} />
                <span className="cursor-pointer justify-content-center align-items-center " onClick={() => handleDeletePoint(index)}>
                  <FaTrashAlt style={{ color: "red" }} />
                </span>
              </div>
            ))} */}
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              tabIndex={1} // tabIndex of textarea
              onChange={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            />
            <div className="d-flex mt-3 ">
              <button className="btn btn-success ml-auto" style={{ marginLeft: "auto" }} onClick={() => handleSubmit()}>
                Submit
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="px-3">
          <div className="mb-2">
            <button type="button" className="btn btn-primary " onClick={() => setNewSection(true)}>
              Add New Section
            </button>
          </div>
          {data.map((term, index) => (
            <div className="my-3" key={index}>
              <div className="d-flex justify-content-between">
                <label className="font-bold" htmlFor="heading">
                  Heading {index + 1}
                </label>
                <span className="cursor-pointer justify-content-center align-items-center " onClick={() => handleDeleteHeading(term._id)}>
                  <FaTrashAlt style={{ color: "red" }} />
                </span>
              </div>
              <input className="p-1" type="text" id="heading" defaultValue={term.heading} onChange={(e) => handleHeadingChange(index, e.target.value)} />
              <br />
              <label className="font-bold" htmlFor="points ">
                Points:
              </label>
              <JoditEditor
                ref={editor}
                value={term.points}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onChange={(prevTerm) => handleChangePoint(prevTerm, term)} // preferred to use only this option to update the content for performance reasons
              />
            </div>
          ))}
          {data.length > 0 && (
            <div className="d-flex aligne-self-end ">
              <button className="btn btn-success ml-auto " style={{ marginLeft: "auto" }} onClick={() => handleUpdate()}>
                Update
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TermsAndCondtions;
