import React, { useEffect, useState } from "react";
import "./ProductInfoTable.css";

const ProductInfoTable = ({ product_Information }) => {
  const [details, setDetails] = useState(product_Information);
  useEffect(() => {
    const obj = Object.fromEntries(details);
    const value = obj["weight"];
    if (value && value?.toString()?.trim() === "1") {
      setDetails((prev) => prev.filter(([key, value]) => key !== "weight"));
    }
  }, []);

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }
  const handleTableKeys = (elem, value) => {
    const str = elem.split("_").join(" ");
    if (str === "gps") {
      return "GPS";
    }
    if (str === "modelNo") {
      return "Model Number";
    }
    if (str === "connectiveTech") {
      return "Connectivity Technology";
    }
    if (str === "inTheBox") {
      return "What's In The Box";
    }
    return titleCase(str);

    // switch (elem) {
    //   case "weight":
    //     return "Item Weight";
    //   case "size":
    //     return "Item Dimensions LxWxH";
    //   case "brand":
    //     return "Brand";
    //   case "model_year":
    //     return "Item Model Year";
    //   case "modelNo":
    //     return "Item Model Number";
    //   case "modelName":
    //     return "Item Model Name";
    //   case "color":
    //     return "Colour";
    //   case "manufacturer":
    //     return "Manufacturer";
    //   case "powerOutput":
    //     return "Speakers Maximum Output Power";
    //   case "connector_Type":
    //     return "Connector Type";
    //   case "audioOutputMode":
    //     return "Audio Output Mode";
    //   case "batteries_Required":
    //     return "Batteries Required";
    //   case "batteries_Included":
    //     return "Batteries Included";
    //   case "batteries":
    //     return "Batteries";
    //   case "compatibleDevices":
    //     return "Compatible Devices";
    //   case "country":
    //     return "Country of Origin";
    //   case "includes_Rechargable_Battery":
    //     return "Includes Rechargable Battery";
    //   case "mountingHardware":
    //     return "Mounting Hardware";
    //   case "number_Of_Items":
    //     return "Number Of Items";
    //   case "specialFeatures":
    //     return "Special Features";
    //   case "speakerAmplificationType":
    //     return "Speaker Amplification Type";
    //   case "os":
    //     return "Operating System";
    //   case "ram":
    //     return "RAM";
    //   case "rom":
    //     return "ROM";
    //   case "connectivityTech":
    //     return "Connectivity Technologies";
    //   case "displayTechnology":
    //     return "Display Technology";
    //   case "displayFeatures":
    //     return "Display Features";
    //   case "Other_display_features":
    //     return "Other Display Features";
    //   case "primarydevice_interface":
    //     return "Primary Device Interface";
    //   case "cameraFeatures":
    //     return "Camera Features";
    //   case "audioJack":
    //     return "Audio Jack";
    //   case "formFactor":
    //     return "Form Factor";
    //   case "batteryPowerRating":
    //     return "Battery Power Rating";
    //   case "inTheBox":
    //     return "Whats in the box";
    //   case "wirelessTech":
    //     return "Wireless Technology";
    //   case "gps":
    //     return "GPS";
    //   case "hasGPS":
    //     return "GPS";
    //   case "colors_displayed":
    //     return "Colors Displayed";
    //   case "device_interface":
    //     return "Primary Device Interface";
    //   case "otherCameraFeatures":
    //     return "Other Camera Features";
    //   case "headphones_Form_Factor":
    //     return "Headphones Form Factor";
    //   case "microphoneFormFactor":
    //     return "Microphone Form Factor";
    //   case "cableFeature":
    //     return "Cable Feature";
    //   case "material":
    //     return "Material";
    //   case "hardwarePlatform":
    //     return "Hardware Platform";
    //   case "screenResolution":
    //     return "Screen Resolution";
    //   case "ACAdapterCurrent":
    //     return "AC Adapter Current";
    //   case "cableType":
    //     return "Cable Type";
    //   case "connectiveTech":
    //     return "Connectivity Tech";
    //   case "dataTransferRate":
    //     return "Data Transfer Rate";
    //   case "itemHeight":
    //     return "Item Height";
    //   case "itemWidth":
    //     return "Item Width";
    //   case "numberOfMemorySticks":
    //     return "Number Of Memory Sticks";
    //   case "numberOfPorts":
    //     return "Number Of Ports";
    //   case "totalUsbPorts":
    //     return "Total USB Ports";
    //   case "speakerType":
    //     return "Speaker Type";
    //   case "peakPowerHandlingSpeakers":
    //     return "Peak Power Handling - Speakers";
    //   case "RMSPowerRangeAmplifiers":
    //     return "RMS Power Range - Amplifiers";
    //   case "speakerConnectivity":
    //     return "Speaker Connectivity";
    //   case "wattage":
    //     return "Wattage";
    //   case "mountingType":
    //     return "Mounting Type";
    //   case "speakerSurroundSoundChannelConfiguration":
    //     return "Speaker Surround Sound Channel Configuration";
    //   case "imported_by":
    //     return "Imported By";
    //   case "power_Source":
    //     return "Power Source";
    //   case "batterCapacity":
    //     return "Battery Capacity";
    //   case "batteryCellComposition":
    //     return "Battery Cell Composition";
    //   case "processorBrand":
    //     return "Processor Brand";
    //   default:
    //     return elem;
    // }
  };

  return (
    <>
      <table className="product_Information_Table">
        <tbody>
          {details.map((elem, index) =>
            elem[1] && elem[0] !== "specText" && elem[0] !== "dynamicValues" ? (
              <tr className="product_Table_Row" key={index}>
                <td className="product_Table_Key w-50">{handleTableKeys(elem[0], elem[1])}</td>
                <td className="product_Table_Value">
                  {elem[1]}
                  {elem[0] === "weight" ? "" : ""}
                </td>
              </tr>
            ) : (
              ""
            )
          )}
        </tbody>
      </table>
    </>
  );
};

export default ProductInfoTable;
