import SearchIcon from "@mui/icons-material/Search";
import driverImage from "../../assets/png/your_order_driver.png";
import { CircularProgress } from "@mui/material";
import { currencyFormat, formatDate } from "../../utils";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { useState } from "react";

function YourOrderDetails({ isMobile, orderDetails, searchValue, handleChange, handleFilterSearch, filters }) {
  const { t } = useTranslation();
  const orderFilteres = [
    {
      name: "All",
      value: 0,
    },
    {
      name: "Pending Order",
      value: 1,
    },
    {
      name: "Delivered Order",
      value: 2,
    },
    {
      name: "Cancel Order",
      value: 3,
    },
  ];
  return (
    <div className="your_order_details">
      {isMobile && (
        <div className="your_order_placed">
          <div className="your_order_id">
            <p>
              {t("order-page.orderId")}: {orderDetails._id}
            </p>
          </div>
        </div>
      )}
      <div className="your_order_div">
        <div className="your_order_tracks">
          <div className="your_order_tracks_inside">
            <div className="your_search_container">
              <b>
                <label className="mb-1 ">Enter Product Name</label>
              </b>
              <div className="your_order_search">
                <SearchIcon className="your_ordersearch_icon" />
                <input type="search" placeholder={t("order-page.searchPlaceholder")} className="your_order_searchbar" value={searchValue} onChange={handleChange} />
              </div>
            </div>
            <div>
              <b>
                <label className="mb-1">Select Order Status</label>
              </b>
              <div className="col-sm-6 col-md-6">
                <Autocomplete
                  id="statusSelected"
                  sx={isMobile ? { width: 200 } : { width: 300 }}
                  style={{ backgroundColor: "white" }}
                  // className="form-control "
                  size="small"
                  options={orderFilteres}
                  onChange={(e, value) => handleFilterSearch(value)}
                  getOptionLabel={(option) => option.name || ""}
                  value={{ name: filters.filterName, value: filters.filterValue }}
                  clearOnEscape={false}
                  renderInput={(params) => (
                    <TextField aria-expanded="false" {...params} variant="standard" value={filters.filterName} style={{ backgroundColor: "white" }} placeholder="Select Status" />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="your_order_header" style={isMobile ? { marginTop: "15px" } : { marginTop: "10px" }}>
            <h4>{t("order-page.yourOrder")}</h4>
            <p>{t("order-page.trackOrder")}</p>
            {!isMobile && (
              <p>
                <span className="text-uppercase">{t("order-page.orderId")}</span>: {orderDetails._id}
              </p>
            )}
          </div>

          <div className="your_order_tracks_second mt-3">
            <div className="your_order_date">
              <h4 className="your_order_title">{t("order-page.LatestOrderPlace")}</h4>
              <h1 className="your_order_placed_date">{formatDate(orderDetails.createdAt)}</h1>
            </div>
            <div className="your_order_total_price">
              <h4 className="text-uppercase">{t("total")}</h4>
              <h4>{currencyFormat(orderDetails.totalPrice)}</h4>
            </div>
            {isMobile && (
              <div className="your_order_image">
                <img src={driverImage} alt="driverImage" />
              </div>
            )}
          </div>
        </div>
        {!isMobile && (
          <div className="your_order_image">
            <img src={driverImage} alt="driverImage" style={{ width: "100%" }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default YourOrderDetails;
