import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//CSS
import "./Footer.css";
import FooterSub1 from "../../assets/vector/Subtraction1.svg";
import FooterSub2 from "../../assets/vector/Subtraction2.svg";
//Image
import footerLogo from "../../assets/png/navbar_logo_desk.png";
import instagramIcon from "../../assets/vector/instagramIcon.svg";
import twitterIcon from "../../assets/vector/twitter_white1.svg";
import linkedinIcon from "../../assets/vector/linkedin.svg";
import youtubeIcon from "../../assets/vector/youtube.svg";
import facebookIcon from "../../assets/vector/facebook_white.svg";
import copyrightWhite from "../../assets/vector/copyright_white.svg";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../utils/ScreenSizeCalculation";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import CartBanner from "../CartProductCard/CartBanner";
import paypal from "../../assets/vector/paypal.png";
import RuPay from "../../assets/vector/RuPay.png";
import Visa_Logo from "../../assets/vector/Visa_Logo.png";
import Mastercard from "../../assets/vector/Mastercard.jpg";
import visaElectron from "../../assets/vector/visaElectron.png";
import locationLogo from "../../assets/png/locationIcon.png";
import emailLogo from "../../assets/png/emailLogo.png";
import callIcon from "../../assets/png/callIcon.png";
import { toast } from "react-toastify";
import { createSubscribeuser } from "../../api/AdminApis/IFD";

const Footer = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const [email, setEmail] = useState("");
  const socialIcon = [
    { icon: twitterIcon, link: "https://twitter.com/olineoindia" },
    { icon: facebookIcon, link: "https://www.facebook.com/OLineOIndia/" },
    { icon: instagramIcon, link: "https://www.instagram.com/olineoindia/" },
    { icon: linkedinIcon, link: "https://www.linkedin.com/company/olineoindia" },
    { icon: youtubeIcon, link: "https://www.youtube.com/@OLineOIndia" },
  ];
  const isMobile = useMediaQuery("(max-width: 500px)");
  const loc = useLocation();

  const subscribeUser = async () => {
    if (!email) {
      toast.error("Please Enter Email!");
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid Email Address!");
      return;
    }

    const res = await createSubscribeuser(email);
    if (res.status === 201) {
      toast.success("User Subscribed!");
      setEmail("");
    } else {
      toast.error(res.data?.error);
    }
  };

  return (
    <>
      {useScreenSize() === "desktop" ? (
        <>
          {loc.pathname === "/login" || loc.pathname === "/signup" || loc.pathname === "/password/forgot" || loc.pathname === "/create-new-password" || loc.pathname === "/otp" ? (
            <div style={{ marginBottom: "40px" }}></div>
          ) : (
            <>
              <CartBanner isMobile={isMobile} />
              <footer className="footerContainer">
                <div className="subscribe d-flex justify-content-between w-100 mt-3 mb-1">
                  <div className="justify-content-center d-flex  text-uppercase " style={{ width: "30%", fontSize: "36px", color: "#fccc04", fontWeight: 700 }}>
                    Subscribe
                  </div>
                  <div className="d-flex  flex-column w-50" style={{ gap: "3px" }}>
                    <div className="d-flex  w-75" style={{ gap: 0, padding: "5px", background: "white" }}>
                      <input placeholder="Enter Your Email Address" style={{ borderRadius: "0px", border: "white" }} value={email} onChange={(e) => setEmail(e.target.value)} />
                      <button className="btn btn-sm " style={{ background: "#fccc04", borderRadius: "0px", width: "172px", color: "black" }} onClick={() => subscribeUser()}>
                        Subscribe
                      </button>
                    </div>
                    <div className="mb-2">Subscribe to stay tuned for our latest electronic gadgets updates. Let's do it!</div>
                  </div>
                </div>
                <div className="w-100 " style={{ background: "white", height: "3px" }}></div>
                <div className="d-flex mt-2">
                  <div className="" style={{ width: "32%" }}>
                    <div className="footer_company_detail">
                      <div className="footer_logo" onClick={() => nav("/")}>
                        <img src={footerLogo} alt="main_logo" />
                      </div>
                    </div>
                    <div className="payment-method mb-2 mt-4" style={{ padding: "0px 0px 0px 95px" }}>
                      <div className="payment-heading heading-2xl">{t("Payment")}</div>
                      <div className="payment-cards d-flex">
                        <img className="pay-image" src={paypal} alt="img1" />
                        <img className="pay-image" src={RuPay} alt="img2" />
                        <img className="pay-image" src={Visa_Logo} alt="img3" />
                        <img className="pay-image" src={Mastercard} alt="img4" />
                        <img className="pay-image" src={visaElectron} alt="img4" />
                      </div>
                    </div>
                  </div>

                  <div className="footer_last ">
                    <div className="about-container">
                      <span className="about-heading heading-2xl">{t("aboutOlineo")}</span>
                      <div style={{ height: "2px", background: "#FFC400", width: "48px" }}></div>
                      <div className="listName about">
                        <Link to="/about-us" className="about-item">
                          {t("footer.about-us")}
                        </Link>
                        <Link to="/career" className="about-item">
                          {t("Careers")}
                        </Link>
                        <Link to="/store-finder" className="about-item">
                          {t("StoreLocator")}
                        </Link>
                      </div>
                    </div>
                    <div className="policy-container">
                      <span className="policy-heading heading-2xl">{t("policy")}</span>
                      <div style={{ height: "2px", background: "#FFC400", width: "48px" }}></div>
                      <div className=" listName policy">
                        <Link to="/terms-condition" className="policy-item">
                          {t("footer.terms")}
                        </Link>
                        <Link to="/terms-condition#order-cancellation" className="policy-item">
                          <span>{t("CancellationPolicy")}</span>
                        </Link>
                        <Link to="/terms-condition#return-exchange" className="policy-item">
                          {t("ReturnExchange")}
                        </Link>
                        <Link to="/terms-condition#disclaimer" className="policy-item">
                          {t("Disclaimer")}
                        </Link>
                        {/* <span className="policy-item">{t('EMIPlans')}</span> */}
                        {/* <span className="policy-item">{t('ERPPolicy')}</span> */}
                        <Link to="/privacy-policy" className="policy-item">
                          {t("privacyPolicy")}
                        </Link>
                        <Link to="/FAQ" className="policy-item">
                          FAQ
                        </Link>
                      </div>
                    </div>
                    <div className="help-container">
                      <span className="help-heading heading-2xl">{t("Help")}</span>
                      <div style={{ height: "2px", background: "#FFC400", width: "48px" }}></div>
                      <div className=" listName help">
                        <Link to="/customer-support" className="help-item">
                          {t("footer.contact")}
                        </Link>
                        <Link to="/customer-support" className="help-item">
                          {t("Customer")} {t("Support")}
                        </Link>
                        <Link to="/customer-support" className="help-item">
                          {t("Servicecenter")}
                        </Link>
                        <Link to="/store-finder" className="help-item">
                          {t("OLINEOMember")}
                        </Link>
                        {/* <Link to="/customer-support" className="help-item">
                          {t("BecomeASupplier")}
                        </Link> */}
                      </div>
                    </div>

                    <div className="footer_terms_container d-block d-md-none">
                      <p className="footer_last_heading">{t("footer.useful-links")}</p>
                      <div className="footer_terms_wrapper">
                        <div className="footer_terms_column1">
                          <Link to={"/wishlist"}>
                            <p className="footer_terms">{t("footer.wishlist")}</p>
                          </Link>
                        </div>
                        <div className="footer_terms_column2">
                          <Link to={"/customer-support"}>
                            <p className="footer_terms">{t("footer.cust-service")}</p>
                          </Link>
                          <Link to={"/customer-support"}>
                            <p className="footer_terms">{t("footer.terms")}</p>
                          </Link>
                          <Link to={"/customer-support"}>
                            <p className="footer_terms">{t("footer.privacy")}</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="last_contact_details" id="last_contact_details-sm-q">
                      <span className="heading-2xl footer_last_heading_desk  ">{t("footer.contact")}</span>
                      <div style={{ height: "2px", background: "#FFC400", width: "48px" }}></div>

                      <div className="contact_link_container">
                        <img src={locationLogo} alt="Location Logo" height="24" width="24" />
                        <span className="contact-address listName">
                          A-501, Neelkanth Business Park, Near Vidyavihar Skywalk, Vidyavihar (W),
                          <br /> Mumbai – 400086
                        </span>
                      </div>

                      <div className="contact_link_container">
                        <img src={emailLogo} alt="Location Logo" height="24" width="24" />
                        <span className="contact-address listName">
                          <a target="_blank" rel="noreferrer" href="mailto:contactus@olineoindia.com">
                            contactus@olineoindia.com
                          </a>
                        </span>
                      </div>
                      <Link to={"tel:63900 63900"} style={{ textDecoration: "none" }}>
                        <div className="contact_link_container">
                          <img src={callIcon} alt="Location Logo" height="24" width="24" />
                          <span className="contact-address listName">(+91) 63900 63900</span>
                        </div>
                      </Link>
                      <div className="payment-container mt-2">
                        <div className="payment-heading heading-2xl">{t("Connect")}</div>
                        <div className="social_links_wrapper">
                          {socialIcon.map((data, index) => (
                            <a href={data.link} target="_blank" rel="noreferrer" key={`social_Icons_${index}`}>
                              <img className="socialMedia-icon" key={index} src={data.icon} alt={`footer_${index}`} style={{ marginBottom: "5px" }} />
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer_copyright">
                  <img src={copyrightWhite} height={16} width={16} alt="" />
                  <p id="copy-right">COPYRIGHT (2024) O-LINE-O NEXUS IND. PVT. LTD.</p>
                </div>
              </footer>
            </>
          )}
        </>
      ) : (
        <>
          {loc.pathname === "/login" || loc.pathname === "/signup" || loc.pathname === "/password/forgot" || loc.pathname === "/create-new-password" || loc.pathname === "/otp" ? (
            <div style={{ marginBottom: "40px" }}></div>
          ) : (
            <>
              <CartBanner isMobile={isMobile} />
              <footer className="footerContainer-res">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {/* <div className="footer-logo-res ">
                    <img src={footerLogo} alt="footerLogo" />
                  </div> */}
                  <div className="subscribe d-flex justify-content-between flex-column align-items-center w-100 mt-3 mb-1">
                    <div className="justify-content-center d-flex  text-uppercase " style={{ width: "30%", fontSize: "36px", color: "#fccc04", fontWeight: 700 }}>
                      Subscribe
                    </div>
                    <div className="d-flex  flex-column align-items-center" style={{ gap: "3px" }}>
                      <div className="d-flex  " style={{ gap: 0, padding: "5px", background: "white" }}>
                        <input placeholder="Enter Your Email Address" style={{ borderRadius: "0px", border: "white" }} value={email} onChange={(e) => setEmail(e.target.value)} />
                        <button className="btn btn-sm " style={{ background: "#fccc04", borderRadius: "0px", width: "172px", color: "black" }} onClick={() => subscribeUser()}>
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="company-detail-res">
                    <div className="detail-left">
                      <div className="details-res">
                        <span className="footer-heading-2xl">{t("aboutOlineo")}</span>
                        <div style={{ height: "2px", background: "#FFC400", width: "48px" }}></div>
                        <Link to="/about-us">
                          <span>{t("footer.about-us")}</span>
                        </Link>
                        <Link to="/career">
                          <span>{t("Careers")}</span>
                        </Link>
                        <Link to="/store-finder">
                          <span>{t("StoreLocator")}</span>
                        </Link>
                      </div>
                      <div className="details-res">
                        <span className="footer-heading-2xl">{t("Help")}</span>
                        <div style={{ height: "2px", background: "#FFC400", width: "48px" }}></div>
                        <Link to="/write-to-us">
                          <span>{t("footer.contact")}</span>
                        </Link>
                        <Link to="/customer-support">
                          <span>
                            {t("Customer")} {t("Support")}
                          </span>
                        </Link>
                        <Link to="/customer-support">
                          <span>{t("Servicecenter")}</span>
                        </Link>
                        <Link to="/customer-support">
                          <span>{t("OLINEOMember")}</span>
                        </Link>
                        <Link to="/customer-support">
                          <span>{t("BecomeASupplier")}</span>
                        </Link>
                      </div>
                      <div className="details-res" style={{ padding: "" }}>
                        <span className="footerHeadings">{t("MailUs")}</span>
                        <span>
                          <a target="_blank" rel="noreferrer" href="mailto:contactus@olineoindia.com">
                            contactus@olineoindia.com
                          </a>
                        </span>
                      </div>
                    </div>
                    <div className="detail-right">
                      <div className="details-res">
                        <span className="footer-heading-2xl">{t("policy")}</span>
                        <div style={{ height: "2px", background: "#FFC400", width: "48px" }}></div>
                        <Link to="/terms-condition">
                          <span>{t("footer.terms")}</span>
                        </Link>
                        <Link to="/terms-condition#order-cancellation">
                          <span>{t("CancellationPolicy")}</span>
                        </Link>
                        <Link to="/terms-condition#return-exchange">
                          <span>{t("ReturnExchange")}</span>
                        </Link>
                        <Link to="/terms-condition#disclaimer">
                          <span>{t("Disclaimer")}</span>
                        </Link>
                        {/* <span>{t('EMIPlans')}</span> */}
                        {/* <span>{t('ERPPolicy')}</span> */}
                        <Link to="/privacy-policy">
                          <span>{t("privacyPolicy")}</span>
                        </Link>
                        <Link to="/FAQ">
                          <span> FAQ</span>
                        </Link>
                      </div>
                      <div className="details-res">
                        <span className="footer-heading-2xl">{t("footer.contact")}</span>
                        <div style={{ height: "2px", background: "#FFC400", width: "48px" }}></div>
                        <span>A-501, Neelkanth Business Park, Near Vidyavihar Skywalk, Vidyavihar (W), Mumbai - 400086</span>
                      </div>
                      <div className="details-res">
                        <span className="footerHeadings">{t("CustomerCare")}</span>
                        <span>
                          <a href="tel:63900 63900" target="_blank" rel="noreferrer">
                            (+91) 63900 63900
                          </a>{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="footer-last-res">
                  <div className="detail-left">
                    <span className="footer-heading-2xl" style={{ fontSize: "20px" }}>
                      Connect
                    </span>
                    <div className="footer social_links_wrapper" style={{ gap: "5px" }}>
                      {socialIcon.map((data, index) => (
                        <a href={data.link} target="_blank" rel="noreferrer" key={`social_Icons_1_${index}`}>
                          <img className="socialMedia-icon  mb-1" style={{ height: "20px", width: "20px" }} key={index} src={data.icon} alt={`social_${index}`} />
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="detail-right">
                    <span id="ft-pay" className="footer-heading-2xl" style={{ fontSize: "20px" }}>
                      Payment
                    </span>
                    <div className="footer-payment-last footer-heading-2xl flex-row gap-2" style={{ flexWrap: "wrap" }}>
                      <img className="pay-image" style={{ objectFit: "contain", width: "90px" }} src={paypal} alt="FooterSub1" />
                      <img className="pay-image" style={{ objectFit: "contain", width: "90px" }} src={RuPay} alt="img2" />
                    </div>
                  </div>
                </div>
                <div className="footer-copy-right">
                  <span>&copy; COPYRIGHT (2024) O-LINE-O NEXUS IND. PVT. LTD.</span>
                </div>
              </footer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Footer;
