import React, { useEffect, useReducer, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { updateWalletCupon } from "../../api/AdminApis/Cupon";
import { toast } from "react-toastify";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import moment from "moment";

const EditWalletCuponModal = (props) => {
  const [data, setData] = useState({});
  const [id, setId] = useState("");

  const initialState = {
    eanValue: "",
    selectedEans: [],
    eanOptions: [],
  };

  const [state, dispatch] = useReducer();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [smartphoneChecked, setSmartphoneChecked] = useState(false);
  const [accessoriesChecked, setAccessoriesChecked] = useState(false);
  const [minPriceSmartphone, setMinPriceSmartphone] = useState(0);
  const [maxPriceSmartphone, setMaxPriceSmartphone] = useState(0);
  const [minPriceAccessories, setMinPriceAccessories] = useState(0);
  const [maxPriceAccessories, setMaxPriceAccessories] = useState(0);

  const handleSmartphoneChange = () => {
    setSmartphoneChecked(!smartphoneChecked);
  };

  const handleAccessoriesChange = () => {
    setAccessoriesChecked(!accessoriesChecked);
  };

  useEffect(() => {
    if (props?.data?._id) {
      const id = props.data._id;
      setId(id);
      setData(props.data);

      const expiresAt = props.data.expiresAt;

      // Format the date using moment
      const formattedDate = moment(expiresAt).format("DD/MM/YYYY");

      // Set the selected date
      setSelectedDate(formattedDate);

      const smartphoneCondition = props.data.conditions.find((condition) => condition.category === "Smartphone");
      const accessoriesCondition = props.data.conditions.find((condition) => condition.category === "accessories");

      if (smartphoneCondition) {
        // Update the state variables with the minPrice and maxPrice
        setMinPriceSmartphone(smartphoneCondition.minPrice);
        setMaxPriceSmartphone(smartphoneCondition.maxPrice);
        setSmartphoneChecked(true);
      }
      if (accessoriesCondition) {
        // Update the state variables with the minPrice and maxPrice
        setMinPriceAccessories(accessoriesCondition.minPrice);
        setMaxPriceAccessories(accessoriesCondition.maxPrice);
        setAccessoriesChecked(true);
      }
    } else {
      setData({ ...data, expiresAt: new Date() });
    }
  }, [props]);

  const submitHandle = async (e) => {
    e.preventDefault();

    const newConditions = [];
    if (smartphoneChecked) {
      if (Number.parseInt(minPriceSmartphone) >= Number.parseInt(maxPriceSmartphone)) {
        toast.warning("Minimum Price can not be greater than Maximum price");
        return;
      }
      newConditions.push({
        category: "Smartphone",
        minPrice: parseInt(minPriceSmartphone),
        maxPrice: parseInt(maxPriceSmartphone),
      });
    }
    if (accessoriesChecked) {
      if (Number.parseInt(minPriceAccessories) >= Number.parseInt(maxPriceAccessories)) {
        toast.warning("Minimum Price can not be greater than Maximum price");
        return;
      }
      newConditions.push({
        category: "accessories",
        minPrice: parseInt(minPriceAccessories),
        maxPrice: parseInt(maxPriceAccessories),
      });
    }
    let bodyData = data;

    bodyData.conditions = newConditions;

    if (id) {
      bodyData._id = id;
    }
    // if (state.selectedEans.length <= 0) {
    //   return toast.error("Please select EAN(s)");
    // }
    // let maxAmount = e.target.maxAmount.value;
    // let discAmount = e.target.discount.value;
    // if (parseInt(maxAmount) <= parseInt(discAmount)) {
    //   return toast.error("Please Enter Correct Amount");
    // }
    // const submitData = {
    //   discount: e.target.discount.value,
    //   maxAmount: e.target.maxAmount.value,
    //   expire: data.expire,
    //   products: state.selectedEans.map((e) => e.value),
    //   code: e.target.code.value,
    // };

    const response = await updateWalletCupon(bodyData);
    if (response.status === 201) {
      toast.success(`Coupon ${id ? "updated" : "Created"} successfully!`);
      setId("");
      setSelectedDate(new Date());
      setMinPriceSmartphone("");
      setMaxPriceSmartphone("");
      setSmartphoneChecked(false);
      setMinPriceAccessories("");
      setMaxPriceAccessories("");
      setAccessoriesChecked(false);
    }
    props.onClose();
  };

  const handleEanChange = (event, newValue) => {
    dispatch({ type: "SET_EAN_VALUE", payload: newValue });
  };

  const handleEanSelect = (event, newValue) => {
    if (newValue) {
      dispatch({ type: "ADD_SELECTED_EAN", payload: newValue });
      dispatch({ type: "SET_EAN_VALUE", payload: "" });
    }
  };

  const handleRemoveEan = (eanToRemove) => {
    dispatch({ type: "REMOVE_SELECTED_EAN", payload: eanToRemove });
  };

  const handleDate = (value) => {
    if (value) {
      data.expiresAt = value;
      setSelectedDate(value);
      setData({ ...data, expiresAt: value });
    }
  };

  const handleStatusChange = (event, value) => {
    setData({ ...data, status: value });
  };
  const statusOptions = ["active", "expired"];

  return (
    <>
      <Dialog {...props} onClose={props.onHide} fullWidth>
        <div className="d-flex justify-content-between">
          <DialogTitle>Edit Coupon</DialogTitle>
          <DialogActions>
            <CloseIcon onClick={props.onClose} className="cursor-pointer" />
          </DialogActions>
        </div>

        <DialogContent>
          <form onSubmit={submitHandle} className="d-flex flex-column gap-3 my-4">
            <TextField
              label="Code (Name should be alphanumeric only)"
              placeholder="Code"
              required
              autoFocus
              type="text"
              value={data?.code}
              name="code"
              onChange={(e) => setData({ ...data, code: e.target.value })}
            />

            <TextField label="Text " placeholder="Text" required type="text" value={data?.text} name="Text" onChange={(e) => setData({ ...data, text: e.target.value })} />

            <TextField label="Cart Text" placeholder="Code" required type="text" value={data?.cartText} name="Cart Text" onChange={(e) => setData({ ...data, cartText: e.target.value })} />

            <TextField
              label="Discount (in Rs)"
              placeholder="Discount (in Rs)"
              type="number"
              name="discount"
              value={data?.value}
              required
              onChange={(e) => setData({ ...data, value: parseInt(e.target.value) })}
            />
            <div>
              <Autocomplete
                defaultValue={data.status}
                onChange={handleStatusChange}
                options={statusOptions}
                getOptionLabel={(option) => option}
                renderInput={(params) => <TextField {...params} label="Status" variant="outlined" />}
              />
            </div>
            <div>
              <label className="mb-1">Expires In:</label>
              <br />
              <DatePicker
                value={selectedDate}
                selected={new Date(data.expiresAt)}
                minDate={new Date()}
                onChange={(value) => handleDate(value)}
                dateFormat="dd/MM/yyyy"
                popperPlacement="top"
                className={" input-field custom-date-picker"}
              />
            </div>
            <Box>
              <div>
                <div class="input-group-text">
                  <input type="checkbox" style={{ width: "10%" }} id="smartphone" checked={smartphoneChecked} onChange={handleSmartphoneChange} />
                  <label for="smartphone">Smartphone</label>
                </div>
                {smartphoneChecked && (
                  <div className="d-flex flex-column">
                    <label for="minPriceSmartphone">Min Price:</label>
                    <input
                      placeholder="SmartPhone Min Price"
                      className="form-control"
                      type="number"
                      id="minPriceSmartphone"
                      value={minPriceSmartphone}
                      onChange={(e) => setMinPriceSmartphone(e.target.value)}
                    />
                    <label for="maxPriceSmartphone">Max Price:</label>
                    <input
                      placeholder="SmartPhone Max Price"
                      className="form-control"
                      type="number"
                      id="maxPriceSmartphone"
                      value={maxPriceSmartphone}
                      onChange={(e) => setMaxPriceSmartphone(e.target.value)}
                    />
                  </div>
                )}

                <div class="input-group-text">
                  <input type="checkbox" style={{ width: "10%" }} id="accessories" checked={accessoriesChecked} onChange={handleAccessoriesChange} />
                  <label for="accessories">Accessories</label>
                </div>
                {accessoriesChecked && (
                  <div>
                    <label for="minPriceAccessories">Min Price:</label>
                    <input
                      placeholder="Accessories Min Price"
                      className="form-control"
                      type="number"
                      id="minPriceAccessories"
                      value={minPriceAccessories}
                      onChange={(e) => setMinPriceAccessories(e.target.value)}
                    />
                    <label for="maxPriceAccessories">Max Price:</label>
                    <input
                      placeholder="Accessories Max Price"
                      className="form-control"
                      type="number"
                      id="maxPriceAccessories"
                      value={maxPriceAccessories}
                      onChange={(e) => setMaxPriceAccessories(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </Box>

            <div className="d-flex justify-content-end">
              <Button type="submit" className="btn btn-primary mr-auto float-right mt-2 px-5" variant="contained">
                {id ? "Update" : "Submit"}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditWalletCuponModal;
