import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { FormControlLabel, Switch } from "@mui/material";

import "./styles/dashboardProducts.css";
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import { changeCheckedBestSeller, deleteProductAPI, getAllAdminProducts, getProductsFromEans, getSearchedProduct, toggleLiveStatus } from "../../api/Product";
import EditProductModal from "../../components/EditProductModal/EditProductModal";
import Pagination from "../../components/Pagination/Pagination";
import { toast } from "react-toastify";
import { currencyFormat, formatDate } from "../../utils/index";
import { PRODUCT_CLASSIFICATION, categoriesList2 } from "../../utils/Constants";
import { Autocomplete, TextField } from "@mui/material";
// import { CircularProgress, Drawer, Paper } from "@mui/material";
// import Popper from "@mui/material/Popper";
// import MenuItem from "@mui/material/MenuItem";
// import MenuList from "@mui/material/MenuList";
// import useAdminCtx from "../../hooks/useAdminCtx";
// import useUserCtx from "../../hooks/useUserCtx";
import useSiteCtx from "../../hooks/useSiteCtx";
import { downloadCsv } from "../../api/CatalogueApi";

// Component definition
function DashboardProducts() {
  const nav = useNavigate();

  // state definitions
  const [loader, setLoader] = useState(true);
  const [checkedLoading, setCheckedLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [indProduct] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchOpin, setSearchOpin] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [stock, setStock] = useState(["In-stock", "Out of stock"]);
  const [stockName, setStockName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [sorting, setSorting] = useState(["descending", "ascending"]);
  const [sortingName, setSortingName] = useState("");
  const [tagName, setTagName] = useState("");
  const [tagValue, setTagValue] = useState("");
  const [checkedClicked, setCheckedClicked] = useState(false);
  const [checkedTagName, setCheckedTagName] = useState("");
  const [checkedTagId, setCheckedTagId] = useState("");
  const tagArr = [
    { name: "Best Seller Tag", value: "bestSeller" },
    { name: "Top Discount", value: "topDiscount" },
    { name: "New Arrival", value: "newArrival" },
    { name: "Top Seller", value: "topSeller" },
    { name: "Upcoming Launches", value: "upcomingLaunches" },
  ];

  const productsPerPage = 40;
  const location = useLocation();
  useEffect(() => {
    if (!checkedTagName) {
      setLoader(true);
    }
    const params = new URLSearchParams(location.search);
    if (params.get("search") === "") return;
    params.append("hierarchyL2", categoryName);

    if (sortingName === "descending") {
      params.append("sort", "descending");
    }
    if (sortingName === "ascending") {
      params.append("sort", "ascending");
    }
    params.append("limit", productsPerPage);
    params.append("page", currentPage);
    params.append("brand", brandName);
    if (stockName) {
      params.append("stockStatus", stockName);
    }
    if (tagValue) {
      params.append("tagName", tagValue);
    }
    // if (searchText && !searchText.includes("OLINEO_")) {
    params.append("search", searchText);
    // params.append("title", searchText);
    params.append("ean", searchOpin);
    // }
    // if (searchText && searchText.includes("OLINEO_")) {
    //   params.append("ean", searchText);
    // }
    getSearchedProduct(params)
      .then((res) => {
        const products = res.products.filter((prod) => prod.classification !== PRODUCT_CLASSIFICATION.TEMP_HIDDEN);
        setAllProducts(products);
        setTotalProducts(res.total_products);
        setLoader(false);
        setCheckedLoading(false);
      })
      .catch((err) => console.log(err));
  }, [categoryName, sortingName, currentPage, stockName, searchText, searchOpin, tagValue, checkedTagName, checkedClicked]);

  // useEffect(() => {
  //   setCheckedLoading(true);
  // }, [checkedClicked]);

  const fetchPaginatedProducts = () => {
    setLoader(true);
    const params = new URLSearchParams(location.search);
    if (params.get("search") === "") return;
    params.append("hierarchyL2", categoryName);

    if (sortingName === "descending") {
      params.append("sort", "descending");
    }
    if (sortingName === "ascending") {
      params.append("sort", "ascending");
    }
    params.append("limit", productsPerPage);
    params.append("page", currentPage);
    params.append("brand", brandName);
    if (stockName) {
      params.append("stockStatus", stockName);
    }
    // if (searchText && !searchText.includes("OLINEO_")) {
    //   params.append("search", searchText);
    // }
    // if (searchText && searchText.includes("OLINEO_")) {
    params.append("search", searchText);
    params.append("ean", searchOpin);
    // }

    getSearchedProduct(params).then((res) => {
      const products = res.products.filter((prod) => prod.classification !== PRODUCT_CLASSIFICATION.TEMP_HIDDEN);
      setAllProducts(products);
      setTotalProducts(res.total_products);
    });
    setLoader(false);
  };

  const deletProduct = async (id) => {
    let text = "Are you sure for delete?";
    if (window.confirm(text) === true) {
      deleteProductAPI(id).then((res) => {
        if (res?.status === 401) {
          toast.error("Unauthorized");
          localStorage.removeItem(process.env.REACT_APP_ADMIN_TOKEN_KEY);
          nav("/admin-login");
        } else {
          toast.success("Product Deleted Successfully!");
          setAllProducts(allProducts.filter((message) => message._id !== id));
        }
      });
    }
  };

  // handles go live toggle - calls api to change go live status
  const handleGoLiveToggle = async (productId, checked) => {
    // setLoader(true);
    try {
      const response = await toggleLiveStatus(productId);
      const payload = response.data;
      if (payload.status === "success") {
        toast.success(`Product live status ${!checked ? "activated" : "deactivated"}`);
        setCheckedClicked(!checkedClicked);
        // fetchPaginatedProducts();
      }
    } catch (err) {
      toast.error(err.response?.data?.message ?? "Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (currentPage !== 0 && allProducts.length <= 0) {
      setCurrentPage(1);
    }
  }, [currentPage, allProducts]);

  const handleSelectedCategory = (e, value) => {
    if (value) {
      setCategoryName(value[0]);
    } else {
      setCategoryName("");
    }
  };
  const handleSelectStock = (e, value) => {
    if (value) {
      setStockName(value);
    } else {
      setStockName("");
    }
  };

  const handleSortingChange = (e, value) => {
    if (value) {
      setSortingName(value);
    } else {
      setSortingName("");
    }
  };

  const handleTagChecked = async (tagName, id) => {
    // setCheckedTagName(tagName);
    setCheckedTagId(id);
    const result = await changeCheckedBestSeller(tagName, id);
    if (result.message === "status updated successfully") {
      const updatedProducts = allProducts.map((product) => {
        if (product._id === id) {
          product[tagName] = !product[tagName]; // Toggle the tagName status
        }
        return product;
      });

      setAllProducts(updatedProducts);
    }
  };

  const handleTagChange = (e, value) => {
    if (value) {
      setTagName(value.name);
      setTagValue(value.value);
    } else {
      setTagValue("");
      setTagName("");
    }
  };

  const handleDownlaod = async () => {
    const res = await downloadCsv();
    if (res) {
      const csvData = res; // Replace this with your actual CSV data
      downloadFile(csvData);
    }
  };

  function downloadFile(data) {
    const filename = `products.xlsx`;
    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Products");
    XLSX.writeFile(wb, filename);
  }

  return (
    <>
      <EditProductModal show={modalShow} onHide={() => setModalShow(false)} data={indProduct} />
      <div className="container">
        <div className=" ">
          <h3>Products</h3>
          <div className="row align-items-center">
            <div className="col-lg-3">
              <label className="mb-2">Product Name</label>
              <input
                type="search"
                id="outlined-basic"
                size="small"
                className="form-control"
                placeholder="Enter Product Name"
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setCurrentPage(1);
                }}
                value={searchText}
                disabled={searchOpin !== ""}
                variant="outlined"
              />
            </div>
            <div className="col-lg-3">
              <label className="mb-2">Product OPIN</label>
              <input
                type="search"
                id="outlined-basic"
                size="small"
                className="form-control"
                placeholder="Enter Product OPIN"
                onChange={(e) => setSearchOpin(e.target.value)}
                value={searchOpin}
                disabled={searchText !== ""}
                variant="outlined"
              />
            </div>
            <div className="col-3">
              <label className="mb-2">Category</label>
              <Autocomplete
                id="statusSelected"
                className="form-control p-0"
                size="medium"
                options={categoriesList2}
                onChange={(e, value) => handleSelectedCategory(e, value)}
                getOptionLabel={(option) => option[0] || ""}
                value={categoryName !== "" ? [categoryName] : null}
                clearOnEscape={false}
                renderInput={(params) => <TextField aria-expanded="false" {...params} variant="standard" placeholder="Select Category" />}
              />
            </div>
            <div className="col-3">
              <label className="mb-2">Stock </label>
              <Autocomplete
                id="statusSelected"
                className="form-control p-0"
                size="medium"
                options={stock}
                onChange={(e, value) => handleSelectStock(e, value)}
                getOptionLabel={(option) => option || ""}
                value={stockName}
                clearOnEscape={false}
                renderInput={(params) => <TextField aria-expanded="false" {...params} variant="standard" placeholder="Select Stock" />}
              />
            </div>
            <div className="col-lg-2">
              <label className="mb-2">Sort By Price </label>
              <Autocomplete
                id="statusSelected"
                className="form-control p-0"
                size="medium"
                options={sorting}
                onChange={(e, value) => handleSortingChange(e, value)}
                getOptionLabel={(option) => option || ""}
                value={sortingName}
                clearOnEscape={false}
                renderInput={(params) => <TextField aria-expanded="false" {...params} variant="standard" placeholder="Select Price" />}
              />
            </div>
            <div className="col-lg-3">
              <label className="mb-2">Sort By Tags </label>
              <Autocomplete
                id="statusSelected"
                className="form-control p-0"
                size="medium"
                options={tagArr}
                onChange={(e, value) => handleTagChange(e, value)}
                getOptionLabel={(option) => option.name || ""}
                value={{ name: tagName, value: tagValue }}
                clearOnEscape={false}
                renderInput={(params) => <TextField aria-expanded="false" {...params} value={tagName} variant="standard" placeholder="Select Tag" />}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="d-flex flex-row mt-3 flex-wrap" style={{ flex: "wrap", gap: "10px" }}>
            <Button className="btn-sm" onClick={() => nav("../admin-add-product")} style={{ marginBottom: 20 }}>
              Add product
            </Button>
            <Button className="btn-sm" onClick={() => nav("../admin-add-product-csv")} style={{ marginBottom: 20 }}>
              Add CSV File
            </Button>
            <Button className="btn-sm" onClick={() => nav("../admin-update-product-csv")} style={{ marginBottom: 20 }}>
              Update Products CSV
            </Button>
            <Button className="btn-sm" onClick={() => nav("../admin-discounts")} style={{ marginBottom: 20 }}>
              Coupons
            </Button>
            <Button className="btn-sm" onClick={() => nav("../catelogue-page")} style={{ marginBottom: 20 }}>
              Catalogue Page (Add Offers)
            </Button>
            <Button className="btn-sm" onClick={() => handleDownlaod()} style={{ marginBottom: 20 }}>
              Download csv
            </Button>
            <br />
            <Button className="btn-sm" onClick={() => nav("../stock-tracker")} style={{ marginBottom: 20 }}>
              Stock Tracker
            </Button>
          </div>
        </div>
        {loader ? (
          <DashboardLoader />
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-bordered " style={{ fontSize: "small", overflowX: "auto" }}>
                <thead>
                  <tr style={{ width: "100%", border: "1px solid", background: "#e9ecef" }}>
                    <th scope="col" style={{ background: "#e9ecef" }}>
                      Sr. No.
                    </th>
                    <th scope="col">Name</th>
                    <th scope="col">OPIN</th>
                    <th scope="col">Price MRP</th>
                    <th scope="col">Price MOP</th>
                    <th scope="col">Uploaded At</th>
                    <th scope="col">Best Seller Tag</th>
                    <th scope="col">Top Deals</th>
                    <th scope="col">New Arrivals</th>
                    <th scope="col">Best Seller</th>
                    <th scope="col">Upcoming Launches</th>
                    <th scope="col">Actions</th>
                    <th scope="col">Active Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allProducts?.length > 0 &&
                    allProducts?.map((item, index) => (
                      <tr key={index} className={`${!item.goLive ? "bg-light" : ""}`} style={{ border: "1px solid" }}>
                        <td>{(currentPage - 1) * productsPerPage + index + 1}</td>
                        <td style={{ width: "320px" }} className={`title ${!item.goLive ? "bg-light" : ""}`}>
                          {item?.title}
                        </td>
                        <td>{item?.ean}</td>
                        <td>{currencyFormat(item?.price?.mrp)}</td>
                        <td>{currencyFormat(item?.price?.mop)}</td>
                        <td style={{ width: "100px" }}>
                          {item?.createdAt
                            ? new Intl.DateTimeFormat("en-IN", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                timeZone: "Asia/Calcutta",
                              }).format(new Date(item?.createdAt))
                            : "-"}
                        </td>
                        <td>
                          {checkedLoading && checkedTagName === "bestSeller" && checkedTagId === item._id ? (
                            <div className="d-flex" style={{ width: "100%", justifyContent: "center" }}>
                              <div className="spinner-grow text-primary" style={{ width: "20px", height: "20px" }} role="status"></div>
                            </div>
                          ) : (
                            <input className="cursor-pointer" type="checkbox" checked={item?.bestSeller} disabled={!item?.goLive} onChange={() => handleTagChecked("bestSeller", item.item._id)} />
                          )}
                        </td>
                        <td>
                          {checkedLoading && checkedTagName === "topDiscount" && checkedTagId === item._id ? (
                            <div className="d-flex" style={{ width: "100%", justifyContent: "center" }}>
                              <div className="spinner-grow text-primary" style={{ width: "20px", height: "20px" }} role="status"></div>
                            </div>
                          ) : (
                            <input className="cursor-pointer" type="checkbox" checked={item?.topDiscount} disabled={!item?.goLive} onChange={() => handleTagChecked("topDiscount", item.item._id)} />
                          )}
                        </td>
                        <td>
                          {checkedLoading && checkedTagName === "newArrival" && checkedTagId === item._id ? (
                            <div className="d-flex" style={{ width: "100%", justifyContent: "center" }}>
                              <div className="spinner-grow text-primary" style={{ width: "20px", height: "20px" }} role="status"></div>
                            </div>
                          ) : (
                            <input className="cursor-pointer" type="checkbox" checked={item?.newArrival} disabled={!item?.goLive} onChange={() => handleTagChecked("newArrival", item.item._id)} />
                          )}
                        </td>
                        <td>
                          {checkedLoading && checkedTagName === "topSeller" && checkedTagId === item._id ? (
                            <div className="d-flex" style={{ width: "100%", justifyContent: "center" }}>
                              <div className="spinner-grow text-primary" style={{ width: "20px", height: "20px" }} role="status"></div>
                            </div>
                          ) : (
                            <input className="cursor-pointer" type="checkbox" checked={item?.topSeller} disabled={!item?.goLive} onChange={() => handleTagChecked("topSeller", item.item._id)} />
                          )}
                        </td>
                        <td>
                          {checkedLoading && checkedTagName === "upcomingLaunches" && checkedTagId === item._id ? (
                            <div className="d-flex" style={{ width: "100%", justifyContent: "center" }}>
                              <div className="spinner-grow text-primary" style={{ width: "20px", height: "20px" }} role="status"></div>
                            </div>
                          ) : (
                            <input
                              className="cursor-pointer"
                              type="checkbox"
                              checked={item?.upcomingLaunches}
                              disabled={!item?.goLive}
                              onChange={() => handleTagChecked("upcomingLaunches", item.item._id)}
                            />
                          )}
                        </td>

                        <td>
                          <Link to={"/admin/admin-add-product"} state={(item = { item })} className="btn p-0">
                            <FontAwesomeIcon className={"table-icon"} icon={faPenToSquare} />
                          </Link>
                          <button className="btn p-0 " onClick={() => deletProduct(item.item._id)}>
                            <FontAwesomeIcon className={"table-icon text-danger"} icon={faTrashCan} />
                          </button>
                        </td>
                        <td>
                          <FormControlLabel control={<Switch checked={item?.item?.goLive} />} onChange={(e) => handleGoLiveToggle(item.item?._id, item.item?.goLive)} />
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* {!allProducts.length && (
                  <tbody>
                    <tr>
                      <td className="text-center" colSpan={6}>
                        No products found!
                      </td>
                    </tr>
                  </tbody>
                )} */}
              </table>
            </div>
            <div className="pagination_Container">
              <Pagination productsPerPage={productsPerPage} totalProducts={totalProducts} pageChange={handlePageChange} currentPage={currentPage} />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default DashboardProducts;
