import React from "react";
import orderCancelledImage from "../../assets/vector/Cartpage/orderCancelledImage.svg";
import Correct from "../../assets/vector/correct.svg";

const OrderCancelled = () => {
  return (
    <div className="my-3 px-3 d-flex flex-column align-items-center page_Wrapper pt-5">
      <div style={{ height: "200px" }}>
        <img className="orderCancelledImage" style={{ height: "100%", width: "100%" }} src={orderCancelledImage} alt="cancelled" />
      </div>
      <h4 className="my-3">Your order is canceled!</h4>
      <div className="order-cancel-tick" style={{ height: "100px" }}>
        <img src={Correct} alt="correct" />
      </div>
    </div>
  );
};

export default OrderCancelled;
