import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { completeOffer } from "../../api/OfferAPIs";
import OfferContext from "../../Contexts/OffersContext";
import SVGLoader from "./SVGLoader";
import "./step1.css";

function Page3() {
  const { OfferId } = useContext(OfferContext);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (OfferId === null) {
      navigate("/quiz-Offer");
    }
  }, [OfferId]);

  const [questions, setQuestions] = useState([
    {
      id: 1,
      question: "Which flagship models Realme have launched recently?",
      options: ["GT 6T", "GT 6", "Above All"],
      answer: "Paris",
    },
    {
      id: 2,
      question: "Which model of Realme mobile you would love to buy?",
      options: ["GT 6", "GT 6T", "C65 5G"],
      answer: "Jupiter",
    },
    {
      id: 3,
      question: "Give a reason to recommend your friends to buy Realme mobile ?",
      options: ["Affordable", "Best In Quality", "Above All"],
      answer: "Vatican City",
    },
  ]);

  const [answers, setAnswers] = useState({});

  const handlecompleteQuiz = async () => {
    if (!answers[1] || !answers[2] || !answers[3]) return toast.error("Please Select All Answers");
    if (OfferId) {
      setLoading(true);
      const res = await completeOffer(OfferId);
      if (res.status === "success") {
        toast.success(res?.message);
        navigate("/quiz-Offer/4");
      }
    }
  };

  const handleOptionChange = (questionId, option) => {
    setAnswers((prevAnswers) => ({ ...prevAnswers, [questionId]: option }));
  };

  return (
    <div className="container mb-4">
      {/* <h1 className="text-center">Quiz Contest</h1> */}
      <form>
        {questions.map((question) => (
          <div key={question.id} className="mb-3 questionContainer">
            <h3>{question.question}</h3>
            {question.options.map((option, index) => (
              <div key={index} className="form-check optionLabel" style={{ padding: "0px 15px" }} onClick={() => handleOptionChange(question.id, option)}>
                <label className="d-flex" style={{ gap: "20px", fontSize: "25px" }}>
                  <input style={{ width: "17px" }} type="checkbox" name={question.id} value={option} checked={answers[question.id] === option} />
                  {option}
                </label>
              </div>
            ))}
          </div>
        ))}
        <button type="button" onClick={() => handlecompleteQuiz()} className="btn btn-warning mt-3 w-100 fw-bolder page_1_submit_btn" disabled={loading}>
          {loading ? <SVGLoader /> : <span>Submit</span>}
        </button>
      </form>
    </div>
  );
}

export default Page3;
