import "./step1.css";
import Pillar1 from "../../assets/bharatmahostav/Pillar.png"
import Pillar2Horizontal from "../../assets/bharatmahostav/Pillar2Horizontal.png";
import Happy from "../../assets/smartBandhan/Happy.png"
import { Box } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import Smartbandhan from "../../assets/smartBandhan/SmartBandhanlogo.png"
import Flower_left from "../../assets/smartBandhan/SmartBandhan_hand_left.png"
import Flower_right from "../../assets/smartBandhan/SmartBandhan_hand_right.png"
import Watch from "../../assets/smartBandhan/SmartBandhan_Bottom_watch.png"

function SmartBandhanIndex() {
    const location = useLocation();
    const page_number = location.pathname.split("/");

    return (
        <div className="w-100 Main2"
        // style={{
        //     position: "relative",
        //     //CSS for ThankYou Image (Main Page)
        //     minHeight: "87ch"
        // }}
        >
            <div className="border-container">
                <img alt="Pillar" data-position="top" src={Pillar2Horizontal} />
                <img alt="Pillar" data-position="right" src={Pillar1} />
                <img alt="Pillar" data-position="left" src={Pillar1} />
                <img alt="Pillar" data-position="bottom" src={Pillar2Horizontal} />
            </div>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1 },
                }}
                noValidate
                autoComplete="off"
                className=" sub-container"
                data-pagenumber={
                    ['/SmartBandhan/', '/SmartBandhan'].includes(location.pathname) ? "first" :
                        (
                            page_number[2] === "2" ? "second" :
                                (
                                    page_number[2] === "3" ? "third" :
                                        (
                                            page_number[2] === "4" ? "fourth" : ""
                                        )
                                )
                        )
                }
            >
                <div className="main-logo">
                    <img src={Smartbandhan} alt="main-logo" />
                    {/* {
                        page_number[2] !== "3" &&
                        ((page_number[2] === "2" || page_number[2] === "4") ?
                            <img src={flower} alt="flower_logo" /> :
                            <img src={hand} alt="hand_logo" />
                        )
                    } */}
                </div>
                <div className="flower-bottom">
                    <img src={Flower_right} alt="flower_left" />
                    {
                        (page_number[2] === "3" || page_number[2] === "4") ?
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px"
                            }}>
                                <img src={Happy} alt="happy" className="Happy" style={{
                                    width: "194px",
                                    marginLeft: "-45px",
                                    marginBottom: "70px",
                                    position: "relative",
                                    zIndex: 3
                                }} />
                            </div>
                            :
                            <img src={Watch} alt="watch" className="watch" />
                    }
                    <img src={Flower_left} alt="flower_right" style={{
                        right: (page_number[2] === "3" || page_number[2] === "4") ? "34px" : "114px"
                    }} />
                </div>
                {
                    page_number[2] === "3" && <div style={{
                        fontSize: "9px",
                        fontWeight: "bold",
                        position: "absolute",
                        bottom: "31px",
                        right: "92px",
                        color: "white",
                        zIndex: 100,
                    }}>Please Note Product Will Be Delivered Within 15 Days</div>
                }
                <Outlet />
            </Box>
        </div>
    );
}

export default SmartBandhanIndex;
