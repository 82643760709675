// moment
import moment from "moment";

export const validatePhone = (phone) => {
  return String(phone).match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
};

// checks whether the offer date is valid and returns a boolean
// value representing whether it is still active
export const isOfferActive = (offer) => {
  if (!offer) return false;
  //   const endDate = moment(offer.to).add(1, "days");
  //   const now = new Date().getTime();
  //   const offerEndTime = new Date(endDate).getTime();
  //   return offerEndTime > now;
  const endDate = moment(offer.to);
  return endDate.isAfter(moment());
};

// truncates and returns the text string upto specified limit
export const truncatedText = (text, limit = 100) => {
  if (!text || text.length <= limit) return text;
  return text.slice(0, limit - 3) + "...";
};

// gets value from localstorage based on key
export const getItemFromStorage = (key) => {
  return localStorage.getItem(key);
};

// debouncer function
export const debounce = (func, delay = 1000) => {
  let timeoutId;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(context, args), delay);
  };
};

// formats a number to n fractional components
export const formatNumber = (number = 0, maxFractionDigits = 2) => {
  return new Intl.NumberFormat("en-IN", { maximumFractionDigits: maxFractionDigits }).format(number);
};
export const currencyFormat = (number) => {
  return new Intl.NumberFormat("hi-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 2,
  }).format(number);
};

export const formatDate = (date) => {
  return new Intl.DateTimeFormat("en-IN", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    timeZone: "Asia/Calcutta",
  }).format(new Date(date));
};

export const listFormat = (arr) => {
  const formatter = new Intl.ListFormat("en", { style: "long", type: "conjunction" });
  return formatter.format(arr);
};

export const isCouponActive = (coupon) => {
  if (!coupon) return false;

  const now = new Date().getTime();
  const couponEndTime = new Date(coupon.expire).getTime();
  return couponEndTime >= now;
};

// Function to calculate the remaining time until JWT expiration
export const calculateTimeUntilExpiration = (token) => {
  const tokenData = JSON.parse(window.atob(token.split(".")[1]));
  const expirationTime = tokenData.exp * 1000; // Convert expiration time to milliseconds
  const currentTime = new Date().getTime();
  const remainingTime = expirationTime - currentTime;

  return remainingTime;
};

export const AdminLogout = (userType, navCallback) => {
  const keyName = userType === "admin" ? process.env.REACT_APP_ADMIN_TOKEN_KEY : process.env.REACT_APP_RETAILER_TOKEN_KEY;
  localStorage.removeItem(keyName);
  navCallback?.("/admin-login");
};

export const generateUniqueIdentifier = () => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let identifier = "";

  for (let i = 0; i < 10; i++) {
    // Adjust the length of the identifier as needed
    const randomIndex = Math.floor(Math.random() * characters.length);
    identifier += characters[randomIndex];
  }

  return identifier;
};
