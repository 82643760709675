import React, { useEffect, useReducer, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { getAllExistingEans, updateCupon } from "../../api/AdminApis/Cupon";
import { toast } from "react-toastify";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";

function reducer(state, action) {
  const existingEANOptions = [...state.eanOptions];
  switch (action.type) {
    case "SET_EAN_VALUE":
      return { ...state, eanValue: action.payload };
    case "ADD_SELECTED_EAN":
      return {
        ...state,
        selectedEans: [...state.selectedEans, action.payload],
        eanOptions: existingEANOptions.map((obj) => {
          if (obj.label === action.payload.label) {
            return {
              ...obj,
              selected: true,
            };
          } else return obj;
        }),
      };
    case "REMOVE_SELECTED_EAN":
      return {
        ...state,
        selectedEans: state.selectedEans.filter((ean) => ean !== action.payload),
        eanOptions: existingEANOptions.map((obj) => {
          if (obj.label === action.payload.label) {
            return {
              ...obj,
              selected: false,
            };
          } else return obj;
        }),
      };
    case "SET_ALL_EANS":
      return {
        ...state,
        eanOptions: action.payload.products.map((ean) => ({
          label: ean,
          value: ean,
          selected: action.payload.data.includes(ean),
        })),
        selectedEans: action.payload.data.map((ean) => ({
          label: ean,
          value: ean,
          selected: true,
        })),
      };
    default:
      return state;
  }
}

const EditCuponModal = (props) => {
  const { data } = props;
  const id = data._id;

  const initialState = {
    eanValue: "",
    selectedEans: [],
    eanOptions: [],
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedDate, setSelectedDate] = useState(new Date());
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllExistingEans();
        if (response?.length > 0) {
          dispatch({
            type: "SET_ALL_EANS",
            payload: {
              products: response,
              data: data?.products ?? [],
            },
          });
        } else {
          toast.error("Something went wrong in Fetching Product EANs.");
        }
      } catch (error) {
        console.err(error);
        toast.error("Oops! Something went wrong");
      }
    };

    fetchData();
  }, []);

  const submitHandle = async (e) => {
    e.preventDefault();

    if (state.selectedEans.length <= 0) {
      return toast.error("Please select EAN(s)");
    }
    let maxAmount = e.target.maxAmount.value;
    let discAmount = e.target.discount.value;
    if (parseInt(maxAmount) <= parseInt(discAmount)) {
      return toast.error("Please Enter Correct Amount");
    }
    const submitData = {
      discount: e.target.discount.value,
      maxAmount: e.target.maxAmount.value,
      expire: data.expire,
      products: state.selectedEans.map((e) => e.value),
      code: e.target.code.value,
    };

    const response = await updateCupon(id, submitData);
    if (response.status === "success") {
      toast.success("Coupon updated successfully!");
    }
    props.onClose();
  };

  const handleEanChange = (event, newValue) => {
    dispatch({ type: "SET_EAN_VALUE", payload: newValue });
  };

  const handleEanSelect = (event, newValue) => {
    if (newValue) {
      dispatch({ type: "ADD_SELECTED_EAN", payload: newValue });
      dispatch({ type: "SET_EAN_VALUE", payload: "" });
    }
  };

  const handleRemoveEan = (eanToRemove) => {
    dispatch({ type: "REMOVE_SELECTED_EAN", payload: eanToRemove });
  };

  const handleDate = (value) => {
    if (value) {
      data.expire = value;
      setSelectedDate(value);
    }
  };

  return (
    <>
      <Dialog {...props} onClose={props.onHide} fullWidth>
        <div className="d-flex justify-content-between">
          <DialogTitle>Edit Coupon</DialogTitle>
          <DialogActions>
            <CloseIcon onClick={props.onClose} className="cursor-pointer" />
          </DialogActions>
        </div>

        <DialogContent>
          <form onSubmit={submitHandle} className="d-flex flex-column gap-3 my-4">
            <div>
              <label className="mb-1">Expires In:</label>
              <br />
              <DatePicker
                value={selectedDate}
                selected={new Date(data.expire)}
                minDate={new Date()}
                onChange={(value) => handleDate(value)}
                dateFormat="dd/MM/yyyy"
                popperPlacement="top"
                className={" input-field custom-date-picker"}
              />
            </div>
            <TextField label="Discount (in Rs)" autoFocus placeholder="Discount (in Rs)" type="number" name="discount" defaultValue={data.discount} required />
            <TextField label="Max Amount" type="number" name="maxAmount" required placeholder="Max Amount" defaultValue={data.maxAmount} />
            <TextField label="Code (Name should be alphanumeric only)" placeholder="Code" required disabled readOnly type="text" defaultValue={data.code} name="code" />

            <Box>
              <Autocomplete
                className="my-2"
                value={null}
                onChange={handleEanSelect}
                inputValue={state.eanValue}
                onInputChange={handleEanChange}
                options={state.eanOptions}
                getOptionLabel={(option) => option?.label || ""}
                getOptionDisabled={(option) => option.selected}
                filterOptions={(options, { inputValue }) => {
                  return options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()));
                }}
                renderInput={(params) => <TextField {...params} label="Enter EAN" variant="outlined" />}
                fullWidth
              />
              <Box mt={2}>
                {state.selectedEans.map((ean, index) => (
                  <Chip key={index} label={ean.label} onDelete={() => handleRemoveEan(ean)} variant="outlined" style={{ marginRight: "8px", marginBottom: "8px" }} />
                ))}
              </Box>
            </Box>

            <div className="d-flex justify-content-end">
              <Button type="submit" className="btn btn-primary mr-auto float-right mt-2 px-5" variant="contained">
                Update
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditCuponModal;
