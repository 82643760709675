import axios from "./axiosInstance";

const headers = {
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
  "Content-Type": "application/json",
  "Access-Control-Allow-origin": "*",
};

export const addProductCatalogue = async (
  L1Selected,
  L2Selected,
  L3Selected,
  classificationSelected,
  prodPreviewData,
  dynamicHeaderDemo,
  technicalDetailsTable,
  alternateColorArray,
  alternateSpecArray,
  slug,
  immediateComplimentaryArray,
  laterComplimentaryArray,
  reference_url,
  hsn,
  gstPerct,
  height,
  weight,
  pwidth,
  length,
  sku,
  selectedNotifications,
  isRefundable
) => {
  console.log("prodPreviewData ", prodPreviewData);
  let addProductResponse;

  let addProductBody = [
    {
      dynamicHeader: dynamicHeaderDemo,
      title: prodPreviewData.name,
      ean: prodPreviewData.ean,
      slug: slug,
      description: prodPreviewData.description,
      type: L2Selected,
      qty: prodPreviewData.stock,
      hierarchyL1: L1Selected,
      hierarchyL2: L2Selected,
      hierarchyL3: L3Selected,
      classification: classificationSelected,
      productInfo: technicalDetailsTable,
      modelNo: technicalDetailsTable.modelNo,
      brand: prodPreviewData.brand,
      color: prodPreviewData.color,
      HSNDetails: { store_id: "", HSN: prodPreviewData.HSN },
      hsn,
      gstpercentage: gstPerct,
      // inwardDate: prodPreviewData.inwardDate,
      price: {
        mrp: parseFloat(prodPreviewData.price.mrp).toFixed(2),
        mop: parseFloat(prodPreviewData.price.mop).toFixed(2),
      },
      preBook: {
        amount: Number.parseInt(prodPreviewData?.preBookAmount) ?? 0,
        isRefundable: prodPreviewData?.isRefundable,
      },
      altProduct: {
        color: alternateColorArray,
        spec: alternateSpecArray,
      },
      complimentoryCatgories: {
        immediate: immediateComplimentaryArray,
        later: laterComplimentaryArray,
      },
      reference_url,
      height,
      weight,
      width: pwidth,
      length,
      SKU: Number.parseInt(sku),
      notificationType: selectedNotifications.map((item) => item.value),
    },
  ];

  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  const header1 = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `bearer ${adminToken}`,
  };

  await axios
    .post(`${process.env.REACT_APP_BASE_URL}/product/pos/addProductInMaster`, JSON.stringify(addProductBody), { headers: header1 })
    .then((res) => {
      addProductResponse = res?.data;
    })
    .catch((err) => {
      addProductResponse = err?.response?.data;
    });

  return addProductResponse;
};

/* export const addBulkProduct = async (order) => {
    let addBulkProductResponse;
    await axios
        .post(`${process.env.REACT_APP_BASE_URL}/product/`, JSON.stringify(order), { headers })
        .then((res) => {
            addBulkProductResponse = res.data;
        })

    return addBulkProductResponse;
}; */

export const updateMasterCatalogue = async (products) => {
  let catalogueRes;

  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const header1 = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `bearer ${adminToken}`,
  };

  await axios.post(`${process.env.REACT_APP_BASE_URL}/product/catalogue`, products, { header1 }).then((res) => {
    catalogueRes = res.data;
  });

  return catalogueRes;
};

export const handleCreateUpdateSeo = async (data) => {
  let catalogueRes;

  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const header1 = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `bearer ${adminToken}`,
  };

  await axios.post(`${process.env.REACT_APP_BASE_URL}/product/catalogue/seo`, data, { header1 }).then((res) => {
    catalogueRes = res.data;
  });

  return catalogueRes;
};

export const deleteSeoData = async () => {
  let deleteres;

  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const header1 = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `bearer ${adminToken}`,
  };

  await axios.delete(`${process.env.REACT_APP_BASE_URL}/product/catalogue/seo`, { header1 }).then((res) => {
    deleteres = res.data;
  });

  return deleteres;
};

//Delete Product From Catalogue
export const deleteProductCatalogue = async (id) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  const header1 = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `bearer ${adminToken}`,
  };

  let deleteProductResponse;
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/product/getProductWithActiveOffers/${id}`, { headers: header1 }).then((res) => {
    deleteProductResponse = res;
  });

  return deleteProductResponse;
};

//Update Product From Catalogue
export const updateProductCatalogue = async (
  L1Selected,
  L2Selected,
  L3Selected,
  classificationSelected,
  prodPreviewData,
  dynamicHeaderDemo,
  technicalDetailsTable,
  alternateColorArray,
  alternateSpecArray,
  slug,
  immediateComplimentaryArray,
  laterComplimentaryArray,
  discount,
  reference_url,
  hsn,
  gstPerct,
  height,
  weight,
  pwidth,
  length,
  imagesObject,
  isUpdateSequence,
  sku,
  selectedNotifications
) => {
  let updateProductResponse;
  let updateProductBody = {
    dynamicHeader: dynamicHeaderDemo,
    title: prodPreviewData.name,
    ean: prodPreviewData.ean,
    slug: slug,
    description: prodPreviewData.description,
    type: L2Selected,
    qty: prodPreviewData.stock,
    hierarchyL1: L1Selected,
    hierarchyL2: L2Selected,
    hierarchyL3: L3Selected,
    classification: classificationSelected,
    productInfo: technicalDetailsTable,
    modelNo: technicalDetailsTable.modelNo,
    brand: prodPreviewData.brand,
    color: prodPreviewData.color,
    hsn,
    gstpercentage: gstPerct,
    // inwardDate: prodPreviewData.inwardDate,
    price: {
      mrp: parseFloat(prodPreviewData.price.mrp).toFixed(2),
      mop: parseFloat(prodPreviewData.price.mop).toFixed(2),
    },
    preBook: {
      amount: Number.parseInt(prodPreviewData?.preBookAmount) ?? 0,
      isRefundable: prodPreviewData?.isRefundable,
    },
    altProduct: {
      color: alternateColorArray,
      spec: alternateSpecArray,
    },
    complimentoryCatgories: {
      immediate: immediateComplimentaryArray,
      later: laterComplimentaryArray,
    },
    discount,
    reference_url,
    height,
    weight,
    width: pwidth,
    length,
    SKU: Number.parseInt(sku),
    notificationType: selectedNotifications.map((item) => item.value),
  };
  const updatedImages = imagesObject.imgsUrl.filter((e) => e?.toString()?.slice(0, 4) !== "blob");
  if (isUpdateSequence) {
    updateProductBody.images = updatedImages;
  }
  await axios.patch(`${process.env.REACT_APP_BASE_URL}/product/${prodPreviewData.id}`, JSON.stringify(updateProductBody), { headers }).then((res) => {
    updateProductResponse = res;
  });

  return updateProductResponse;
};

// Add Product Images
export const addProductImages = async (id, images) => {
  let addProductImagesResponse;

  const headers = {
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
    "Content-Type": "application/json",
    "Access-Control-Allow-origin": "*",
  };

  const formData = new FormData();
  for (let i = 0; i < images.length; i++) {
    formData.append("image", images[i]);
  }

  await axios.patch(`${process.env.REACT_APP_BASE_URL}/product/${id}`, formData, { headers }).then((res) => {
    addProductImagesResponse = res;
  });

  return addProductImagesResponse;
};

// dELETE Product Images
export const deleteProductImages = async (id, deleteProductImage) => {
  const headers = {
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
    "Content-Type": "application/json",
    "Access-Control-Allow-origin": "*",
  };
  let deleteProductImagesResponse;

  if (deleteProductImage?.length > 0) {
    let obj = {
      delete: deleteProductImage,
    };

    await axios
      .delete(`${process.env.REACT_APP_BASE_URL}/product/image/${id}`, { headers, data: JSON.stringify(obj) })
      .then((res) => {
        deleteProductImagesResponse = res;
      })
      .catch((err) => {
        deleteProductImagesResponse = err?.response?.data;
      });
  }

  return deleteProductImagesResponse;
};

// Add Product Gallery Images
export const addProductGalleryImages = async (id, images) => {
  const headers = {
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
    "Content-Type": "application/json",
    "Access-Control-Allow-origin": "*",
  };
  let addProductGalleryImagesResponse;

  if (images.length > 0) {
    const formData = new FormData();

    for (let i = 0; i < images.length; i++) {
      formData.append("gallery", images[i]);
    }

    await axios.patch(`${process.env.REACT_APP_BASE_URL}/product/gallery/${id}`, formData, { headers }).then((res) => {
      addProductGalleryImagesResponse = res;
    });
  }

  return addProductGalleryImagesResponse;
};

// dELETE Gallery Images
export const deleteGalleryImages = async (id, deleteGalleryImage) => {
  const headers = {
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
    "Content-Type": "application/json",
    "Access-Control-Allow-origin": "*",
  };
  let deleteGalleryImagesResponse;

  if (deleteGalleryImage?.length > 0) {
    let obj = {
      delete: deleteGalleryImage,
    };

    await axios.delete(`${process.env.REACT_APP_BASE_URL}/product/gallery/${id}`, { headers, data: JSON.stringify(obj) }).then((res) => {
      deleteGalleryImagesResponse = res;
    });
  }

  return deleteGalleryImagesResponse;
};

//Update Product from Add Offers Page
export const updateProductOffers = async (product) => {
  let updateOffersResponse;
  let productID = product._id;

  await axios.patch(`${process.env.REACT_APP_BASE_URL}/product/${productID}`, JSON.stringify(product), { headers }).then((res) => {
    updateOffersResponse = res;
  });
  return updateOffersResponse;
};

// Invokes backend api to initialise product master
export const initializeProductMaster = async (products) => {
  let initMasterRes;

  await axios.post(`${process.env.REACT_APP_BASE_URL}/product/catalogue/initialize-master`, JSON.stringify(products), { headers }).then((res) => {
    initMasterRes = res.data;
  });
  return initMasterRes;
};
export const downloadCsv = async (products) => {
  let initMasterRes;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/product/catalogue/download-csv`, { headers }).then((res) => {
    initMasterRes = res.data;
  });
  return initMasterRes;
};

// Check Combo product Stock Status
export const checkComboProductStockStatusAPICaller = async (query) => {
  let response;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/product/catalogue/check-combo-status?${query}`, { headers }).then((res) => {
    response = res.data;
  });
  return response;
};
