import React, { useRef, useEffect, useState } from "react";
import "./Products.css";
import ProductCard from "../Cards/ProductCard";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import useScreen from "../../utils/ScreenSizeCalculation";
import { Skeleton, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useUserCtx from "../../hooks/useUserCtx";
import ProductBoxSkeleton from "../ProductBoxSkeleton";
import { TweenMax } from "gsap";
import { getHomePageSections } from "../../api/AdminApis/HomePageSections";

const Products = ({ productArray, heading, posterUrl, targetUrl, sectionType }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:1000px)");
  const { userWishlist, cartArray } = useUserCtx();

  const btnpressprev = (e) => {
    const element = e.currentTarget.nextElementSibling;
    let width = element.clientWidth;
    element.scrollLeft = element.scrollLeft - width + 20;
    const prevButton = e.currentTarget;
    const nextButton = e.currentTarget.nextElementSibling;
    const nextNextElement = nextButton.nextElementSibling;
    // Disable previous button if there's no previous element to scroll to
    prevButton.readOnly = element.scrollLeft <= 0 ? true : false;
    // Ensure next button is enabled
    nextNextElement.readOnly = false;
    if (nextNextElement.classList.contains("blurnxtBtn")) {
      nextNextElement.classList.remove("blurnxtBtn");
    }
    if (element.scrollLeft <= 0) {
      prevButton.classList.add("blurPrevBtn");
      element.classList.add("no-scrollonleft");
      setTimeout(() => {
        element.classList.remove("no-scrollonleft");
      }, 500);
    }
  };

  const btnpressnext = (e) => {
    const element = e.currentTarget.previousElementSibling;
    const scrollContainerWidth = element.clientWidth;

    // Get the width of each product
    const productWidth = element.querySelector(".product-card").clientWidth;
    // Calculate the number of products that can be fully visible in the scroll container
    const fullyVisibleProducts = Math.floor(scrollContainerWidth / productWidth);

    // Calculate the number of products to scroll
    const productsToScroll = fullyVisibleProducts > 1 ? fullyVisibleProducts - 1 : 1;

    // Calculate the width to scroll
    const scrollWidth = productsToScroll * productWidth;

    // Calculate the new scroll left position
    const newScrollLeft = element.scrollLeft + scrollWidth + 20;

    // Scroll to the new position
    element.scroll({
      left: newScrollLeft,
      behavior: "smooth",
    });

    // Update button states if needed
    const prevButton = e.currentTarget.previousElementSibling.previousElementSibling;
    const nextButton = e.currentTarget;

    const tempWidth = Math.ceil(element.scrollLeft + element.clientWidth);
    nextButton.readOnly = tempWidth >= element.scrollWidth;
    prevButton.readOnly = false;
    if (prevButton.classList.contains("blurPrevBtn")) {
      prevButton.classList.remove("blurPrevBtn");
    }
    if (tempWidth >= element.scrollWidth) {
      nextButton.classList.add("blurnxtBtn");
      element.classList.add("no-scrollonright");

      setTimeout(() => {
        element.classList.remove("no-scrollonright");
      }, 500);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
      {useScreen() !== "desktop" && sectionType === "scroll_card_mobile" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0.5rem",
          }}
        >
          <Link to={targetUrl ?? "/"} style={{ width: "100%" }}>
            <img src={posterUrl} alt={heading} style={{ height: "75px", width: "100%", borderRadius: "10px" }} />
          </Link>
        </div>
      )}
      <div className="products-component">
        {!isMobile && (
          <div
            className="aside"
            style={{
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              margin: "0rem 0rem 1.2rem 0.7rem",
              width: "16%",
              height: "490px",
              zIndex: 1,
              paddingTop: "4px",
            }}
          >
            <Link to={targetUrl ?? "/"}>
              <>
                <img src={posterUrl} alt={heading} style={{ height: "490px", width: "104%", objectFit: "cover", borderRadius: "10px" }} />
                <span
                  style={{
                    position: "absolute",
                    color: "white",
                    top: "87%",
                    left: "31%",
                    padding: "0px 10px",
                    backgroundColor: "#ffcc0a",
                    padding: "5px 10px",
                    borderRadius: "10px",
                    fontWeight: "600",
                  }}
                >
                  Shop Now
                </span>
              </>
            </Link>
          </div>
        )}
        <div style={{ width: `${isMobile ? "100%" : "81%"}`, zIndex: 0 }}>
          {!isMobile && (
            <button className="pre-btn" onClick={btnpressprev}>
              <KeyboardArrowLeftIcon />
            </button>
          )}

          <div className="products">
            {productArray?.length <= 0 ? (
              <div className="d-flex gap-4">
                {new Array(5).fill(true).map((_, index) => (
                  <ProductBoxSkeleton key={`Product_Box_${index}_${heading}`} height="490px" />
                ))}
              </div>
            ) : (
              <>
                {productArray
                  .filter((prod) => {
                    switch (heading) {
                      case "TOP DEALS":
                        return prod.topDiscount === true;
                      case "NEW ARRIVALS":
                        return prod.newArrival === true;
                      case "BEST SELLER":
                        return prod.topSeller === true;
                      case "UPCOMING LAUNCHES":
                        return prod.upcomingLaunches === true;
                      default:
                        return false;
                    }
                  })
                  .map((e, i) => (
                    <ProductCard
                      key={`${i}-${e._id}`}
                      id={e._id ?? ""}
                      bestSeller={e?.bestSeller}
                      productImg={e.images[0]}
                      productName={e.title}
                      productDescription={e.description[0]?.split(" ")?.slice(0, 18)?.join(" ")}
                      productRating={e?.rating ?? 0}
                      productPriceObj={e?.price}
                      productDiscountObj={e?.discount}
                      productSlug={e?.slug}
                      wishlist={userWishlist.wishlist_items?.some((obj) => obj._id?.toString() === e._id?.toString())}
                      cart={cartArray?.no_of_carts > 0 && cartArray?.cart.some((obj) => obj._id?.toString() === e._id?.toString())}
                      classification={e?.classification}
                      preBookData={e?.preBook}
                    />
                  ))}
              </>
            )}
          </div>
          {!isMobile && (
            <button className="next-btn" onClick={btnpressnext}>
              <KeyboardArrowRightIcon />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
