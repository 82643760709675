import React, { useEffect } from "react";

import { Autocomplete, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { categoriesList2 } from "../../utils/Constants";
import { faTrashAlt, faEdit, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CategoryPageAdminPannel({ categoryName, handleSelectedCategory, handleDelete, allBottomSections }) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div>
      {allBottomSections.length > 0 &&
        allBottomSections.map((section, index) => {
          if (section.sectionType === "static_deals") {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  gap: "5px",
                  margin: "20px 0",
                }}
              >
                <section className="home-page-section-body p-2 rounded">
                  <h6>{section.sectionHeading && `${section.sectionHeading} `} (Static Deals Section)</h6>
                  {section.products.map((banner, index) => (
                    <div
                      style={{
                        display: "flex",
                        gap: "15px",
                        marginTop: "20px",
                      }}
                      key={index}
                    >
                      <img width={300} height={200} style={{ objectFit: "contain" }} src={banner.image} alt={banner.title} />
                      <div>
                        <p>Title - {banner.title}</p>
                        <p>Target URL - {banner.targetUrl}</p>
                      </div>
                    </div>
                  ))}
                  <div>Order: {section.priority}</div>
                </section>
                <aside className="home-page-section-aside">
                  <FontAwesomeIcon
                    size="lg"
                    onClick={() =>
                      navigate("../admin-home-add-section", {
                        state: {
                          type: "static_deals",
                          updateProduct: true,
                          data: section,
                          from: location.pathname,
                        },
                      })
                    }
                    cursor="pointer"
                    icon={faEdit}
                  />
                  <FontAwesomeIcon size="lg" className="text-danger" cursor="pointer" onClick={() => handleDelete(section._id)} icon={faTrashAlt} />
                </aside>
              </div>
            );
          }
          return null; // if sectionType is not "static_deals", return null
        })}
    </div>
  );
}

export default CategoryPageAdminPannel;
