import "./step1.css";
// import Happy from "../../assets/IFD/Happy.png"
import MainLogo from "../../assets/IFD/main-logo.png";
// import crackers from "../../assets/IFD/crackers.svg"
import { Box } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import GiveawayContest from "../../assets/png/GiveawayContest.png";
import TermsAndConditionModal from "./TermsAndConditionModal";
function IFDIndex() {
  const location = useLocation();
  const page_number = location.pathname.split("/");

  useEffect(() => {
    document.title = "Quiz Contest | O-LINE-O";

    return () => {
      document.title = "O-LINE-O Nexus";
    };
  }, []);

  function handleUnLoad(event) {
    event.returnValue = null;
  }
  const [modalName, setModalName] = useState(null);

  const [show, setShow] = useState(false);
  useEffect(() => {
    window.addEventListener("beforeunload", handleUnLoad);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      const confirmationMessage = "Are you sure you want to leave?";

      e.returnValue = confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div
      className="w-100 Main2"
      // style={{
      //     position: "relative",
      //     //CSS for ThankYou Image (Main Page)
      //     minHeight: "87ch"
      // }}
    >
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
        }}
        style={{ marginTop: page_number[2] === "3" ? "26px" : "155px" }}
        noValidate
        autoComplete="off"
        className="sub-container"
        data-pagenumber={
          ["/quiz-Offer/", "/quiz-Offer"].includes(location.pathname)
            ? "first"
            : page_number[2] === "2"
            ? "second"
            : page_number[2] === "3"
            ? "third"
            : page_number[2] === "4"
            ? "fourth"
            : page_number[2] === "5"
            ? "fifth"
            : page_number[2] === "6"
            ? "sixth"
            : ""
        }
      >
        <div className="main-logo" style={{ marginTop: "20px", height: "150px" }}>
          <img src={GiveawayContest} alt="main-logo" />
        </div>
        <Outlet />
        <Link
          onClick={() => {
            setModalName("terms");
            setShow(true);
          }}
        >
          <div className="tnc-apply">T&C Apply</div>
        </Link>
      </Box>

      {show && modalName === "terms" && <TermsAndConditionModal show={show} onHide={() => setShow(false)} />}
    </div>
  );
}

export default IFDIndex;
