import React from "react";
import { ListGroup } from "react-bootstrap";
import { getAdminIndOrder, getAdminIndividualConsignment } from "../../api/AdminApis/AdminOrder";
import { approveCancelation, getAllCancelation } from "../../api/AdminApis/Cancel";
import CancelAdminApprove from "../../components/CancelationProductModal/CancelAdminApprove";
import CancelationProductModal from "../../components/CancelationProductModal/CancelationProductModal";
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import AdminConsignmentDetailsModal from "../../components/OrderProductModal/AdminConsignmentDetailsModal";
import { listFormat } from "../../utils";
import { toast } from "react-toastify";

// Component definition
const Cancellation = () => {
  // state definitions
  const [loader, setLoader] = React.useState(false);
  const [, setCancellation] = React.useState([]);
  const [orderItem, setOrderItem] = React.useState();
  const [cancelObj, setCancelObj] = React.useState();
  const [show, setShow] = React.useState(false);
  const [showSecModal, setShowSecModal] = React.useState(false);
  const [delCharges, setDelCharges] = React.useState("");
  const [cancelRequest, setCancelRequest] = React.useState([]);
  const [cancelProcessed, setCancelProcessed] = React.useState([]);
  const [consignmentSelected, setConsignmentSelected] = React.useState(null);
  const [details, setDetails] = React.useState(null);

  React.useEffect(() => {
    setLoader(true);
    getAllCancelation()
      .then((res) => {
        if (res) {
          setCancellation(res.cancellation);
          if (res.no_of_cancellation > 0) {
            let reqCancel = [];
            let processCancel = [];
            res.cancellation.forEach((item) => {
              if (item.approved) {
                processCancel.push(item);
              } else {
                reqCancel.push(item);
              }
            });
            setCancelRequest(reqCancel);
            setCancelProcessed(processCancel);
          }
        }
      })
      .finally(() => setLoader(false));
  }, []);

  const seeProduct = (id, modalShow = true) => {
    getAdminIndOrder(id).then((res) => {
      if (res) {
        setOrderItem(res.order);
      }
    });
    if (modalShow) {
      setShow(true);
    }
  };

  const handleApproveCancel = (id) => {
    approveCancelation(id, delCharges).then((res) =>
      res
        ? (setLoader(true),
          getAllCancelation().then((res) => {
            setCancellation(res.cancellation);
            setLoader(false);
            setDelCharges("");
          }))
        : ""
    );
  };

  const handleApproveClick = (item) => {
    seeProduct(item.orderId, false);
    setCancelObj(item);
    setShowSecModal(true);
  };

  const getAConsignmentFunc = (id) => {
    getAdminIndividualConsignment(id).then((res1) => {
      if (res1?.details) {
        setDetails(res1.details);
        setShow(true);
        setConsignmentSelected(res1.details._id);
      } else toast.error("Something went wrong in fetching Individual Consignment");
    });
  };

  // Main renderer
  return loader ? (
    <DashboardLoader />
  ) : (
    <div className="container">
      {/* <CancelationProductModal
                show={show}
                onHide={() => setShow(false)}
                orderItem={orderItem}
            /> */}
      {/* <CancelAdminApprove
                show={showSecModal}
                setShowSecModal={setShowSecModal}
                orderItem={orderItem}
                cancelObj={cancelObj}
                setDelCharges={setDelCharges}
                delCharges={delCharges}
                handleApproveCancel={handleApproveCancel}
            /> */}
      {show && <AdminConsignmentDetailsModal show={show} onHide={() => setShow(false)} details={details} consignmentSelected={consignmentSelected} />}
      <div className="d-flex justify-content-between">
        <h3>Order Cancellation</h3>
      </div>
      <ListGroup as="ol">
        {loader ? (
          <DashboardLoader />
        ) : cancelRequest?.length > 0 ? (
          cancelRequest?.map((item, index) => (
            <>
              <ListGroup.Item key={index} as="li" className="d-flex my-2 gap-2 justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{index + 1}.</div>
                </div>
                <div className="ms-2 me-auto">
                  <div>
                    <span className="fw-bold">Order ID:</span> {item.orderId}
                  </div>
                  <div>
                    <span className="fw-bold">Consignment ID:</span>{" "}
                    <span className="text-decoration-underline cursor-pointer" onClick={() => getAConsignmentFunc(item.consignmentId)}>
                      {item.consignmentId}
                    </span>
                  </div>
                  <span className="fw-bold"> Item Id</span> {Array.isArray(item.itemId) ? listFormat(item.itemId) : ""}
                </div>
                <div className="ms-2 me-auto">
                  <div>
                    <span className="fw-bold">Amount:</span> {item.amount}
                  </div>
                  {/* <td className="text-primary" style={{ cursor: "pointer" }} onClick={() => seeProduct(item.orderId)}>See product</td> */}
                </div>
                <div className="ms-2 me-auto">
                  <div>
                    <span className="fw-bold">Reason:</span> {item.reason}
                  </div>
                </div>
                {/* <div className="ms-2 d-flex justify-content-between align-items-center gap-3">
                                            <button className='btn btn-secondary'>Reject</button>
                                            <button className='btn btn-primary' onClick={() => handleApproveClick(item)} >Approve</button>
                                        </div> */}
              </ListGroup.Item>
            </>
          ))
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center p-3">
              <h5 className="fw-bold">No Request Found</h5>
            </div>
          </>
        )}
      </ListGroup>
      {/* <div className='mt-5'>
                <h4>Cancellation Processed</h4>
            </div>
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Cancel Id</th>
                            <th scope="col">Order Id</th>
                            <th scope="col">Item Id</th>
                            <th scope="col">Refund Amount</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(cancelProcessed?.length > 0) ? (
                            cancelProcessed?.map((item, index) => (
                                <>
                                    <tr key={index}>
                                        <td> {item?._id} </td>
                                        <td>{item?.orderId}</td>
                                        <td>{item?.itemId[0]}</td>
                                        <td>₹{item?.refundAmt}</td>
                                        <td>{item?.approved ? 'Approved' : 'Rejected'}</td>
                                        <td className='text-primary pe-auto' onClick={() => seeProduct(item.orderId)}>See Product</td>
                                    </tr>
                                </>
                            ))) : (<>
                                <div className='d-flex justify-content-between align-items-center p-3'>
                                    <h4 className='fw-bold'>No Data</h4>
                                </div>
                            </>)}
                    </tbody>
                </table>
            </div> */}
    </div>
  );
};

export default Cancellation;
