import React, { useCallback, useEffect, useRef, useState } from "react";
import "./findStore.css";
// import FindStoreMap from "./findStoreMap";
import FindStoreContact from "./findStoreContact";
import { getAllStores, getAllStoresLatAndLong, getStoreUsingPinCode, getStoreUsingSearchKey } from "../../api/StoreApi";
// import Loader from "../../components/Loader/Loader";
import useGeolocation from "../../hooks/useGeolocation";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import useUserCtx from "../../hooks/useUserCtx";
import locationWarningYellowIcon from "../../assets/vector/location_warning_yellow.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import loadingImg from "../../assets/png/loading.jpg";
import { useMediaQuery } from "@mui/material";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { MdMyLocation } from "react-icons/md";
import Mappelmap from "./Mappelmap";
import SEO from "../../components/SEO/SEO";
import useSiteCtx from "../../hooks/useSiteCtx";

export default function FindStore({ storeLocations, setStoreLocations }) {
  const { location, locationFetch } = useGeolocation();
  const { setUserLocation, userContext } = useUserCtx();
  const routeLocation = useLocation();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [storeSearchKey, setStoreSearchKey] = useState("");
  const [debounceQuery, setDebounceQuery] = useState("");
  const [showStore, setShowStore] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [findNearLocation, setFindNearLocation] = useState(false);
  const [totalStores, setTotalStores] = useState(0);
  const storeLimitPerPage = 20;
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [stores, setStores] = useState([]);

  const [storeLatLong, setStoreLatLang] = useState([]);
  const isMobile = useMediaQuery("(max-width:1000px)");
  const { seoData } = useSiteCtx();

  const breadCrumbsData = [
    {
      text: t("home"),
      url: "/",
      isActive: routeLocation.pathname === "/",
    },
    {
      text: t("storeFinder"),
      // url: "/store-finder",
      isActive: routeLocation.pathname === "/store-finder",
    },
  ];
  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    setShowStore(false);
    if (storeSearchKey.toString().length !== 0) return;
    const delay = 400;
    const timer = setTimeout(() => {
      getAllStores(storeLimitPerPage, pageNumber)
        .then((res) => {
          if (res?.status === "success") {
            setStoreLocations((prev) => ({
              ...prev,
              loaded: true,
              no_of_stores: prev?.no_of_stores + (res?.data?.no_of_stores ?? 0),
              stores: [...prev.stores, ...res?.data?.stores?.results],
            }));
            setStores((prev) => [...prev, ...res?.data?.stores?.results]);
            setTotalStores(res?.data?.stores?.total);
            setHasMore(res?.data?.stores?.next ? true : false);
            if (!res?.data?.stores?.next) {
              setHasMore(false);
            }
          }
        })
        .finally(() => {
          setShowStore(true);
          setShowLoader(false);
        });
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [pageNumber, storeSearchKey]);

  useEffect(() => {
    if (location.loaded && location.error) {
      setShowStore(false);
    } else if (location.loaded && location.coordinates) {
      setUserLocation({
        loaded: true,
        useThis: false,
        coordinates: location.coordinates,
        address: location.address,
      });
      getStoreUsingSearchKey(location.address.zip).then((res) => {
        if (res) {
          setStoreLocations({
            loaded: true,
            no_of_stores: res?.stores?.length,
            stores: res?.stores,
          });
          setStores(res?.stores);
          setTotalStores(res?.stores?.length);
          setShowStore(true);

          const allstores = res?.stores;

          var updatedStoreArr = allstores.map((store) => ({
            display_name: store.display_name,
            latitude: store.latitude,
            longitude: store.longitude,
          }));
          setStoreLatLang(updatedStoreArr ? updatedStoreArr : []);
          // setStoreSearchKey()
          setShowLoader(false);
        }
      });
    }
  }, [location]);

  const handleStoreSelectButton = async () => {
    // let userToken = userContext ? userContext.JWT : "";
    // if (userToken) {
    setFindNearLocation(true);
    await locationFetch();
    setShowLoader(true);
    // } else {
    //   toast.warning("Please Login to Search Location!");
    //   navigate("/login");
    // }
  };

  const refreshPage = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    setPageNumber(1);
    setStores([]);
    const timeOutId = setTimeout(() => {
      if (storeSearchKey) {
        setShowLoader(true);
        setDebounceQuery(storeSearchKey);
      }
    }, 700);

    return () => {
      clearTimeout(timeOutId);
    };
  }, [storeSearchKey]);

  const Caller = useCallback((value) => {
    if (!value) return;
    setShowStore(false);
    setShowLoader(true);
    if (isNaN(value)) {
      getStoreUsingSearchKey(value).then((res) => {
        if (res.status === "success") {
          setStoreLocations({
            loaded: true,
            no_of_stores: res?.stores?.length ?? 0,
            stores: res?.stores ?? [],
          });
          setStores(res?.stores ?? []);
          setTotalStores(res?.stores?.length);
          const allstores = res?.stores;
          var updatedStoreArr = allstores.map((store) => ({
            display_name: store.display_name,
            latitude: store.latitude,
            longitude: store.longitude,
          }));
          setStoreLatLang(updatedStoreArr ? updatedStoreArr : []);
        } else
          setStoreLocations({
            loaded: true,
            no_of_stores: 0,
            stores: [],
          });
      });
    } else {
      getStoreUsingPinCode(value).then((res) => {
        if (res.status === "success") {
          setStoreLocations({
            loaded: true,
            no_of_stores: res?.stores?.length ?? 0,
            stores: res?.stores ?? [],
          });
          setStores(res?.stores ?? []);
          setTotalStores(res?.stores?.length);
          const allstores = res?.stores;
          var updatedStoreArr = allstores.map((store) => ({
            display_name: store.display_name,
            latitude: store.latitude,
            longitude: store.longitude,
          }));
          setStoreLatLang(updatedStoreArr ? updatedStoreArr : []);
        } else
          setStoreLocations({
            loaded: true,
            no_of_stores: 0,
            stores: [],
          });
      });
    }
    setShowStore(true);
    setShowLoader(false);
  }, []);

  useEffect(() => {
    Caller(debounceQuery);
  }, [debounceQuery]);

  const handleInputChange = (e) => {
    setStoreSearchKey(e.target.value);
    if (e.target.value === "") setShowStore(false);
  };

  const observer = useRef();
  const lastStoreRef = useCallback(
    (node) => {
      if (showLoader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPageNumber((prev) => prev + 1);
          }
        },
        {
          root: null, // Use the viewport as the root
          threshold: 0.2, // 20% of the target visible
        }
      );
      if (node) observer.current.observe(node);
    },
    [showLoader, hasMore]
  );

  useEffect(() => {
    setShowLoader(true);
    const fetchData = async () => {
      try {
        const response = await getAllStoresLatAndLong();
        if (response?.status === "success") {
          setStoreLatLang(response?.data);
        } else {
          toast.error("Something went wrong in Fetching Stores.");
        }
      } catch (error) {
        toast.error("Oops! Something went wrong");
      }
    };

    fetchData();

    return () => {
      setStoreLatLang([]);
    };
  }, []);

  return (
    <div className="findStoreContainer">
      <SEO seoData={seoData} />
      <BreadCrumbs data={breadCrumbsData} />

      {/* {breadCrumbsData.map((item, index) => (
          <span className="slug-URL">
            <Link className={`normal-link ${item.isActive ? "text-black" : "text-black-50"} `} to={item.url} key={index}>
              {item.text}
            </Link>
          </span>
        ))} */}
      <div id="outermostDiv">
        <h1 className="findStoreMapTitle">{t("store.find-store-title")}</h1>
        <p className="findStoreMapInputTitle">{t("store.nearest-store")}</p>
        <div className="d-flex">
          <div className="findStoreMapSearchContainer">
            <div className="findStoreMapSearch">
              <SearchIcon className="findStoreMapSearchIcon" />
              <input type="search" placeholder={t("store.placeholder")} className="findStoreMapSearchbar" value={storeSearchKey} onChange={handleInputChange} />
            </div>
          </div>
          &nbsp;&nbsp; &nbsp;&nbsp;
        </div>
        {location && location.loaded && location.error && (
          <>
            <div className="location_Alert">
              <img src={locationWarningYellowIcon} alt="" />
              <p>{t("store.enable-from-browser")}</p>
            </div>
            <div className="location_Refresh">
              <p>{t("store.refresh-page")}</p>
              <div className="location_Refresh_Button">
                <button type="submit" className="submit-button" onClick={refreshPage}>
                  <p>{t("store.refresh")}</p>
                </button>
              </div>
            </div>
          </>
        )}
        {/* <div className="store_Page_Separtor store_Finder_Separtor">
          <span className="hr_Line"></span>
          <p>{t("store.or")}</p>
          <span className="hr_Line"></span>
        </div> */}

        {showLoader ? (
          <div className="loader-div rounded d-flex flex-column">
            <img src={loadingImg} alt="loading_tab" className="loading-image rounded" />
            Loading...
          </div>
        ) : (
          <>
            {!location.error ? (
              <>
                {totalStores > 0 ? (
                  <>
                    {/* <div className="" style={{ position: "relative", display: "flex", width: "100%", minHeight: "100vh" }}>
                  <div
                    className="w-50"
                    style={{
                      position: "sticky",
                      top: "100px", // Adjust as needed
                    }}
                  >
                    
                  </div>
                </div>
               */}
                    <div className="findStorecontainer2">
                      <div className="scrollable">
                        {totalStores ? (
                          <div className="stickyHeader">
                            <div className="stickyHeaderContent">
                              <span className="findStoreContactHeaderTitle">{`${t("store.found")} ${totalStores} ${t("store.stores-near")}`}</span>
                              {isMobile ? (
                                <button style={{ alignItems: "center" }} type="button" className="searchButton btn-sm" onClick={handleStoreSelectButton}>
                                  {t("store.nearMe")} <MdMyLocation />
                                </button>
                              ) : (
                                <button style={{ alignItems: "center" }} type="button" className=" searchButton btn-sm" onClick={handleStoreSelectButton}>
                                  {t("store.show-stores")} &nbsp;
                                  <MdMyLocation />
                                </button>
                              )}
                            </div>
                          </div>
                        ) : null}
                        {/* Content that overflows and scrolls */}
                        {/* <></> */}
                        <ul>
                          <FindStoreContact stores={stores} totalStores={totalStores} lastStoreRef={lastStoreRef} storeSearchKey={storeSearchKey} showStore={showStore} />
                        </ul>
                        {hasMore && !storeSearchKey && (
                          <div className="loader-div rounded d-flex flex-column">
                            <img src={loadingImg} alt="loading_tab" className="loading-image rounded" />
                            Loading...
                          </div>
                        )}
                      </div>
                      {!isMobile && (
                        <div className="sticky">
                          {/* Content that remains sticky */}
                          {/* <FindStoreMap stores={storeLatLong} /> */}
                          {storeLatLong.length > 0 && <Mappelmap stores={storeLatLong} />}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="nostore_Found_Text">
                    <p>{t("store.no-store")}</p>
                  </div>
                )}
              </>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}
