import React, { useEffect, useState } from "react";
import { getNewArrivalProductsForHomePage, getProductsFromEans } from "../../api/Product";
import Products from "../Products/Products";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Section4 = ({ id, heading, products, posterUrl, targetUrl, sectionType }) => {
  const [productArray, setProductArray] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (products.length <= 0) return;
    if (productArray.length > 0) return; // <--- Add this check
    const randomizedProducts = shuffleArray(products);
    setProductArray(randomizedProducts);
  }, [products, productArray]); // <--- Add productArray to the dependency array

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  return (
    <div className="section4_container" id={id}>
      <div className="section4_card_container1">
        <Products productArray={productArray} heading={heading} posterUrl={posterUrl} targetUrl={targetUrl} sectionType={sectionType} />
      </div>
    </div>
  );
};

export default Section4;
