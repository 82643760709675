// top level imports
import { useState, useEffect } from "react";

// React-Toast
import { toast } from "react-toastify";

// API
import { getStockDetails, handleDelteStock } from "../../../api/AdminApis/StockTracker";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Autocomplete from "@mui/material/Autocomplete";

// Components
import DashboardLoader from "../../../components/DashboardContent/DashboardLoader";

export function StockTracker() {
  // state definitions
  const [loading, setLoading] = useState(false);
  const [stocks, setStocks] = useState([]);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [statusName, setStatusName] = useState("Latest Added");
  const [statusValue, setStatusValue] = useState("dateNewest");

  useEffect(() => {
    fetchStockDetails("");
  }, [statusValue]);

  const showLoader = () => setLoading(true);

  const hideLoader = () => setLoading(false);

  const fetchStockDetails = async (queryStr) => {
    showLoader();
    try {
      let queryParams = `populate=yes&${queryStr}`;
      if (statusValue) {
        queryParams += `&sort=${statusValue}`;
      }
      const response = await getStockDetails(queryParams);
      if (response.status === "success") {
        setStocks(response.data);
      }
    } finally {
      hideLoader();
    }
  };

  const handleSelectedStatusChange = (e, value) => {
    if (value) {
      setStatusName(value.name);
      setStatusValue(value.value);
    }
  };

  const statusArr = [
    { name: "Latest Added", value: "dateNewest" },
    { name: "Old Stocks", value: "dateOldest" },
    { name: "High to Low Stock", value: "stockHighToLow" },
    { name: "Low to High Stock", value: "stockLowToHigh" },
  ];

  const handleUpdate = async (stockId) => {
    navigate(`edit/${stockId}`);
  };

  const handleDelete = async (stockId) => {
    const res = await handleDelteStock(stockId);
    if (res.status === 200) {
      toast.success("Stock Deleted!");
      fetchStockDetails("");
    } else {
      toast.error("Something Went Wrong!");
    }
  };

  function handleSearchClick(e) {
    e.preventDefault();
    if (searchValue) {
      fetchStockDetails(`ean=${searchValue}`);
    }
  }

  function handleSearchReset(e) {
    e.preventDefault();
    setSearchValue("");
    fetchStockDetails("");
  }

  // Main renderer
  return (
    <div className="container">
      <button
        onClick={() => navigate("../admin-products")}
        style={{
          all: "unset",
          marginBottom: "25px",
          cursor: "pointer",
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} /> Product Page
      </button>

      <div className="d-flex justify-content-between mb-2">
        <h3>Stocks (Store wise product quantity)</h3>
      </div>

      <div className="d-flex justify-content-between my-4">
        <div className="d-flex gap-2 justify-content-center align-items-center">
          <div>
            <TextField size="small" id="outlined-basic" label="Search Product OPIN" variant="outlined" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
          </div>
          <div>
            <Autocomplete
              id="statusSelected"
              className="form-control "
              size="small"
              options={statusArr}
              onChange={(e, value) => handleSelectedStatusChange(e, value)}
              getOptionLabel={(option) => option.name || ""}
              value={{
                name: statusName,
                value: statusValue,
              }}
              clearOnEscape={false}
              style={{ width: "300px", marginBottom: "0px" }}
              renderInput={(params) => <TextField aria-expanded="false" {...params} variant="standard" value={statusName} placeholder="Select Status" />}
            />
          </div>
          <Button variant="contained" onClick={handleSearchClick} disabled={!searchValue}>
            Search
          </Button>
          <Button variant="contained" onClick={handleSearchReset}>
            Reset
          </Button>
        </div>
        <Button size="small" variant="outlined" onClick={() => navigate("add")}>
          Add Stock
        </Button>
      </div>

      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>S. No.</th>
              <th>OPIN</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Action</th>
            </tr>
          </thead>
          {loading ? (
            <DashboardLoader />
          ) : stocks.length > 0 ? (
            <tbody>
              {stocks?.map((el, index) => {
                return (
                  <tr key={el._id}>
                    <td>{index + 1}</td>
                    <td>{el.ean}</td>
                    <td>{el.product?.title ?? "N.A"}</td>
                    <td>{el.quantity}</td>
                    <td style={{ display: "flex", gap: "5px" }}>
                      <button className="btn btn-success" onClick={() => handleUpdate(el._id)}>
                        Edit
                      </button>
                      <button className="btn btn-danger" onClick={() => handleDelete(el._id)}>
                        <FontAwesomeIcon className={"table-icon"} icon={faTrashCan} />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <h5 className="my-4">No Stock Added</h5>
          )}
        </table>
      </div>
    </div>
  );
}
