import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { saveUserDetails } from "../../api/OfferAPIs";
import OfferContext from "../../Contexts/OffersContext";

import FB from "../../assets/bharatmahostav/Asset 52.png";
import INSTA from "../../assets/bharatmahostav/Asset 54.png";
import TWITTER from "../../assets/bharatmahostav/Asset 53.png";
import YOUTUBE from "../../assets/bharatmahostav/youtube.png";
import SVGLoader from "./SVGLoader";

function Page1() {
  const { changeOfferId } = useContext(OfferContext);
  const [userInfo, setuserInfo] = useState({
    name: "",
    phone: "",
  });

  const [isIconClicked, setIsIconClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  function validateName(name) {
    let regex = /^[a-zA-Z ]{2,30}$/;
    return !regex.test(name);
  }

  function validatePhone(phone) {
    return !/^\d{10}$/.test(phone);
  }

  function validateEmail(email) {
    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !regex.test(email);
  }

  const validateData = () => {
    let isValid = true;
    if (!userInfo.name || !userInfo.phone) {
      isValid = false;
    }
    return isValid;
  };

  const onContinue = async () => {
    const payload = {
      name: userInfo.name,
      phone: userInfo.phone,
    };
    try {
      setLoading(true);
      if (validateName(userInfo.name)) {
        return toast.error("Invalid Name");
      }
      if (validatePhone(userInfo.phone)) {
        return toast.error("Invalid Phone Number");
      }

      const data = await saveUserDetails(payload);
      if (data?.data?.status === "error" || data?.status === 403) {
        toast.error(data?.data?.message ?? "Something went wrong");
      } else {
        data.OfferUserID && changeOfferId(data.OfferUserID);
        toast.success(data?.message);
        navigate("/quiz-Offer/2");
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  function handleIconCLick() {
    setIsIconClicked(true);
  }

  return (
    <div
      className="container position-relative conatiner-parent"
      style={{
        display: "grid",
        placeContent: "center",
      }}
    >
      {/* <img alt="thank-you" src={ThankYou}  className="thankYouImageMainPage" /> */}

      <div className="input-group mt-2">
        <TextField
          type="text"
          size="small"
          className="form-control fw-700"
          label="Customer's Name"
          variant="filled"
          value={userInfo.name}
          onChange={(e) => {
            setuserInfo({ ...userInfo, name: e.target.value });
          }}
          inputMode="text"
        />
      </div>
      <div className="input-group mt-2">
        <TextField
          type="number"
          size="small"
          className="form-control mt-2 fw-700"
          label="Customer's Number"
          variant="filled"
          value={userInfo.phone}
          onChange={(e) => {
            setuserInfo({ ...userInfo, phone: e.target.value });
          }}
          inputMode="numeric"
        />
        <br />
      </div>

      <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
        <div className="fw-bolder text-uppercase" style={{ color: "#2e457d" }}>
          Follow Us
        </div>
        <div className="d-flex gap-2 justify-content-center align-items-center my-2">
          <a href="https://www.facebook.com/OLineOIndia/" target="_blank" rel="noreferrer" onClick={handleIconCLick}>
            <img alt="facebook" src={FB} width={40} />
          </a>
          <a onClick={handleIconCLick} className="pl-2 " href="https://www.instagram.com/olineoindia/" target="_blank" rel="noreferrer">
            <img alt="insta" src={INSTA} width={40} />
          </a>
          <a onClick={handleIconCLick} href="https://twitter.com/olineoindia" target="_blank" className="pl-2 " rel="noreferrer">
            <img alt="twitter" src={TWITTER} width={40} />
          </a>
          <a onClick={handleIconCLick} href="https://www.youtube.com/@OLineOIndia" target="_blank" className="pl-2 icon-container" rel="noreferrer">
            <img alt="youtube" src={YOUTUBE} width={40} />
          </a>
        </div>
      </div>

      <button onClick={onContinue} type="button" className="btn btn-warning mt-3 w-100 fw-bolder page_1_submit_btn" id="submit-otp-btn" disabled={!validateData() || loading}>
        {loading ? <SVGLoader /> : <span>Continue</span>}
      </button>
    </div>
  );
}

export default Page1;
