import axios from "../axiosInstance";

export const getCupon = async () => {
  let cuponResponse;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/product/coupon`).then((res) => {
    cuponResponse = res.data;
  });

  return cuponResponse;
};
export const getSingleCupon = async (code) => {
  let cuponResponse;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/product/coupon/${code}`).then((res) => {
    cuponResponse = res.data;
  });

  return cuponResponse;
};

export const addCupon = async (data) => {
  let cuponResponse;

  await axios.post(`${process.env.REACT_APP_BASE_URL}/product/coupon`, data).then((res) => {
    cuponResponse = res.data;
  });

  return cuponResponse;
};

export const updateCupon = async (id, data) => {
  let cuponResponse;

  await axios.patch(`${process.env.REACT_APP_BASE_URL}/product/coupon/${id}`, data).then((res) => {
    cuponResponse = res.data;
  });

  return cuponResponse;
};

export const deleteCouponById = async (id) => {
  let response = null;
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/product/coupon/${id}`).then((res) => {
    response = res.data;
  });

  return response;
};

export const getAllExistingEans = async () => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let response;

  await axios
    .get(`${process.env.REACT_APP_BASE_URL}/product/get-all-eans`, { headers })
    .then((res) => {
      response = res.data.data;
    })
    .catch((err) => (response = err.response));

  return response;
};

export const getWalletCupon = async () => {
  let cuponResponse;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/product/walletCoupon`).then((res) => {
    cuponResponse = res.data;
  });

  return cuponResponse;
};

export const getSingleWalletCupon = async (couponeId) => {
  let cuponResponse;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/product/walletCoupon?couponId=${couponeId}`).then((res) => {
    cuponResponse = res.data;
  });

  return cuponResponse;
};

export const deleteSingleWalletCupon = async (couponeId) => {
  let cuponResponse;

  await axios.delete(`${process.env.REACT_APP_BASE_URL}/product/walletCoupon?couponId=${couponeId}`).then((res) => {
    cuponResponse = res;
  });

  return cuponResponse;
};

export const updateWalletCupon = async (bodyData) => {
  let cuponResponse;

  await axios.post(`${process.env.REACT_APP_BASE_URL}/product/walletCoupon`, bodyData).then((res) => {
    cuponResponse = res;
  });

  return cuponResponse;
};

export const getCouponsUserrWise = async (userId) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let cuponResponse;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/product/getCouponUserWise/${userId}`, { headers }).then((res) => {
    cuponResponse = res.data?.coupons;
  });

  return cuponResponse;
};

export const updateCouponStatus = async (userId, Id, status) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  let cuponResponse;
  const bodyData = {
    id: Id,
    status,
    userId,
  };

  await axios.post(`${process.env.REACT_APP_BASE_URL}/product/updateCouponStatus`, bodyData, { headers }).then((res) => {
    cuponResponse = res;
  });

  return cuponResponse;
};
