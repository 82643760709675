import React, { useEffect, useMemo, useState } from "react";
import { getAdminAllOrder, getAllOrderCounts } from "../../api/AdminApis/AdminOrder";
import { getAllStore } from "../../api/AdminApis/AdminStore";
import { allUsers } from "../../api/AdminApis/Users";
import { getAdminDashBoardData, getAllProducts } from "../../api/Product";
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import "./styles/dashboardHome.css";
import { useAdminOrRetailer } from "../../utils/useAdminOrRetailer";
import { useTranslation } from "react-i18next";
import { Chart } from "react-google-charts";
import { Skeleton } from "@mui/material";
import { Chart as Charts } from "react-charts";
import MyBarChart from "./MyBarChart";
import { categoriesList2 } from "../../utils/Constants";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

// Component definition
function DashBoardHome() {
  // state definitions
  const [order, setOrder] = useState(0);
  const [loader, setLoader] = useState(true);
  const [pieLoader, setPieLoader] = useState(true);
  const [user, setUser] = useState(0);
  const [products, setProducts] = useState(0);
  const [stores, setStores] = useState(0);
  const [category, setCategory] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const { t } = useTranslation();

  const [filter, setFilter] = useState("lastWeek");

  const filteredData = useMemo(() => {
    const filterIndexMap = {
      lastweek: 0,
      last30days: 1,
      last90days: 2,
    };
    const index = filterIndexMap[filter];
    const data = orderData[index]?.orders ?? 0;

    return [
      {
        label: filter,
        data: [[0, data]],
      },
    ];
  }, [orderData, filter]);
  // hooks
  const admin = useAdminOrRetailer(process.env.REACT_APP_ADMIN_TOKEN_KEY);

  useEffect(() => {
    if (!admin) return;

    setLoader(true);
    getAdminAllOrder().then((res) => {
      setOrder(res?.total_orders);
      setLoader(false);
    });
  }, []);

  useEffect(() => {
    if (!admin) return;

    setLoader(true);
    allUsers()
      .then((res) => {
        if (res) {
          setUser(res.total_users);
        }
      })
      .finally(() => setLoader(false));
  }, []);

  useEffect(() => {
    if (!admin) return;

    setLoader(true);
    getAllProducts().then((res) => {
      setProducts(res.total_products);
      setLoader(false);
    });
  }, []);

  useEffect(() => {
    if (!admin) return;

    setLoader(true);
    getAllStore()
      .then((res) => {
        if (res) {
          setStores(res.total_stores);
        }
      })
      .finally(() => setLoader(false));
  }, []);

  useEffect(() => {
    setPieLoader(true);
    getAdminDashBoardData()
      .then((res) => {
        const totalProducts = res.no_of_products;

        const categoryPercentages = categoriesList2.map((category) => {
          const categoryName = category[0];
          const filteredProducts = res.products.filter((product) => product.hierarchyL2 === categoryName);
          const percentage = ((filteredProducts.length / totalProducts) * 100).toFixed(2);
          return [categoryName, parseFloat(percentage)];
        });
        const data = [["Category", "Products"], ...categoryPercentages];
        setCategory(data);
        setPieLoader(false);
      })
      .catch((err) => console.log("err ", err));
  }, []);

  const options = {
    is3D: true,
  };

  const data = useMemo(
    () => [
      {
        label: "Series 2",
        data: [
          [0, 3],
          [1, 5],
          [2, 5],
          [3, 6],
          [4, 4],
        ],
      },
    ],
    []
  );

  useEffect(() => {
    getOrderCountData();
  }, []);
  const getOrderCountData = async () => {
    try {
      const response = await getAllOrderCounts();
      if (response) {
        const result = [
          { name: "Last Week", orders: response.lastWeek },
          { name: "Last 30 Days", orders: response.last30Days },
          { name: "Last 90 Days", orders: response.last90Days },
        ];
        setOrderData(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const axes = useMemo(
    () => [
      { primary: true, type: "linear", position: "bottom" },
      { type: "linear", position: "left" },
    ],
    []
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <div className="card-counter primary">
            <i className="fa fa-code-fork"></i>
            <span className="count-numbers">{loader ? <DashboardLoader /> : order}</span>
            <span className="count-name">Total Orders</span>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card-counter danger">
            <i className="fa fa-ticket"></i>
            <span className="count-numbers">{loader ? <DashboardLoader /> : products}</span>
            <span className="count-name">Products</span>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card-counter success">
            <i className="fa fa-database"></i>
            <span className="count-numbers">{loader ? <DashboardLoader /> : stores}</span>
            <span className="count-name">Stores</span>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card-counter info">
            <i className="fa fa-users"></i>
            <span className="count-numbers">{loader ? <DashboardLoader /> : user}</span>
            <span className="count-name">Users</span>
          </div>
        </div>
      </div>
      <div className="mt-3 bg-white">
        <span className="w-100  mt-2 d-flex justify-content-center text-uppercase thicker">Product Categories</span>
        {pieLoader ? (
          <div className="d-flex justify-content-center">
            <Skeleton variant="circular" animation="wave" width={"250px"} height={"250px"} />
          </div>
        ) : (
          <Chart chartType="PieChart" data={category} options={options} width={"100%"} height={"400px"} />
        )}
      </div>
      {/* <div className="flex"> */}
      {/* <div
          style={{
            width: "400px",
            height: "300px",
          }}
        >
          <Charts data={data} axes={axes} />
        </div>
        <div>
          <MyBarChart />
        </div> */}
      {/* <div>
          <div>
            <button onClick={() => setFilter("lastWeek")}>Last Week</button>
            <button onClick={() => setFilter("last30days")}>Last 30 Days</button>
            <button onClick={() => setFilter("last90days")}>Last 90 Days</button>
          </div>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={filteredData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="orders" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div
          style={{
            width: "400px",
            height: "300px",
          }}
        >
          <Charts data={filteredData} axes={axes} />
        </div>
      </div> */}
    </div>
  );
}

export default DashBoardHome;
