import React from "react";
import { Link } from "react-router-dom";
import phoneIcon from "../../assets/vector/phoneIcon.svg";
import { FaRegHandPointRight } from "react-icons/fa";
import ShareButton from "./ShareButton";
import { FaMapLocationDot } from "react-icons/fa6";

// Utility function to detect mobile devices
const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export default function FindStoreContactPerson({ store, reference }) {
  const siteUrl = "https://olineoindia.com/";
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${store.latitude},${store.longitude}`;
  const mapsUrl = isMobile() ? `geo:${store.latitude},${store.longitude}` : googleMapsUrl;

  return (
    <div ref={reference}>
      <div className="text-decoration-none">
        <span className="d-flex align-items-center">
          <FaRegHandPointRight style={{ color: "black" }} />
          &nbsp;&nbsp;&nbsp;
          <span className="findStoreContactPersonName ">{store.display_name}</span>
        </span>
        <p className="findStoreContactPersonAddress">{store.street_name}</p>
      </div>
      <div className="d-flex align-items-center " style={{ gap: "10px" }}>
        <Link to={`tel:${store.contact_no}`} className="findStoreContactPersonPhone">
          <img alt="phoneIcon" src={phoneIcon} className="findStoreContactPersonIcon" />
          <p className="findStoreContactPersonNumber">{store.contact_no}</p>
        </Link>
        <div>
          <ShareButton store={store} siteUrl={siteUrl} />
        </div>
        <div alt="Open In Map">
          <a href={mapsUrl} target="_blank" rel="noopener noreferrer" className="cursor-pointer text-dark">
            <FaMapLocationDot size={25} />
          </a>
        </div>
      </div>
    </div>
  );
}
