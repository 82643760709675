export const PRODUCT_CLASSIFICATION = {
  COMING_SOON: "Coming Soon",
  PRE_BOOKED: "Pre Booking",
  NORMAL: "Normal",
  JUST_ARRIVED: "Just Arrived",
  TEMP_HIDDEN: "Temporarily Hidden",
  OUT_OF_STOCK: "Out Of Stock",
};

export const API_RESPONSE_STATUS = {
  SUCCESS: "success",
  ERROR: "error",
};

export const OFFER_TYPES = {
  DISCOUNT: { key: "flatDiscount", label: "Discount" },
  COMBO: { key: "combo", label: "Combo" },
  CONTAINER: { key: "container", label: "Container" },
  COUPON: { key: "coupon", label: "Coupon" },
  STORE: { key: "store", label: "Store" },
};

export const SOCKET = {
  NEW_ORDER_PLACED: "new_order_placed",
  ORDER_ACCEPTED: "order_accepted",
  ORDER_REJECTED: "order_rejected",
  ORDER_ACCEPT_ID: "order_accept_id",
  NEW_CNMT_PLACED: "new_consignment_placed",
  CNMT_REJECTED: "consignment_rejected",
  CNMT_ACCEPTED: "consignment_accepted",
  CNMT_MISSED: "consignment_missed",
  CNMT_ACCEPT_ERR: "consignment_accept_error",
  STORE_MANUAL_CNMT: "manual_consignment",
};

export const PAYMENT_STATUS = {
  SUCCESS: "success",
  FAILURE: "failure",
};

export const APP_DATE_FORMAT = "MMM-dd-yyyy";
export const APP_DATE_TIME_FORMAT = "DD-MMM-yyyy hh:mm a";
export const DEFAULT_GST_PERCENTAGE = 18;
export const CANCELLED_STATUSES = [
  "CANCELLED",
  "REFUND_INITIATED",
  "REFUND_IN_PROCESS",
  "REFUND_SUCCESS",
  "CANDIDATE_FOR_RTO",
  "RTO_CONFIRMED",
  "RTO_INTRANSIT",
  "RTO_REATTEMPT",
  "RTO_DELIVERED",
  "RTO_COMPLETED",
  "RTO_RECEIVED",
  "RTO_COMPLETE_INTACT",
  "RTO_COMPLETE_DAMAGED",
  "RETURNED",
];

export const SPECIAL_CHARS = "~`\\!@#$%^&*()_-+=";

export const OFFERS_CONSTANT = {
  OfferCategories: ["SmartPhones", "Accessories", "Consumer Durables"],
  SmartPhones: ["Samsung", "Apple", "Vivo", "Oppo", "Realme", "Xiaomi", "Other"],
};

export const categoriesList2 = [["Smartphone"], ["Laptop"], ["Tablet"], ["Smart TV"], ["True Wireless Earbuds"], ["Powerbank"], ["Bluetooth Speaker"], ["Bluetooth Headphones"], ["Smartwatch"]];
