import React from "react";
import Shimmer from "./Shimmer";
import "./SkeletonStyles.css";

const SkeletonElement = ({ type, height }) => {
  return (
    <div className="skeleton_Wrapper" style={{ height: height || "300px" }}>
      <div className={`skeleton ${type}`}></div>
      <Shimmer />
    </div>
  );
};

export default SkeletonElement;
