import { useEffect, useRef, useState } from "react";
import "./step1.css";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";
// import confetti from "canvas-confetti";

function Page5() {
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    // setTimeout(() => {
    //   setShowConfetti(false);
    // }, 5000); // 5 seconds
  }, []);

  // const containerRef = useRef(null);

  // useEffect(() => {
  //   const canvas = document.createElement("canvas");
  //   canvas.style.width = "90%";
  //   canvas.style.height = "50vh";
  //   canvas.style.position = "absolute";
  //   canvas.style.top = "0";
  //   canvas.style.left = "0";
  //   containerRef.current.appendChild(canvas);

  //   const confettiInstance = confetti.create(canvas);
  //   confettiInstance();

  //   setTimeout(() => {
  //     containerRef.current.removeChild(canvas);
  //   }, 5000); // 5 seconds
  // }, []);

  const navigate = useNavigate();
  return (
    <div>
      <p style={{ textAlign: "center", fontSize: "30px", color: "#273e74", fontWeight: "bolder", marginTop: "35px" }}>
        Congratulations! <br /> Your entry is confirmed. <br />
        Stay connected with us on social media for the winner announcement!
      </p>
      <div>{showConfetti && <Confetti wind={0} friction={0.99} width={window.innerWidth || 300} height={window.innerHeight || 200} />}</div>
      {/* <div ref={containerRef} className="button-wrapper " style={{ position: "absolute", height: "100vh", width: "100%", left: 0, top: 0 }} /> */}
      <button className="btn btn-warning mt-3 w-100 fw-bolder page_1_submit_btn" onClick={() => navigate("/")}>
        Start Shopping
      </button>
    </div>
  );
}

export default Page5;
