import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTrashAlt, faEdit, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";

function HomePageAdminPannel({ allBottomSections, handleDelete }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div>
      <div
        className="top-scroll-banners-section mt-2 cursor-pointer"
        onClick={() =>
          navigate("/admin/admin-home-page/top-posters", {
            state: {
              for: `homepageposter`,
              from: location.pathname,
              type: "static_poster",
            },
          })
        }
      >
        <span> Home Page static Posters</span>
        <FontAwesomeIcon cursor={"pointer"} icon={faArrowRight} />
      </div>

      {allBottomSections.length > 0 ? (
        <>
          {allBottomSections.map((section, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                gap: "5px",
                margin: "20px 0",
              }}
            >
              {section.sectionType === "scroll_card" && (
                <section className="home-page-section-body p-2 rounded">
                  <h6>{section.sectionHeading && `${section.sectionHeading} `} (Scroll Card Section)</h6>
                  <div>
                    {section.products.map((poster, index) => (
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          marginTop: "10px",
                        }}
                        key={index}
                      >
                        <img width={100} height={150} style={{ objectFit: "contain" }} src={poster.imgUrl} alt={poster.title} />
                        <div>
                          <p>Title - {poster.title}</p>
                          <p>Target URL - {poster.targetUrl}</p>
                          <p>Size - 440px X 1060px</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>Order: {section.priority}</div>
                </section>
              )}
              {section.sectionType === "static_deals" && (
                <section className="home-page-section-body p-2 rounded">
                  <h6>{section.sectionHeading && `${section.sectionHeading} `} (Static Deals Section)</h6>
                  {section.products.map((banner, index) => (
                    <div
                      style={{
                        display: "flex",
                        gap: "15px",
                        marginTop: "20px",
                      }}
                      key={index}
                    >
                      <img width={200} height={100} style={{ objectFit: "contain" }} src={banner.image} alt={banner.title} />
                      <div>
                        <p>Title - {banner.title}</p>
                        <p>Target URL - {banner.targetUrl}</p>
                        <p>Size : {index - 1 === 0 ? "1000px X 330px" : "500px X 330px"} </p>
                      </div>
                    </div>
                  ))}
                  <div>Order: {section.priority}</div>
                </section>
              )}
              {section.sectionType === "static_deals_mobile" && (
                <section className="home-page-section-body p-2 rounded">
                  <h6>{section.sectionHeading && `${section.sectionHeading} `} (Static Deals Mobile Section)</h6>
                  {section.products.map((banner, index) => (
                    <div
                      style={{
                        display: "flex",
                        gap: "15px",
                        marginTop: "20px",
                      }}
                      key={index}
                    >
                      <img width={200} height={100} style={{ objectFit: "contain" }} src={banner.image} alt={banner.title} />
                      <div>
                        <p>Title - {banner.title}</p>
                        <p>Target URL - {banner.targetUrl}</p>
                        <p>Size - 250px X 150px</p>
                      </div>
                    </div>
                  ))}
                  <div>Order: {section.priority}</div>
                </section>
              )}
              {section.sectionType === "scroll_card_mobile" && (
                <section className="home-page-section-body p-2 rounded">
                  <h6>{section.sectionHeading && `${section.sectionHeading} `} (Scroll Card Mobile Section)</h6>
                  <div>
                    {section.products.map((poster, index) => (
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          marginTop: "10px",
                        }}
                        key={index}
                      >
                        <img width={100} height={150} style={{ objectFit: "contain" }} src={poster.imgUrl} alt={poster.title} />
                        <div>
                          <p>Title - {poster.title}</p>
                          <p>Target URL - {poster.targetUrl}</p>
                          <p>Size - 500px X 75px</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>Order: {section.priority}</div>
                </section>
              )}
              <aside className="home-page-section-aside">
                <FontAwesomeIcon
                  size="lg"
                  onClick={() =>
                    navigate("../admin-home-add-section", {
                      state: {
                        type: section.sectionType,
                        isMobileViewPoster: section.sectionType,
                        updateProduct: true,
                        data: section,
                        from: location.pathname,
                      },
                    })
                  }
                  cursor="pointer"
                  icon={faEdit}
                />
                <FontAwesomeIcon size="lg" className="text-danger" cursor="pointer" onClick={() => handleDelete(section._id)} icon={faTrashAlt} />
              </aside>
            </div>
          ))}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center p-3 mt-5">No Other Sections</div>
      )}
    </div>
  );
}

export default HomePageAdminPannel;
