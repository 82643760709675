import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
// import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// Custom Components
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";

// Utils
import { debounce } from "../../utils";
// - API
import { changeProductCommentStatus, getAdminProductComments } from "../../api/AdminApis/AdminReviews";
import { FormControlLabel, Switch, TextField } from "@mui/material";

// Component definitions
const AdminProductComments = () => {
  // state definitions
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [filterObj, setFilterObj] = useState({
    status: "All",
    category: "all",
    searchText: "",
    startDate: "",
    endDate: "",
  });
  const [totalComments, setTotalComments] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const statusArr = [
    { name: "All", value: null },
    { name: "Approved", value: true },
    { name: "Rejected", value: false },
  ];
  const [statusName, setStatusName] = useState("");
  const [statusValue, setStatusValue] = useState();

  const nav = useNavigate();
  const commentsPerPage = 10;
  // const { isOpen: isSidebarOpen = false } = useOutletContext();

  useEffect(() => {
    setLoading(true);
    (async () => {
      let url = `?limit=${commentsPerPage}&page=${currentPage}`;
      // add filter params
      if (filterObj["status"] !== "All") {
        url += `&status=${filterObj["status"]}`;
      }

      if (filterObj["category"].toLocaleLowerCase() !== "all") {
        url += `&category=${filterObj["category"]}`;
      }

      if (filterObj["searchText"]) {
        url += `&searchText=${filterObj["searchText"]}`;
      }

      if (filterObj["startDate"]) {
        url += `&startDate=${filterObj["startDate"]}`;
      }

      if (filterObj["endDate"]) {
        url += `&endDate=${filterObj["endDate"]}`;
      }
      fetchData(url);
    })();
  }, [currentPage, filterObj]);

  /** Handler funcions - starts */

  const fetchData = (url = "") => {
    setLoading(true);
    getAdminProductComments(url)
      .then((res) => {
        if (res?.status === "success") {
          setTotalComments(res.data.total_comments);
          setComments(res.data.comments);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong! Internal Server Error");
      })
      .finally(() => setLoading(false));
  };

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const handleStatusChange = (commentId, e, value) => {
    if (window.confirm("Sure?")) {
      changeProductCommentStatus(commentId).then((res) => {
        if (res?.status === "success") {
          toast.success(res.status?.message);
          fetchData();
        } else {
          toast.error("Oops! Some Error occured.");
        }
      });
    }
  };

  // handles filter change and updates filterObj
  const handleFilterChange = (key, value) => {
    if (!Object.keys(filterObj).includes(key)) return;
    setFilterObj((prev) => ({ ...prev, [key]: value }));
  };

  // handles date change filter
  const handleDateChange = (dateKey, dateVal) => {
    if (filterObj[dateKey] === dateVal) return;
    setFilterObj((prev) => ({ ...prev, [dateKey]: dateVal ? dateVal : "" }));
    setCurrentPage(1);
  };

  const handleFreeTextChange = debounce((event) => {
    handleFilterChange("searchText", event.target.value);
    setCurrentPage(1);
  });

  /** Handler funcions - ends */

  const handleSelectedStatusChange = (e, value) => {
    if (value) {
      setCurrentPage(1);
      setStatusName(value.name);
      console.log(value.value);
      setStatusValue(value.value);
      setFilterObj((prev) => ({ ...prev, status: value.value }));
    }
  };

  return (
    <div className="container">
      {/* style={{ maxWidth: isSidebarOpen ? "1140px" : "100%" }} */}
      <div className="d-flex justify-content-between mb-2">
        <h3>Product Comments And Reviews</h3>
        <button className="btn btn-primary btn-sm" type="button" onClick={() => nav("../admin-store-comments")}>
          Go To Store Comments{" "}
        </button>
      </div>

      {/** Filter Sections */}
      <div className="row mb-2">
        {/** Free text filter */}
        <div className="col-lg-3">
          <div className="input-group ">
            <label className="mb-2 ">Enter Product ID</label>
            <input id="standard-basic" className="w-100 form-control p-1" onChange={handleFreeTextChange} placeholder="Enter Product Id" label="Enter Product ID" />
          </div>
        </div>

        {/** Date filters */}
        <div className="col-lg-3">
          <label className="mb-2">Start Date: </label>
          <DatePicker
            name="startDate"
            dateFormat="dd/MM/yyyy"
            selected={filterObj["startDate"]}
            className=" input-field form-control custom-date-picker"
            placeholderText="Select Date"
            onChange={(dateVal) => handleDateChange("startDate", dateVal)}
          />
        </div>
        <div className="col-lg-3">
          <label className=" mb-2">End Date: </label>
          <DatePicker
            name="endDate"
            dateFormat="dd/MM/yyyy"
            selected={filterObj["endDate"]}
            className="input-field custom-date-picker   form-control"
            maxDate={new Date()}
            onChange={(dateVal) => handleDateChange("endDate", dateVal)}
            placeholderText="Select Date"
          />
        </div>
        {/** Status Filter */}
        <div className="col-lg-3">
          <label className=" mb-2">Status</label>
          <Autocomplete
            id="statusSelected"
            className="form-control p-0"
            size="medium"
            options={statusArr}
            onChange={(e, value) => handleSelectedStatusChange(e, value)}
            getOptionLabel={(option) => option.name || ""}
            value={{
              name: statusName,
              value: statusValue,
            }}
            clearOnEscape={false}
            renderInput={(params) => <TextField aria-expanded="false" {...params} variant="standard" value={statusName} placeholder="Select Status" />}
          />
        </div>
      </div>

      {!loading ? (
        <>
          <div className="table-responsive mb-2" style={{ fontSize: "small" }}>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Product ID</th>
                  <th scope="col">User ID</th>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Product Rating</th>
                  <th scope="col">Product Review</th>
                  <th scope="col">Product Delivery rating</th>
                  <th scope="col">Product Delivery review</th>
                  <th scope="col">Approved Status</th>
                  <th scope="col">Likes</th>
                </tr>
              </thead>
              <tbody>
                {comments?.length > 0 &&
                  comments?.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * commentsPerPage + index + 1}.</td>
                      <td>{item?.productId ?? "-"}</td>
                      <td>{item?.user?._id ?? "-"}</td>
                      <td>
                        {new Intl.DateTimeFormat("en-IN", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: true,
                          timeZone: "Asia/Calcutta",
                        }).format(new Date(item?.createdAt))}
                      </td>
                      <td>{item?.productRating ?? "-"}</td>
                      <td>{item?.productReview ?? "-"}</td>
                      <td>{item?.storeRating ?? "-"}</td>
                      <td>{item?.storeReview ?? "-"}</td>
                      <td>
                        <FormControlLabel control={<Switch checked={item?.visible} />} onChange={(e) => handleStatusChange(item?._id, e, item?.visible)} />
                      </td>
                      <td>{item?.likes?.length ?? "0"}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination_Container">
            <Pagination productsPerPage={commentsPerPage} totalProducts={totalComments} pageChange={handlePageChange} currentPage={currentPage} />
          </div>
        </>
      ) : (
        <DashboardLoader />
      )}
    </div>
  );
};

export default AdminProductComments;
