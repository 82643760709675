// import React, { useState, useEffect } from "react";
import { saveAddress } from "../api/Address";
import useUserCtx from "./useUserCtx";

// const useGeolocation = () => {
//   const [location, setLocation] = useState({
//     loaded: false,
//     coordinates: { lat: "", lng: "" },
//     address: { city: "", state: "", zip: "" },
//   });
//   // useEffect(() => {
//   //   const address = localStorage.getItem("setLocation");
//   //   if (address) {
//   //     setLocation((prev) => ({
//   //       ...prev,
//   //       address,
//   //     }));
//   //   }
//   //   console.log("fatch");
//   // }, []);
//   // const { userContext } = useUserCtx();

//   const locationFetch = () => {
//     const onSuccess = (location) => {
//       setLocation((prev) => ({
//         ...prev,
//         loaded: true,
//         coordinates: {
//           lat: location.coords.latitude,
//           lng: location.coords.longitude,
//         },
//       }));
//       getAddress(location.coords.latitude, location.coords.longitude, process.env.REACT_APP_GEOAPI_KEY);
//     };

//     const onError = (error) => {
//       setLocation({
//         loaded: true,
//         error,
//       });
//     };

//     const getAddress = (lat, long, geolocKey) => {
//       // fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${googleKey}`)
//       fetch(`https://api.geoapify.com/v1/geocode/reverse?lat=${lat}&lon=${long}&apiKey=${geolocKey}`)
//         .then((res) => res.json())
//         .then((data) => {
//           var addrss = data.features[0];
//           processUserAddress(addrss);
//         });
//     };
//     function reformat(result) {
//       var me = {};
//       var components = result.address_components;
//       var len = components.length;
//       for (var i = 0; i < len; i++) {
//         for (var ii = 0; ii < components[i].types.length; ii++) {
//           me[components[i].types[ii]] = components[i].short_name;
//           me[components[i].types[ii] + "_long"] = components[i].long_name;
//         }
//       }
//       for (var prop in result) {
//         if (prop != "address_components") me[prop] = result[prop];
//       }
//       return me;
//     }

//     const processUserAddress = (addrss) => {
//       const address = addrss.properties;
//       const city = address?.city;
//       const state = address?.state;
//       const postal = address?.postcode;
//       // console.log(postal);
//       // let userToken = userContext?.JWT;
//       // if (userToken) {
//       //   const defaultaddress = {
//       //     customerName: userContext?.fullName,
//       //     phone: userContext?.mobileNumber,
//       //     zip: address.postcode,
//       //     state: address.state,
//       //     city: address.city,
//       //     address_line1: address.address_line1,
//       //     landmark: address.neighbourhood,
//       //   };

//       //   saveAddress(defaultaddress, true).then((res) => {
//       //     console.log(res);
//       //   });
//       // } else {
//       const data = {
//         city: city,
//         state: state,
//         zip: postal,
//       };
//       //   localStorage.setItem("setLocation", JSON.stringify(data));

//       setLocation((prev) => ({
//         ...prev,
//         address: data,
//       }));
//       // }
//       if (!("geolocation" in navigator)) {
//         onError({
//           code: 0,
//           message: "Geolocation not supported",
//         });
//       }
//       navigator.geolocation.getCurrentPosition(onSuccess, onError);
//     };
//   };

//   return { location, locationFetch };
// };

// export default useGeolocation;

import React, { useState, useEffect } from "react";
import { getAddress as getAddressforUser } from "../api/Address";

const useGeolocation = () => {
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: "", lng: "" },
    address: { city: "", state: "", zip: "" },
  });
  const { userContext, setUserDefaultAddress, setUserAddress } = useUserCtx();

  const locationFetch = () => {
    const onSuccess = (location) => {
      setLocation((prev) => ({
        ...prev,
        loaded: true,
        coordinates: {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        },
      }));
      getAddress(location.coords.latitude, location.coords.longitude, process.env.REACT_APP_GEOAPI_KEY);
    };

    const onError = (error) => {
      setLocation({
        loaded: true,
        error,
      });
    };

    const getAddress = (lat, long, geolocKey) => {
      // fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${googleKey}`)
      fetch(`https://api.geoapify.com/v1/geocode/reverse?lat=${lat}&lon=${long}&apiKey=${geolocKey}`)
        .then((res) => res.json())
        .then((data) => {
          var addrss = data.features[0];
          processUserAddress(addrss);
        });
    };
    function reformat(result) {
      var me = {};
      var components = result.address_components;
      var len = components.length;
      for (var i = 0; i < len; i++) {
        for (var ii = 0; ii < components[i].types.length; ii++) {
          me[components[i].types[ii]] = components[i].short_name;
          me[components[i].types[ii] + "_long"] = components[i].long_name;
        }
      }
      for (var prop in result) {
        if (prop != "address_components") me[prop] = result[prop];
      }
      return me;
    }

    const processUserAddress = (addrss) => {
      const address = addrss.properties;
      const city = address?.city;
      const state = address?.state;
      const postal = address?.postcode;

      let userToken = userContext?.JWT;
      if (userToken) {
        const defaultaddress = {
          customerName: userContext?.fullName,
          phone: userContext?.mobileNumber,
          zip: address.postcode,
          state: address.state,
          city: address.city,
          address_line1: address.address_line1,
          landmark: address.neighbourhood,
        };

        saveAddress(defaultaddress, true).then((res) => {
          setUserDefaultAddress((prev) => ({
            ...prev,
            loaded: true,
            useThis: true,
            address: defaultaddress,
            no_of_address: 1,
          }));
          getAddressforUser().then((res) => {
            // console.log(res);
            if (res) {
              setUserAddress({
                loaded: true,
                no_of_address: res.no_of_address,
                address: res.address,
              });
            }
          });
          localStorage.setItem("setLocation", JSON.stringify(defaultaddress));
        });
      } else {
        const data = {
          city: city,
          state: state,
          zip: postal,
        };
        localStorage.setItem("setLocation", JSON.stringify(data));

        setLocation((prev) => ({
          ...prev,
          address: data,
        }));
      }
    };

    if (!("geolocation" in navigator)) {
      onError({
        code: 0,
        message: "Geolocation not supported",
      });
    }
    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  };

  return { location, locationFetch };
};

export default useGeolocation;
