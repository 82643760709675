import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteTopBannerProduct, setUpdatedBanners } from "../../api/AdminApis/Banner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import useAdminCtx from "../../hooks/useAdminCtx";
import { getHomePageSections } from "../../api/AdminApis/HomePageSections";
import { categoriesList2 } from "../../utils/Constants";
import { Autocomplete, TextField } from "@mui/material";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// Component definition
const DashboardHomeTopBanners = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { allHomePageSections, setAllHomePageSections } = useAdminCtx();
  // state definitions
  const [topBannerSection, setTopBannerSection] = useState([]);
  const [showBannersArray, setShowBannersArray] = useState([]);
  const [categoryName, setCategoryName] = useState("");

  const handleDelete = ({ key, sectionType }) => {
    const cnf = window.confirm("Are you sure?");
    if (!cnf) return;
    deleteTopBannerProduct(key, sectionType, (err, res) => {
      if (err) return toast.error("Something went wrong!");
      if (res.status === 200) {
        getHomePageSections((err, res) => {
          if (err) return console.log(err);
          setAllHomePageSections(res.sections);
        });
      }
    });
  };

  useEffect(() => {
    // &&  location.state.type === "hompage"? :
    var arr1 = [];
    const topSection = allHomePageSections.find((section) => section.sectionType === "scroll_banner");
    if (topSection) {
      const updatedProducts = topSection.products.map((product) => ({
        ...product,
        sectionType: topSection.sectionType,
      }));

      // Push the updated products into arr1
      arr1.push(...updatedProducts);
      setTopBannerSection(updatedProducts);
      const newarr = arr1.filter((item) => (location?.state?.type === "homepage" ? item.title.split("_")[1] === "HOMEPAGE" : item.title.split("_")[1] !== "HOMEPAGE"));
      setShowBannersArray(newarr);
    }
  }, [allHomePageSections]);

  useEffect(() => {
    getHomePageSections((err, res) => {
      if (err) return console.log(err);
      setAllHomePageSections(res.sections);
    });
  }, []);

  const handleSelectedCategory = (e, value) => {
    if (value) {
      setCategoryName(value[0]);
      const topBanners = [...topBannerSection];

      let filtresBanners = topBanners.filter((prod) => prod.title.split("_")[1] === value[0]);
      setShowBannersArray(filtresBanners);
    } else {
      setShowBannersArray([...topBannerSection]);
      setCategoryName("");
    }
  };

  return (
    <div className="container">
      <button
        onClick={() => navigate("../admin-home-page")}
        style={{
          all: "unset",
          marginBottom: "25px",
          cursor: "pointer",
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} /> Page Sections
      </button>
      <div className="d-flex flex-row align-items-center justify-content-between" style={{ gap: "10px" }}>
        <h3>Top Scrolling Banners</h3>
        {location?.state?.type !== "homepage" && (
          <div className="col-lg-4 ">
            <label className=" mb-2">Filter Banners </label>
            <Autocomplete
              id="statusSelected"
              className="form-control p-0"
              size="medium"
              options={categoriesList2}
              onChange={(e, value) => handleSelectedCategory(e, value)}
              getOptionLabel={(option) => option[0] || ""}
              value={categoryName !== "" ? [categoryName] : null}
              clearOnEscape={false}
              renderInput={(params) => <TextField aria-expanded="false" {...params} variant="standard" placeholder="Select Category" />}
            />
          </div>
        )}
        <button
          className="btn btn-primary mt-4"
          onClick={() =>
            navigate("../admin-home-add-section", {
              state: {
                type: "scroll_banner",
                updateProduct: false,
                from: location.pathname,
              },
            })
          }
        >
          Add Banner
        </button>
      </div>
      <div className=" hidden-md-up">
        {showBannersArray?.length > 0 ? (
          <>
            {showBannersArray?.map((banner, index) => (
              <>
                <div className="banner-product-card">
                  {banner.imgUrl.split(".").pop() === "mp4" ? (
                    <video controls autoPlay loop muted style={{ height: "250px" }}>
                      <source src={banner.imgUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <span className="d-flex flex-column">
                      <img src={banner.imgUrl} alt="" width={500} />
                      Size: 1500px X 300px
                    </span>
                  )}
                  <div className="banner-product-card-body" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
                    <p>
                      <u>Title</u> - {banner.title}{" "}
                    </p>
                    <p>
                      <u> Image URL</u> - <a href={banner.imgUrl}>{banner.imgUrl}</a>{" "}
                    </p>
                    <p>
                      <u> Target URL</u> - <a href={banner.targetUrl}>{banner.targetUrl}</a>{" "}
                    </p>
                    <p>
                      <u> Index</u> - {index + 1}
                    </p>
                  </div>
                  <div className="icons">
                    <FontAwesomeIcon
                      onClick={() =>
                        navigate("../admin-home-add-section", {
                          state: {
                            type: "scroll_banner",
                            updateProduct: true,
                            data: banner,
                            from: location.pathname,
                          },
                        })
                      }
                      cursor={"pointer"}
                      icon={faEdit}
                    />
                    <FontAwesomeIcon cursor={"pointer"} onClick={() => handleDelete({ key: banner.imgKey, sectionType: banner?.sectionType })} icon={faTrashAlt} />
                  </div>
                </div>
              </>
            ))}
          </>
        ) : (
          <>
            <div className="d-flex mt-5">No Banner Images!</div>
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardHomeTopBanners;
