import axios from "../axiosInstance";

//Get All Banners in admin
export const getBannerProducts = async (cb) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };
  await axios
    .get(`${process.env.REACT_APP_BASE_URL}/product/get-banner-products`, { headers })
    .then((res) => {
      cb(null, res.data.data);
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const updateTopBannerProduct = async (data, isNewProduct, cb) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
    "new-product": isNewProduct,
  };

  axios
    .post(`${process.env.REACT_APP_BASE_URL}/product/admin/update-top-banner-product`, data, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};
export const deleteTopBannerProduct = async (oldImgKey, sectionType, cb) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  axios
    .put(`${process.env.REACT_APP_BASE_URL}/product/admin/delete-top-banner-product`, { oldImgKey, sectionType }, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

export const setUpdatedBanners = async (banners) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  let response;
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  await axios
    .post(`${process.env.REACT_APP_BASE_URL}/product/admin/setBannerArray`, { banners }, { headers })
    .then((res) => {
      if (res) {
        response = res.data;
      }
    })
    .catch((err) => {
      response = err.response;
    });
  return response;
};
