import React, { useEffect, useState } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { setUpdatedBanners } from "../../api/AdminApis/Banner";
import useAdminCtx from "../../hooks/useAdminCtx";
import { getHomePageSections } from "../../api/AdminApis/HomePageSections";

function TopBannerSequesnce() {
  const [topBannerSection, setTopBannerSection] = useState([]);

  const { allHomePageSections, setAllHomePageSections } = useAdminCtx();

  useEffect(() => {
    // &&  location.state.type === "hompage"? :
    var arr1 = [];
    const topSection = allHomePageSections.find((section) => section.sectionType === "scroll_banner");
    if (topSection) {
      const updatedProducts = topSection.products.map((product) => ({
        ...product,
        sectionType: topSection.sectionType,
      }));

      // Push the updated products into arr1
      arr1.push(...updatedProducts);
      setTopBannerSection(updatedProducts);
    }
  }, [allHomePageSections]);

  useEffect(() => {
    getHomePageSections((err, res) => {
      if (err) return console.log(err);
      setAllHomePageSections(res.sections);
    });
  }, []);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const topBannerItems = Array.from(topBannerSection);

    // Reorder topBannerSection
    const [reorderedItemTop] = topBannerItems.splice(result.source.index, 1);
    topBannerItems.splice(result.destination.index, 0, reorderedItemTop);
    const categories = {};
    topBannerItems.forEach((item) => {
      const category = item.title.split("_")[1]?.toLowerCase();
      if (!categories[category]) {
        categories[category] = [];
      }
      categories[category].push(item);
    });
    const sortedArray = Object.values(categories).flat();
    setTopBannerSection(sortedArray);
    updateBackendBanners(sortedArray);
  };
  const updateBackendBanners = async (banners) => {
    try {
      const res = await setUpdatedBanners(banners);
      // await axios.post("/api/update-banner-order", { banners });
    } catch (error) {
      console.error("Error updating banner order:", error);
    }
  };
  return (
    <div>
      <div className=" hidden-md-up">
        {topBannerSection?.length > 0 ? (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="banners">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {topBannerSection?.map((banner, index) => (
                    <Draggable key={banner.imgKey} draggableId={banner.imgKey} index={index}>
                      {(provided) => (
                        <>
                          <div className="banner-product-card-sequence" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            {banner.imgUrl.split(".").pop() === "mp4" ? (
                              <video controls autoPlay loop muted style={{ width: "40%", objectFit: "contain" }}>
                                <source src={banner.imgUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <span className="d-flex " style={{ width: "40%", objectFit: "contain" }}>
                                <img src={banner.imgUrl} style={{ width: "100%" }} alt="" />
                                {/* Size: 1500 X 300 */}
                              </span>
                            )}
                            <div className="banner-product-card-body justify-content-between" style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
                              <p>
                                <u>Title</u> - {banner.title}{" "}
                              </p>
                              <p>
                                <u> Index</u> - {index + 1}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <>
            <div className="d-flex mt-5">No Banner Images!</div>
          </>
        )}
      </div>
    </div>
  );
}

export default TopBannerSequesnce;
