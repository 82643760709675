import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

// Custom Components
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";

// Static assets
import searchIconBlue from "../../assets/vector/search_blue.svg";

// Utils
import { debounce, formatDate } from "../../utils";
// - API
import { changeQueryStatus, getQuiry, getQueryCategories } from "../../api/AdminApis/AdminQuiry";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Component definitions
const Quirys = () => {
  // state definitions
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState([]);
  const [queryCategories, setQueryCategories] = useState([]);
  const [filterObj, setFilterObj] = useState({
    status: "All",
    category: "all",
    searchText: "",
    startDate: "",
    endDate: "",
  });
  const [totalQueries, setTotalQueries] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const queriesPerPage = 10;
  const statusArr = ["All", "Unanswered", "Delayed", "Under Review", "Closed"];
  const { isOpen: isSidebarOpen = false } = useOutletContext();
  const [categoryName, setCategoryName] = useState("");
  const [statusValue, setStatusValue] = useState();

  // componentDidMount
  useEffect(() => {
    fetchQueryCategories();
  }, []);

  useEffect(() => {
    setLoading(true);
    (async () => {
      let url = `?limit=${queriesPerPage}&page=${currentPage}`;
      // add filter params
      if (filterObj["status"] !== "All") {
        url += `&status=${filterObj["status"]}`;
      }

      if (filterObj["category"].toLocaleLowerCase() !== "all") {
        url += `&category=${filterObj["category"]}`;
      }

      if (filterObj["searchText"]) {
        url += `&searchText=${filterObj["searchText"]}`;
      }

      if (filterObj["startDate"]) {
        url += `&startDate=${filterObj["startDate"]}`;
      }

      if (filterObj["endDate"]) {
        url += `&endDate=${filterObj["endDate"]}`;
      }

      getQuiry(url)
        .then((res) => {
          if (res) {
            setTotalQueries(res.total_queries);
            setQuery(res?.querys);
          }
        })
        .finally(() => setLoading(false));
    })();
  }, [currentPage, filterObj]);

  /** Handler funcions - starts */

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const handleStatusChange = (e, id) => {
    if (window.confirm("Sure?")) {
      changeQueryStatus(e.target.value, id).then((res) => {
        if (res === 200) {
          toast.success("Status Updated!");
        } else {
          toast.error("Oops! Some Error happened.");
        }
      });
    } else {
      document.getElementById(`query-select-${id}`).reset();
    }
  };

  // fetches categories for Query entity
  const fetchQueryCategories = async () => {
    setLoading(true);
    try {
      const response = await getQueryCategories();
      if (response.status === "success") {
        setQueryCategories(response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  // handles filter change and updates filterObj
  const handleFilterChange = (key, value) => {
    if (!Object.keys(filterObj).includes(key)) return;

    setFilterObj((prev) => ({ ...prev, [key]: value }));
  };

  // handles date change filter
  const handleDateChange = (dateKey, dateVal) => {
    if (filterObj[dateKey] === dateVal) return;
    setFilterObj((prev) => ({ ...prev, [dateKey]: dateVal }));
  };

  const handleFreeTextChange = debounce((event) => {
    handleFilterChange("searchText", event.target.value);
  });

  const handleSelectedStatusChange = (name, value) => {
    if (value) {
      setCurrentPage(1);
      setFilterObj((prev) => ({ ...prev, [name]: value }));
    }
  };

  /** Handler funcions - ends */

  return (
    <div className="container" style={{ maxWidth: isSidebarOpen ? "1140px" : "" }}>
      <div className="d-flex justify-content-between mb-2">
        <div>
          <h3>Customer Queries</h3>
        </div>

        <div className="d-flex">
          <Link className="btn btn-link me-2" to="../admin-query-categories">
            View Categories
          </Link>
        </div>
      </div>

      {/** Filter Sections */}
      <div className="row ">
        {/** Free text filter */}
        <div className="position-relative me-2">
          <input autoFocus type="search" placeholder="Enter search text" className="form-control" onChange={handleFreeTextChange} />
        </div>
        {/** Date filters */}
        <div className="col-lg-3">
          <label className="mb-2">Start Date: </label>
          <DatePicker
            name="startDate"
            dateFormat="dd/MM/yyyy"
            selected={filterObj["startDate"]}
            className=" input-field form-control custom-date-picker"
            placeholderText="Select Date"
            onChange={(dateVal) => handleDateChange("startDate", dateVal)}
          />
        </div>
        <div className="col-lg-3">
          <label className=" mb-2">End Date: </label>
          <DatePicker
            name="endDate"
            dateFormat="dd/MM/yyyy"
            selected={filterObj["endDate"]}
            className="input-field custom-date-picker   form-control"
            maxDate={new Date()}
            onChange={(dateVal) => handleDateChange("endDate", dateVal)}
            placeholderText="Select Date"
          />
        </div>
        {/** Category filter */}
        <div className="col-lg-3">
          <label className=" mb-2">Category</label>
          <Autocomplete
            id="statusSelected"
            className="form-control p-0"
            size="medium"
            options={queryCategories}
            onChange={(e, value) => handleSelectedStatusChange("category", value?.category || "all")}
            getOptionLabel={(option) => option.category || ""}
            clearOnEscape={false}
            renderInput={(params) => <TextField aria-expanded="false" {...params} variant="standard" placeholder="Select Status" />}
          />
        </div>

        {/* Status Filter */}
        <div className="col-lg-2">
          <label className=" mb-2">Status</label>
          <Autocomplete
            id="statusSelected"
            className="form-control p-0"
            size="medium"
            options={statusArr}
            onChange={(e, value) => handleSelectedStatusChange("status", value)}
            getOptionLabel={(option) => option || ""}
            clearOnEscape={false}
            renderInput={(params) => <TextField aria-expanded="false" {...params} variant="standard" placeholder="Select Status" />}
          />
        </div>
      </div>

      {!loading ? (
        <>
          <div className="table-responsive mb-2">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Sr.No.</th>
                  <th scope="col">Query ID</th>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Query</th>
                  <th scope="col">Category</th>
                  <th scope="col">Status</th>
                  <th scope="col">Replied at</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {query?.length > 0 &&
                  query?.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * queriesPerPage + index + 1}.</td>
                      <td>{item.queryId?.toUpperCase() ?? "-"}</td>
                      <td>
                        {new Intl.DateTimeFormat("en-IN", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: true,
                          timeZone: "Asia/Calcutta",
                        }).format(new Date(item.createdAt))}
                      </td>
                      <td>{item.name ?? "-"}</td>
                      <td>{item.email ?? "-"}</td>
                      <td>{item.phone ?? "-"}</td>
                      <td>{item.query ?? "-"}</td>
                      <td className="text-capitalize">{item.category ?? "-"}</td>
                      <td>
                        <form id={`query-select-${item._id}`}>
                          <select onChange={(e) => handleStatusChange(e, item._id)} defaultValue={item.status}>
                            <option>{item.status ?? "-"}</option>
                            {statusArr
                              .filter((status) => status !== item.status)
                              .map((status, i) => (
                                <option value={status}>{status}</option>
                              ))}
                          </select>
                        </form>
                      </td>
                      <td>{item?.replied_at ? formatDate(item.replied_at) : "-"}</td>
                      <td>
                        {!item.replied_at ? (
                          <Link role="button" className="btn btn-sm btn-primary" to={`../admin-query-response/${item._id}`}>
                            Reply
                          </Link>
                        ) : (
                          "Replied"
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination_Container">
            <Pagination productsPerPage={queriesPerPage} totalProducts={totalQueries} pageChange={handlePageChange} currentPage={currentPage} />
          </div>
        </>
      ) : (
        <DashboardLoader />
      )}
    </div>
  );
};

export default Quirys;
