//
import * as React from "react";
import { useState, useEffect } from "react";

// import PaymentPop from "./PaymentPop";
//CSS
import "./Payment.css";

import ConfettiExplosion from "react-confetti-explosion";
//Images
import left_arrow from "../../assets/vector/left_arrow.svg";
import { useNavigate } from "react-router";
import useUserCtx from "../../hooks/useUserCtx";
import { initOrder, paymentInit } from "../../api/OrdersApi";
import { getGstDetails } from "../../api/miscellaneous";
import { Checkbox, FormControlLabel, useMediaQuery } from "@mui/material";
import NothingImage from "../../assets/png/your_order_image.png";
import { toast } from "react-toastify";
import { getSearchedProduct } from "../../api/Product";
import { currencyFormat, isOfferActive, truncatedText, isCouponActive } from "../../utils";
import { useTranslation } from "react-i18next";
import { checkCoupon } from "../../api/couponApi";
import EditDetailsModal from "./EditDetailsModal";
import { useLocation } from "react-router-dom";
import SEO from "../../components/SEO/SEO";
import useSiteCtx from "../../hooks/useSiteCtx";
import savedLogo from "../../assets/png/appliedLogo.png";

// Component definition
const Payment = () => {
  const isMobile = useMediaQuery(`(max-width: 500px)`);
  const {
    userContext,
    userAddress,
    orderInit,
    setOrderInit,
    // userDefaultAddress,

    payuOffers,
    deliveryCharges,
    setCartArray,
    appliedCoupon,
    priceBoxDetails,
    setAppliedCoupon,
    cartArray,
    userDefaultAddress,
    paymentProductArr,
    setPaymentProductArr,
    setPriceBoxDetails,
    userPreBooked,
  } = useUserCtx();

  const { seoData } = useSiteCtx();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const userData = React.useMemo(() => {
    if (userContext.JWT) {
      return {
        fullName: userContext.fullName,
        email: userContext.email,
        phone: userContext.mobileNumber,
      };
    }
  }, [userContext]);
  // state definitions
  const [disable, setDisable] = useState(true);

  const [isExploding, setIsExploding] = useState(false);
  const [initProcessing, setInitProcessing] = useState(false);
  const [comboProducts, setComboProducts] = useState([]);
  const [gst, setGst] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [claimGst, setClaimGst] = useState(false);
  const [showProd, setShowProd] = useState({});
  // const [serviceabilityCheckDone, setServiceabilityCheckDone] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [checked, setChecked] = useState(false);
  const fetchGstDetails = React.useCallback(async () => {
    try {
      const response = await getGstDetails();
      if (response.status === "success") {
        setGst(response.gst);
      }
    } catch (error) {
      toast.error("Something went wrong in Fetching GST");
    }
  }, []);

  useEffect(() => {
    fetchGstDetails();
  }, [fetchGstDetails]);

  useEffect(() => {
    setOrderInit((prev) => ({
      ...prev,
      shippingAddressId: userAddress?.address?.length > 0 ? userAddress?.address.find((e) => e.isDefault === true)?._id : null,
      claimGst,
    }));
    setDisable(false);
  }, [claimGst, setOrderInit, userAddress?.address]);

  useEffect(() => {
    const paymentProductArray = JSON.parse(localStorage.getItem("paymentProductArr"));
    setPaymentProductArr((prev) => ({
      ...prev,
      loaded: true,
      cart: paymentProductArray?.cart,
      combo: paymentProductArray?.combo,
      no_of_carts: paymentProductArray?.cart?.length,
    }));
  }, []);

  useEffect(() => {
    const paymentProductArr = JSON.parse(localStorage.getItem("paymentProductArr"));
    if (paymentProductArr.cart.length > 0) {
      let productId = [];
      let quantity = [];
      let onsiteGoPlan = [];
      let updatedCart = [];
      let giftCoupon = [];

      for (const item of paymentProductArr.cart) {
        productId.push(item._id);
        if (item?.from && item.from?.plan) {
          onsiteGoPlan.push({ ...item.from, quantity: item.quantity });
        }
        quantity.push(parseInt(item.quantity));

        if (item.coupon?.value) {
          giftCoupon.push({ ...item.coupon, productId: item._id });
        }

        // Check if userPreBooked has an item with the same product ID
        // const prebookedItem = userPreBooked.find((el) => el.productId === item._id);
        // if (prebookedItem) {
        //   // Update the item price by subtracting the prebooked amount
        //   item.price.mop -= prebookedItem.amount;
        // }
        updatedCart.push(item);
      }

      updateCartComboDetails(paymentProductArr);
      setOrderInit((prev) => ({
        ...prev,
        productId: productId,
        onsiteGoPlan: onsiteGoPlan,
        quantity: quantity,
        giftCoupon,
      }));
      setPaymentProductArr({ ...paymentProductArr, cart: updatedCart });
      setShowProd({ ...paymentProductArr, cart: updatedCart });
    }
    // cleanup on componentWillUnmount
    return () => {
      setComboProducts([]);
    };
  }, [userPreBooked]); // Dependency added here

  const fetchComboProduct = async (productEan) => {
    const response = await getSearchedProduct(`ean=${productEan}`);
    const product = response.products[0];
    setComboProducts((prev) => {
      return [{ ...product, isComboProduct: true }];
    });

    return product;
  };

  const validateUserData = () => {
    let isValid = true;
    if (!userContext.email) {
      toast.error("Please add Email Id.");
      isValid = false;
    } else if (!userAddress.address.length) {
      toast.error("Please Add Shipping Address.");
      isValid = false;
    }
    return isValid;
  };

  const handleOrderInit = async (e) => {
    if (validateUserData()) {
      e.preventDefault();
      setInitProcessing(true);

      try {
        const OrderinitRes = await initOrder(orderInit); //saves order in DB and returns the order
        if (!OrderinitRes) return setInitProcessing(false);

        let orderId = OrderinitRes._id; //returns order id

        const payInitRes = await paymentInit(orderId);
        // 1) Takes Order ID and checks its presence in Order Collection in DB.
        // 2) Creates Payment collection in DB and sends payment link.
        window.open(payInitRes, "_parent");
      } catch (err) {
        console.error({ err });
        toast.error(err?.response?.data?.status === "error" ? err.response.data.message : "Something went wrong");
      } finally {
        setInitProcessing(false);
      }
    }
  };

  useEffect(() => {
    const paymentProductArr = JSON.parse(localStorage.getItem("paymentProductArr"));
    if (paymentProductArr.cart.length > 0) {
      const productNumbers = paymentProductArr?.cart.reduce((accumulator, current) => {
        // count combo products
        if (Array.isArray(current.comboProductDetails)) {
          accumulator = accumulator + current.comboProductDetails.length;
        }
        return accumulator + current?.quantity;
      }, 0);

      const productPrice = paymentProductArr?.cart?.reduce((accumulator, current) => {
        // count combo products price
        if (Array.isArray(current.comboProductDetails)) {
          accumulator =
            accumulator +
            current.comboProductDetails?.reduce((acc, curr) => {
              return acc + curr?.price?.mrp;
            }, 0);
        }
        return accumulator + current?.price?.mrp * current?.quantity;
      }, 0);

      const totalMop = paymentProductArr.cart.reduce((accumulator, current) => {
        if (current?.hierarchyL2 === "Warranties") {
          return accumulator;
        }
        const mop = parseFloat(current?.price?.mop);
        // count combo products mop
        if (Array.isArray(current.comboProductDetails)) {
          accumulator =
            accumulator +
            current.comboProductDetails?.reduce((acc, curr) => {
              if (!curr?.price) return 0;
              const comboMop = parseFloat(curr.price?.mop);
              return acc + comboMop;
            }, 0);
        }
        return (accumulator += mop * current.quantity);
      }, 0);

      const flatDiscountSum = paymentProductArr.cart.reduce((accumulator, current) => {
        const currentMop = current?.price?.discountPrice ? current?.price?.discountPrice : current?.price?.mop;
        if (current.discount?.flatDiscount?.value > 0 && isOfferActive(current.discount?.flatDiscount)) {
          if (current.discount?.flatDiscount?.discountType === "flat") {
            accumulator += current.discount?.flatDiscount?.value * current.quantity;
          } else {
            // mop * flatDiscountInPercentage / 100
            accumulator += (currentMop * current.discount?.flatDiscount?.value) / 100;
          }
        }

        // deduct any discount applied on the combo product
        if (Array.isArray(current.comboProductDetails)) {
          const comboOffer = current.discount?.combo;
          accumulator += current.comboProductDetails.reduce((acc, curr) => {
            const currComboMop = curr?.price?.discountPrice ? curr?.price?.discountPrice : curr?.price?.mop;
            if (comboOffer?.value > 0 && isOfferActive(comboOffer)) {
              if (comboOffer?.discountType === "flat") {
                acc += parseFloat(comboOffer?.value);
              } else {
                acc += (currComboMop * comboOffer?.value) / 100;
              }
            }
            return acc;
          }, 0);
        }
        return accumulator;
      }, 0);

      const giftCouponAmount = paymentProductArr.cart.reduce((acc, current) => {
        const couponAmount = current?.coupon?.value || 0;
        return acc + couponAmount;
      }, 0);

      // apply coupon discount that was entered by user on checkout
      const couponDiscount = !isNaN(+appliedCoupon?.discount) ? +appliedCoupon?.discount : 0;

      const discountedPrice = productPrice - totalMop + flatDiscountSum + couponDiscount + giftCouponAmount;
      const totalDeliveryCharge = isNaN(+deliveryCharges) ? 0 : +deliveryCharges;

      const totalAmount = productPrice - discountedPrice + totalDeliveryCharge;
      if (giftCouponAmount > 0) {
        setIsExploding(true);
      }
      setPriceBoxDetails({
        cartItemsNumber: productNumbers,
        cartItemsPrice: productPrice,
        totalAmount: totalAmount,
        totalDiscount: discountedPrice,
        totalDeliveryCharge: totalDeliveryCharge,
        giftCouponAmount: giftCouponAmount,
      });
    }
  }, [payuOffers, paymentProductArr, deliveryCharges, appliedCoupon]);
  const completeConfit = () => {
    setIsExploding(false);
  };

  /* const checkServiceAvailability = async (item, pincode) => {
        const response = await checkProdServiceabilityStatus(item, pincode);
        const payload = response.data;
        if (payload.status === 'error' && !payload.data?.length) {
            setCartArray(prev => {
                return {
                    ...prev,
                    cart: prev.cart.filter(prod => prod.ean !== item.ean)
                };
            })
            toast.error(`Service unavailable for ${item.title}`)
        }
    }

    const addOnsitego = (event) => {
        setHasOnsitego(event.target.checked);
    }

    const handleClaimGstChange = (event) => {
        setClaimGst(event.target.checked);
    }

    const initServiceAvailabilityCheck = async () => {
        if (cartArray && cartArray.no_of_carts > 0 && userDefaultAddress.loaded) {
            let itemArray = cartArray.cart.map((item) => {
                let itemObj = {
                    ean: item.ean,
                    title: item.title,
                    quantity: item.quantity,
                };
                return itemObj;
            });

            const pincode = userDefaultAddress?.address?.zip;
            for (const item of itemArray) {
                await checkServiceAvailability(item, pincode);
            }
            setServiceabilityCheckDone(true)
        }
    } */

  const getComboProdPrice = (comboProdMop, comboOffer) => {
    let result = comboProdMop;
    const discountVal = parseFloat(comboOffer.value);

    if (comboOffer.discountType !== "flat") {
      result = result - (result * discountVal) / 100;
    } else {
      result = result - discountVal;
    }
    return result;
  };

  // product container component
  const CardDiv = ({ name, price, details = "", image, hierarchyL2 = "", mrp, isComboProduct = false, slug, couponApplied }) => {
    return (
      <>
        {hierarchyL2 !== "Warranties" ? (
          <div className="cardDiv" style={{ overflow: "unset" }} onClick={() => navigate(`/product/${slug}`)}>
            <div className="card__square">
              <img className="w-100 h-100" alt="card" src={image ?? NothingImage} />
              {isComboProduct && <div className="tag-item tag-best-seller fs-6 my-1">Combo Product</div>}
            </div>
            <div className="card__info">
              <div className="card__heading" style={{ fontSize: "18px", fontWweight: "600" }}>
                {name}
              </div>
              <span className="card__details" style={{ fontSize: "13px", color: "#b2b2b2" }}>
                {details}
              </span>
              {hierarchyL2 !== "Warranties" && (
                <div className="card__price" style={{ display: "flex", flexDirection: "column" }}>
                  <b className="me-2" style={{ color: "#241a6b" }}>
                    {currencyFormat(price)}
                  </b>
                  {mrp > price && (
                    <span
                      className="text-decoration-line-through"
                      style={{
                        fontSize: "0.7rem",
                        color: "#b2b2b2",
                        // bottom: "1.5em",
                      }}
                    >
                      {currencyFormat(mrp)}
                    </span>
                  )}
                  {couponApplied?.value && (
                    <div>
                      <span style={{ color: "green" }}>Gift coupon Applied - {currencyFormat(couponApplied.value)}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="cardDiv" style={{ overflow: "unset" }}>
            <div className="card__square">
              <img className="w-100 h-100" alt="card" src={image ?? NothingImage} />
              {isComboProduct && <div className="tag-item tag-best-seller fs-6 my-1">Combo Product</div>}
            </div>
            <div className="card__info">
              <div className="card__heading" style={{ fontSize: "18px", fontWweight: "600" }}>
                {name}
              </div>
              <span className="card__details" style={{ fontSize: "13px", color: "#b2b2b2" }}>
                {details}
              </span>
              {hierarchyL2 !== "Warranties" && (
                <div className="card__price" style={{ display: "flex", flexDirection: "column" }}>
                  <b className="me-2" style={{ color: "#241a6b" }}>
                    {currencyFormat(price)}
                  </b>
                  {mrp > price && (
                    <span
                      className="text-decoration-line-through"
                      style={{
                        fontSize: "0.7rem",
                        color: "#b2b2b2",
                        // bottom: "1.5em",
                      }}
                    >
                      {currencyFormat(mrp)}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  {
    /* {hierarchyL2 === 'Smartphone' && (
              <p className="cart_Text text-success fs-6 m-0 p-0">
                  Extended warranty upto 1 year on Smartphone Purchase
              </p>
          )} */
  }
  // checks whether entered coupon code is valid and fetches the details of that coupon
  const checkEnteredCoupon = async () => {
    const couponCode = document.getElementById("coupon_code")?.value ?? "";
    if (!couponCode) return toast.error("Enter Coupon Code...");
    try {
      const response = await checkCoupon(couponCode);
      const payload = response.data;

      if (payload.status === "success") {
        const coupon = payload.data.coupon;
        const productEan = paymentProductArr.cart.map((prod) => prod.ean);
        // check whether coupon max amount is in limits to the total purchase
        // and is applicable to any product in the purchase
        let isCouponApplicable = productEan.some((ean) => {
          return coupon.products.includes(ean) && +priceBoxDetails.totalAmount <= coupon.maxAmount;
        });

        if (+coupon.discount <= 0 || !isCouponActive(coupon)) {
          isCouponApplicable = false;
        }

        if (isCouponApplicable) {
          setAppliedCoupon(coupon);
          setOrderInit((prev) => ({ ...prev, coupon: coupon.code }));
          toast.success("Coupon applied successfully!");
        } else {
          toast.error("No applicable coupon found!");
        }
      }
    } catch (err) {
      let message = "Something went wrong";
      if (err.response?.data?.message) message = err.response?.data?.message;
      toast.error(message);
    }
  };

  useEffect(() => {
    setOrderInit((prev) => ({ ...prev, manualCnmtReceive: checked }));
  }, [checked]);

  // const getStoreName = React.useCallback(() => {
  //   let name;
  //   if (cartArray?.loaded) {
  //     cartArray.cart.forEach((cart) => {
  //       if (cart?.from?.length > 0 && cart.from[0]) name = cart.from[0];
  //       return name;
  //     });
  //   }
  //   return name ? name : false;
  // }, [cartArray?.loaded, cartArray.cart]);

  const updateCartComboDetails = async (cartArray) => {
    let newProd = [];
    for (const prod of cartArray.cart) {
      // check the product has combo offer and if yes fetch that product
      const comboOffer = prod.discount?.combo;

      if (comboOffer?.value > 0 && isOfferActive(comboOffer)) {
        const cProd = await fetchComboProduct(comboOffer.childEan);
        const cProdArr = prod["comboProductDetails"] ?? [];
        if (!prod["comboProductDetails"]?.length) {
          const comboProdSet = new Set([...cProdArr, cProd]);
          prod["comboProductDetails"] = [...comboProdSet];
        }
      }

      newProd.push(prod);
    }

    setPaymentProductArr((prev) => ({
      // Spread the previous state to retain existing properties
      ...prev,
      // Update the state with new values
      loaded: true, // Mark as loaded
      no_of_carts: newProd.length, // Set the number of carts to the length of newProd
      cart: newProd, // Update the cart with newProd
      combo: cartArray.combo, // Update the combo with cartArray.combo
    }));
  };

  const getFinalDisplayPrice = (productData) => {
    const mop = parseFloat(productData.price?.mop);
    if (!mop) return 0;
    const flatDiscount = productData.discount?.flatDiscount;

    let result = mop;

    if (flatDiscount?.value > 0 && isOfferActive(flatDiscount)) {
      if (flatDiscount?.discountType === "flat") {
        result -= flatDiscount?.value;
      } else {
        result -= (result * flatDiscount?.value) / 100;
      }
    }

    return result;
  };

  const onEditOrAddAddress = () => {
    if (userDefaultAddress?.address?.customerName) {
      navigate("/yourAddresses", {
        state: {
          from: "payment",
        },
      });
    } else {
      navigate("/profile/yourAddresses/newAddress");
    }
  };

  // main renderer
  return (
    <>
      <div className="page_Wrapper">
        <div style={{ marginTop: "1rem" }}>
          <div className="d-flex align-items-center" onClick={() => navigate(-1)}>
            <img src={left_arrow} alt="backIcon" className="backIcon mt-2 cursor-pointer" />
            <span className="backSpan">{t("back")}</span>
            <span className="checkoutSpan">{t("cart.checkout")}</span>
          </div>
        </div>
        <div className="main_wrapper">
          <div className="main_container ">
            <div className="main_heading">
              <h3 className="text-uppercase">{t("shippingInformation")}</h3>
            </div>
            <div className="shippment_details">
              <div className="shipment_div row">
                <span className="keys col-sm-3">{t("login.name")}</span> <span className="values col-sm-9">{userContext.fullName}</span>
              </div>
              <div className="shipment_div row">
                <span className="keys col-sm-3">{t("login.email")}</span>{" "}
                <span
                  className="values col-sm-7"
                  style={{
                    color: userContext.email === "" ? "red" : "initial",
                  }}
                >
                  {userContext.email !== "" ? userContext.email : "No Email Found"}
                </span>
                <span className="col-sm-2 email-edit-btn" onClick={handleOpen}>
                  Edit
                </span>
              </div>
              <div className="shipment_div row">
                <span className="keys col-sm-3">{t("shippingAddress")}</span>{" "}
                {userDefaultAddress?.address?.customerName ? (
                  <span className="values col-sm-7">
                    {`${userDefaultAddress?.address?.address_line1} ${userDefaultAddress?.address?.city} ${userDefaultAddress?.address?.country} ${userDefaultAddress?.address?.zip}`}
                  </span>
                ) : (
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                    className="col-sm-7 text-danger d-flex align-items-center"
                  >
                    No Address Found
                  </span>
                )}
                <span className="col-sm-2 email-edit-btn" onClick={onEditOrAddAddress}>
                  {userDefaultAddress?.address?.customerName ? "Edit" : "Add"}
                </span>
              </div>
              <div className="shipment_div row border-0">
                <span className="keys col-sm-3">{t("phoneNo")}</span> <span className="values col-sm-9">{userContext.mobileNumber}</span>
              </div>
            </div>

            <div className="cart-coupon">
              <div>
                <div className="coupon-head">
                  <span className="head-size text-uppercase">{t("coupon")}</span>
                  <div className="hr-coupon"></div>
                </div>

                <div className="input-coupon">
                  <div className="instruct-coupon">{t("couponEnter")}</div>
                  <input put className="search-input inpt" type="text" placeholder={t("couponCodePlaceholder")} name="coupon_code" id="coupon_code" disabled={priceBoxDetails?.giftCouponAmount > 0} />
                  <button className="coupon-btn inpt" disabled={priceBoxDetails?.giftCouponAmount > 0} onClick={checkEnteredCoupon}>
                    <span className="apply-coupon-btn">{t("apply")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="second_container">
            <div className="second_container_main_heading">
              <h3 className="secondHeading text-uppercase">{t("order-page.yourOrder")}</h3>
            </div>
            {showProd?.cart?.length > 0 &&
              showProd?.cart?.map((product, index) => {
                return (
                  <>
                    <CardDiv
                      key={index}
                      image={product?.images?.[0]}
                      name={product.title}
                      price={getFinalDisplayPrice(product) * product.quantity}
                      mrp={product.price.mrp * product.quantity}
                      details={truncatedText(product.description[0]?.split("\n")[0], 40)}
                      hierarchyL2={product.hierarchyL2}
                      slug={product?.slug}
                      couponApplied={product?.coupon}
                    />

                    {product["comboProductDetails"]?.map((cProd) => {
                      return (
                        <CardDiv
                          key={index}
                          image={cProd?.images?.[0]}
                          name={cProd.title}
                          price={getComboProdPrice(cProd.price?.mop, product.discount.combo)}
                          mrp={cProd.price.mrp}
                          details={truncatedText(cProd.description[0]?.split("\n")[0], 40)}
                          category={cProd.hierarchyL2}
                          isComboProduct={true}
                          slug={cProd?.slug}
                        />
                      );
                    })}
                  </>
                );
              })}

            {/* <CardDiv name="Headphone pink color" price="₹4369.00" />

                    <CardDiv name="Headphone pink color" price="₹4369.00" />
                    <CardDiv name="Headphone pink color" price="₹4369.00" /> */}
            {/* {gst && (
                        <p className="cart_Text section_Wrapper marginLeft">
                            <FormControlLabel
                                label="Claim GST"
                                control={
                                    <Checkbox onChange={handleClaimGstChange} />
                                }
                            />
                        </p>
                    )} */}
            {/* <p className="cart_Text section_Wrapper marginLeft">
                        <FormControlLabel
                            label={t("SecureWithOnsitego")}
                            control={
                                <Checkbox onChange={addOnsitego} />
                            }
                        />
                    </p> */}
            <hr className="order_break" />
            {priceBoxDetails?.giftCouponAmount > 0 && (
              <div className="text-center ">
                <p className="d-flex justify-content-center ">
                  <span className=" " style={{ padding: "7px 70px", border: "1.5px solid #00d700", background: "#ecfbf4", color: "#00d700", borderRadius: "15px" }}>
                    {isExploding && (
                      <div style={{ marginLeft: "200px" }}>
                        <ConfettiExplosion duration={3000} width={500} force={1} onComplete={completeConfit} />{" "}
                      </div>
                    )}
                    <img src={savedLogo} height="20px" width="20px" alt="Savedlogo" /> &nbsp; Yay! You are saving {currencyFormat(priceBoxDetails?.giftCouponAmount)} on this order
                  </span>
                </p>
              </div>
            )}
            <div className="subtotal">
              <div className="subtotall_keys">
                <p>Subtotal</p>
                {priceBoxDetails.totalPreBookAmount ? <p>Pre Booking Amount</p> : <></>}
                <p>Discount</p>
                <p>Shipping Charges</p>
                <p>Coupon Applied</p>
                {priceBoxDetails?.giftCouponAmount ? <p>Gift Coupon Applied</p> : <></>}
                <p>Combo Products</p>
                <span>TOTAL</span>
              </div>
              <div className="subtotall_value">
                <p>{currencyFormat(priceBoxDetails.cartItemsPrice)}</p>
                {priceBoxDetails.totalPreBookAmount ? <p>{currencyFormat(priceBoxDetails.totalPreBookAmount) ?? "-"}</p> : <></>}
                <p>{currencyFormat(priceBoxDetails.totalDiscount)}</p>
                <p>{currencyFormat(priceBoxDetails.totalDeliveryCharge)}</p>
                <p>{appliedCoupon?.code || "-"}</p>
                {priceBoxDetails?.giftCouponAmount ? <p>{currencyFormat(priceBoxDetails?.giftCouponAmount) || "-"}</p> : <></>}
                {comboProducts.length > 0 ? <p>{comboProducts.length}</p> : <p>{t("cart.none")}</p>}
                <span>{currencyFormat(priceBoxDetails.totalAmount)}</span>
              </div>
            </div>
            {/* {orderInit.type === "Manual" && getStoreName() && (
              <div style={{ marginBlock: "1rem", textAlign: "center" }}>
                <FormControlLabel
                  control={<Checkbox checked={checked} onChange={handleCheckBoxChange} inputProps={{ "aria-label": "controlled" }} />}
                  label={`Collect Product(s) from Store: ${getStoreName()}`}
                />
              </div>
            )} */}
            <button className="submit-button buy-now" style={{ padding: "20px" }} disabled={disable || initProcessing || !showProd.cart.length} onClick={handleOrderInit}>
              <h4 className="submit-button-textPop" style={{ fontSize: "26px" }}>
                {t("payNow")}
              </h4>
            </button>
          </div>
        </div>
      </div>
      {openModal && <EditDetailsModal open={openModal} handleClose={handleClose} setOpenModal={setOpenModal} isMobile={isMobile} data={userData} />}
    </>
  );
};

export default Payment;
