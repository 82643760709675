import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getUser, sendOtpUpdatePhone, updateUser } from "../../api/Auth";
import { saveUserPic } from "../../api/Auth";
import DatePicker from "react-date-picker";
import { toast } from "react-toastify";

//Images
import cameraIcon from "../../assets/vector/camera_icon.svg";
import locationIconBlue from "../../assets/vector/location_blue.svg";
import arrowRightBlue from "../../assets/vector/arrow_right_blue.svg";
import defaultUserImage from "../../assets/png/default_user_image.png";

//Component
import UpdateModal from "../../components/ModalComponenr/UpdateModal";
import { validatePhone } from "../../utils";
import useUserCtx from "../../hooks/useUserCtx";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTranslation } from "react-i18next";

// toast.configure();

// Component definitions
const EditDetails = ({ profileDetails = true, profilePicUpdate }) => {
  // Translations
  const { t } = useTranslation();

  // state definitions
  const [disabled, setDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDay] = useState(null);
  const [profilePic, setProfilePic] = useState({ locataion: "" });
  const [newProfilePic, setNewProfilePic] = useState(null);

  const [displayInfo, setDisplayInfo] = useState({
    user_Full_Name: "",
    user_ph_Number: "",
    user_Email: "",
    user_Birth_Date: "",
  });
  const [secondaryData, setSecondaryData] = useState({
    user_Full_Name: "",
    user_ph_Number: "",
    user_Email: "",
    user_Birth_Date: "",
  });

  const [modalData, setModalData] = useState({
    number: null,
    oldData: "",
    newData: "",
    userName: displayInfo.user_Full_Name,
    otpVerificationId: null,
  });

  const { width } = useWindowDimensions();

  const matches = width >= 768;
  const { userContext, setUserContext } = useUserCtx();

  useEffect(() => {
    if (userContext && userContext.profilePic) {
      setProfilePic(userContext.profilePic);
    } else if (newProfilePic !== null) {
      setProfilePic(newProfilePic);
    } else {
      setProfilePic(defaultUserImage);
    }
  }, [userContext, newProfilePic]);

  useEffect(() => {
    if (userContext) {
      setDisplayInfo({
        user_Full_Name: userContext.fullName,
        user_ph_Number: userContext.mobileNumber,
        user_Email: userContext.email,
        user_Birth_Date: userContext.dob ? new Date(userContext.dob) : "",
      });
      setSecondaryData({
        user_Full_Name: userContext.fullName,
        user_ph_Number: userContext.mobileNumber,
        user_Email: userContext.email,
      });

      if (useContext.dob) {
        let bday = new Date(userContext.dob);
        setDisplayInfo((prev) => ({
          ...prev,
          user_Birth_Date: bday,
        }));
        setSecondaryData((prev) => ({
          ...prev,
          user_Birth_Date: bday,
        }));
      }
    }
  }, [userContext]);

  const handleUpdate = (prop) => {
    if (prop === "number") {
      if (displayInfo.user_ph_Number === secondaryData.user_ph_Number) return;

      if (validatePhone(displayInfo.user_ph_Number) === null) return toast.error(t("phoneValidationFailed"));

      const data = {
        currentNumber: secondaryData.user_ph_Number,
        updatedNumber: displayInfo.user_ph_Number,
      };

      sendOtpUpdatePhone(data, (err, res) => {
        if (err) {
          if (err.response.data.message === "updatedNumber already exist") {
            toast.error(t("numberAlreadyExists"));
          } else {
            toast.error(t("writeToUs.error"));
          }
          return console.log(err);
        }
        if (res.status === 200) {
          setModalData((prev) => {
            return { ...prev, otpVerificationId: res.data.updateId };
          });
          toast.success(t("otpSentBoth"));
          handleModal("number");
        }
      });
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setNewProfilePic(reader.result);
          setUserContext((prev) => ({
            ...prev,
            profilePic: e.target.files[0],
          }));
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const editPageOptions = [
    {
      image: locationIconBlue,
      title: t("profile.address"),
      link: "/myaddress",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      dob: displayInfo.user_Birth_Date,
      email: displayInfo.user_Email,
      fullName: displayInfo.user_Full_Name,
    };

    updateUser(data).then((res) => (res ? toast.success(t("detailsUpdated")) : toast.error(t("incompleteData"))));

    saveUserPic(userContext.newProfilePic)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(t("profileUpdated"));
        }
      })
      .catch((err) => console.log(err));

    getUser().then((res) => {
      if (res) {
        let user = res;
        setUserContext((prev) => ({
          ...prev,
          id: user._id,
          fullName: displayInfo.user_Full_Name,
          mobileNumber: displayInfo.user_ph_Number,
          email: displayInfo.user_Email,
          dob: displayInfo.user_Birth_Date,
        }));
      }
    });
  };

  const handleDate = (e, type, key) => {
    type((prev) => ({ ...prev, [key]: e }));
  };

  const validateForm = () => {
    displayInfo.user_Full_Name !== "" && displayInfo.user_ph_Number !== "" && displayInfo.user_Email !== "" && selectedDay !== null && userContext.profilePic && profilePicUpdate
      ? setDisabled(false)
      : setDisabled(true);
  };

  const handleModal = (prop) => {
    if (prop === "email") {
      setModalData((prev) => {
        return { ...prev, number: false, oldData: secondaryData, newData: displayInfo };
      });
    } else {
      setModalData((prev) => {
        return {
          ...prev,
          number: true,
          oldData: secondaryData,
          newData: displayInfo,
        };
      });
    }
    setShowModal(true);
  };

  const handleInput = (prop, e) => {
    e.target ? setDisplayInfo({ ...displayInfo, [prop]: e.target.value }) : setDisplayInfo({ ...displayInfo, [prop]: e.label });
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="page_Wrapper edit_Page_Wrapper">
        {profileDetails && (
          <div className="profile_User_Details">
            <div className="cursor-pointer" onClick={() => navigate("/profile")}>
              <div className="user_Profile_Pic_Container">
                <div className="user_Profile_Pic">
                  <img src={profilePic} alt="" />
                </div>
                <div className="user_Camera_Icon">
                  <img src={cameraIcon} alt="" />
                  <form action="" encType="multipart/form-data">
                    <input type="file" name="Profile Image" id="Profile Image" onChange={handleImageChange} className="profile_Image" accept=".jpg, .jpeg, .png" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        <form action="" className="profile_edit_form" onChange={validateForm} onSubmit={handleSubmit}>
          <div className="edit_input_container">
            <label className="edit_input_label">{t("login.name")}</label>
            <input type="text" className="input-field" value={displayInfo.user_Full_Name} onChange={(value) => handleInput("user_Full_Name", value)} />
          </div>
          <div className="edit_input_container">
            <label className="edit_input_label">{t("login.phone")}</label>
            <input type="number" maxLength={10} className="input-field numeric-text" value={displayInfo.user_ph_Number} onChange={(value) => handleInput("user_ph_Number", value)} />
            {matches ? (
              <button
                disabled={displayInfo.user_ph_Number === secondaryData.user_ph_Number}
                className="edit_input_update"
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdate("number");
                }}
              >
                {t("update")}
              </button>
            ) : (
              <Link to={"/update-details/number"} className="edit_input_update" onClick={() => handleUpdate("number")}>
                {t("update")}
              </Link>
            )}
          </div>
          <div className="edit_input_container">
            <label className="edit_input_label">{t("login.email")}</label>
            <input type="text" className="input-field" value={displayInfo.user_Email} onChange={(value) => handleInput("user_Email", value)} />
          </div>
          <div className="edit_input_container">
            <label className="edit_input_label">{t("login.birthday")} (dd/mm/yyyy)</label>
            <div>
              <DatePicker
                value={displayInfo.user_Birth_Date}
                onChange={(e) => handleDate(e, setDisplayInfo, "user_Birth_Date")}
                format="dd/MM/yyyy"
                maxDate={new Date()}
                className={"input-field custom-date-picker"}
              />
            </div>
          </div>
          {matches && (
            <div>
              <button type="submit" className="submit-button profile_Submit_Button" disabled={false}>
                <p>{t("save-details")}</p>
              </button>
            </div>
          )}
        </form>
        <div className="profile_Options edit_Extra_Options">
          {editPageOptions.map((option, index) => (
            <Link to={option.link} className={`profile_Option edit_Profile_Option ${option.title === "Logout" ? "logout_Styles" : ""}`} key={index}>
              <div>
                <img src={option.image} alt="" />
                <p>{option.title}</p>
              </div>
              <img src={arrowRightBlue} alt="" className="profile_arrow" />
            </Link>
          ))}
        </div>
        <div className="address_Footer tab_None">
          <button type="submit" className="submit-button" onClick={() => handleSubmit()} disabled={disabled}>
            <p>{t("save-details")}</p>
          </button>
        </div>
      </div>
      {matches && <UpdateModal showModal={showModal} setShowModal={setShowModal} modalData={modalData} setModalData={setModalData} />}
    </>
  );
};

export default EditDetails;
