import React, { useEffect, useRef, useState, useMemo } from "react";
import Section4 from "../components/Section4/Section4";
import Section1 from "../components/Section1/Section1";

// React-Router
import { useLocation, useNavigate } from "react-router-dom";

//Images
import { getHomePageSections } from "../api/AdminApis/HomePageSections";
import useSiteCtx from "../hooks/useSiteCtx";
import useProductCtx from "../hooks/useProductCtx";

// Utils
import { PRODUCT_CLASSIFICATION } from "../utils/Constants";
import Banners from "../components/Banners/Banners";
import DealsSection from "../components/DealsSection";

// Toast
import { toast } from "react-toastify";
import { getSearchedProduct } from "../api/Product";
import useUserCtx from "../hooks/useUserCtx";
import SEO from "../components/SEO/SEO";
import SkeletonElement from "../components/Skeletons/SkeletonElement";
import { useMediaQuery } from "@mui/material";

// Component Definition
const Home = () => {
  // hooks
  // const { allProducts } = useProductCtx();
  const location = useLocation();
  const navigate = useNavigate();
  const sectionRefs = [useRef(null), useRef(null), null, useRef(null), useRef(null)];
  const [visibleSections, setVisibleSections] = useState([]);
  const [scrollCardSections, setScrollCardSections] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [staticDealMobile, setStaticDealMobile] = useState([]);
  const [staticDeals, setStaticDeals] = useState([]);
  const [bannersArr, setBannersArr] = useState([]);
  const { seoData } = useSiteCtx();

  const isMobile = useMediaQuery("(max-width:700px)");

  useEffect(() => {
    getHomePageSections((err, res) => {
      if (err) return console.log(err);

      const filteredArray = Array.from(res.sections).filter((ele, index) => {
        return index !== 0 && ele.sectionHeading;
      });
      const updatedArr = filteredArray.filter((ele, i) => {
        return !ele?.sectionHeading.split("").includes("_");
      });
      let staticDealMobile = updatedArr.filter((item) => item.sectionType === "static_deals_mobile").map((item) => item.products);
      let staticDeal = updatedArr.filter((item) => item.sectionType === "static_deals").map((item) => item.products);
      setStaticDealMobile(staticDealMobile[0]);
      setStaticDeals(staticDeal[0]);
      setScrollCardSections(updatedArr);
      var arr = [];

      const topSectionPosters = res.sections.find((section) => section.sectionType === "static_poster");
      if (topSectionPosters) {
        const updatedProducts = topSectionPosters.products.filter((product) => !product.title.includes("_"));
        arr.push(...updatedProducts);
        setBannersArr(arr);
      }

      if (location.search) {
        handleLogoutRedirection(location);
      }
    });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.append("homepageProduct", true);

    getSearchedProduct(params).then((res) => {
      const products = res.products.filter((prod) => prod.classification !== PRODUCT_CLASSIFICATION.TEMP_HIDDEN);
      setAllProducts(products);
    });
  }, []);

  const handleLogoutRedirection = (location) => {
    const searchQuery = new URLSearchParams(location.search.slice(1));
    if (searchQuery.has("action") && searchQuery.get("action") === "logout") {
      toast.error("User logged out due to token expiration! Please login again.");
      navigate("/");
    }
  };

  useEffect(() => {
    const createObserver = (sectionRef, index) => {
      if (!sectionRef || !sectionRef.current || visibleSections.includes(index)) return null;
      return new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setVisibleSections((prev) => [...new Set([...prev, index])]);
              if (observers[index]) {
                observers[index].unobserve(sectionRef.current);
              }
            }
          });
        },
        { threshold: 0 } // Adjust the threshold as needed
      );
    };

    // Create and store the observers for each sectionRef
    const observers = sectionRefs.map((sectionRef, index) => createObserver(sectionRef, index));

    // Start observing each target section
    sectionRefs.forEach((sectionRef, index) => {
      if (sectionRef && sectionRef.current && !visibleSections.includes(index)) {
        observers[index].observe(sectionRef.current);
      }
    });

    // Cleanup: Stop observing when the component unmounts
    return () => {
      sectionRefs.forEach((sectionRef, index) => {
        if (sectionRef && sectionRef.current && observers[index]) {
          observers[index].unobserve(sectionRef.current);
        }
      });
    };
  }, [sectionRefs]);

  // Main renderer
  return (
    <div className="homepage_wrapper">
      <SEO seoData={seoData} />

      <Section1 id={"homepage_hero"} />
      {bannersArr.length > 0 ? <Banners banners={bannersArr} /> : <SkeletonElement type={"productBanner"} height="250px" />}

      {scrollCardSections.length > 0 ? (
        <>
          {scrollCardSections?.map((sec, i) => {
            if (sec.sectionType === "static_deals_mobile" && isMobile) {
              return <DealsSection heading={sec.sectionHeading} products={staticDealMobile} section={sec.sectionType} key={`Deals_Wrapper_${i}`} />;
            } else if (sec.sectionType === "static_deals" && !isMobile) {
              return <DealsSection heading={sec.sectionHeading} products={staticDeals} section={sec.sectionType} key={`Deals_Wrapper_${i}`} />;
            } else if (sec.sectionType == "scroll_card" && !isMobile) {
              return (
                <div ref={sectionRefs[i]} data-ref={`index_${i}`} key={`Home_Page_Sections_${i}`}>
                  <Section4
                    id={`product-card-${i}`}
                    heading={sec.sectionHeading}
                    posterUrl={sec?.products[0]?.imgUrl}
                    products={allProducts}
                    isVisible={visibleSections.includes(i)}
                    targetUrl={sec?.products[0]?.targetUrl}
                    sectionType={sec?.sectionType}
                  />
                </div>
              );
            } else if (sec.sectionType == "scroll_card_mobile" && isMobile) {
              return (
                <div ref={sectionRefs[i]} data-ref={`index_${i}`} key={`Home_Page_Sections_${i}`}>
                  <Section4
                    id={`product-card-${i}`}
                    heading={sec.sectionHeading}
                    posterUrl={sec?.products[0]?.imgUrl}
                    products={allProducts}
                    isVisible={visibleSections.includes(i)}
                    targetUrl={sec?.products[0]?.targetUrl}
                    sectionType={sec?.sectionType}
                  />
                </div>
              );
            }
          })}
        </>
      ) : (
        <>
          {new Array(4).fill(true).map((_, index) => (
            <SkeletonElement type={"productBanner"} height="490px" />
          ))}
        </>
      )}
    </div>
  );
};

export default Home;
