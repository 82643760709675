import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addNewCardSection, getHomePageSections, updateCardSection } from "../../api/AdminApis/HomePageSections";
import useAdminCtx from "../../hooks/useAdminCtx";

import { Button, Card } from "react-bootstrap";

const ScrollCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [posterid, setPosterId] = useState(null);
  const [data, setData] = useState({
    title: "",
    products: [],
    priority: null,
    targetUrl: "",
    image: null,
    imageUrl: null,
    title: "",
    isMobileViewPoster: false,
  });
  const { allHomePageSections, setAllHomePageSections } = useAdminCtx();
  useEffect(() => {
    getHomePageSections((err, res) => {
      if (err) toast.error("Unable to Fetch the Sections properly");
      console.log(res.sections);
      setAllHomePageSections(res.sections);
    });
  }, []);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      title: location.state?.data?.products[0]?.title ?? "",
      image: location.state?.data?.image ?? [],
      imageUrl: location.state?.data?.products[0]?.imgUrl ?? null,
      priority: location.state?.data?.priority ?? null,
      targetUrl: location.state?.data?.products[0]?.targetUrl ?? "",
      isMobileViewPoster: location.state?.data?.sectionType === "scroll_card_mobile" ? true : false,
    }));
    setPosterId(location.state?.data?._id ?? null);
  }, [location.state?.data]);

  const handleHeadingChnage = (e) => {
    setData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleTargetUrlChange = (e) => {
    setData((prev) => {
      return { ...prev, targetUrl: e.target.value };
    });
  };

  //   const handleSubmit = (e) => {
  //     const newData = {
  //       sectionHeading: data.sectionHeading,
  //       products: [image.image],
  //       sectionType: "scroll_card",
  //       moreInfo: {},
  //       targetUrl: data.targetUrl,
  //     };
  //     console.log("newData ", newData);
  //     if (id) {
  //       if (location.state.data.priority === data.priority) {
  //         newData.priority = location.state.data.priority;
  //       } else {
  //         newData.priority = data.priority;
  //       }
  //       updateCardSection(newData, id).then((res) => {
  //         if (res) {
  //           navigate("../admin-home-page");
  //           toast.success("ScrollCard Updated!!");
  //         } else toast.error("Something Went wrong");
  //       });
  //     } else {
  //       newData.priority = !data.priority ? (allHomePageSections.length === 0 ? 1 : allHomePageSections[allHomePageSections.length - 1].priority + 1) : data.priority;
  //       addNewCardSection(newData).then((res) => {
  //         if (res) {
  //           navigate("../admin-home-page");
  //           toast.success("ScrollCard added!!");
  //         } else toast.error("Oops! Something Went wrong");
  //       });
  //     }
  //   };
  const handleSubmit = () => {
    if (!data.title) return toast.error("Please Enter the Title!");
    if (!data.targetUrl) return toast.error("Please Enter the Target URL!");
    if (!location.state.updateProduct && !data.image) return toast.error("Please Choose an Image!");

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("targetUrl", data.targetUrl);
    formData.append("sectionType", data.isMobileViewPoster ? "scroll_card_mobile" : "scroll_card");
    formData.append("priority", allHomePageSections.length === 0 ? 0 : data.priority);

    if (!location.state.updateProduct) {
      formData.append("image", data.image);
    } else if (location.state.updateProduct) {
      formData.append("oldImgKey", data.oldImgKey);
      formData.append("Id", posterid);
      data.image && formData.append("image", data.image);
    }

    // setSubmitBtnLoading(true);

    updateCardSection(formData, !location.state.updateProduct, (err, res) => {
      // setSubmitBtnLoading(false);
      if (err) {
        toast.error(err.data?.message ?? "Something Went Wrong!");
        return;
      }
      if (res.status === 200) {
        toast.success(res.data.message || "Success!");
        navigate(location.state.from);
      }
    });
  };

  const handleRemoveCard = () => {};

  const deleteSelectedImage = () => {};

  const handleFileUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setData({ ...data, image: e.target.files[0], imageUrl: reader.result });
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      if (location.state.updateProduct) {
        setData({ ...data, imageUrl: location.state.data.products[0] });
      }
    }
  };

  const handleCheckboxChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <h2>Add Scroll Card Section (Heading Poster)</h2>
      <Card className="mb-3">
        <Card.Header style={{ display: "flex", justifyContent: "space-between" }}>
          <Card.Title>Poster For - {data.title}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Poster Title *</Form.Label>
            <Form.Control type="text" name="title" value={data?.title} onChange={(e) => handleHeadingChnage(e)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Target URL *</Form.Label>
            <Form.Control type="text" name="targetUrl" value={data?.targetUrl} onChange={(e) => handleHeadingChnage(e)} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Static Mobile View Banner"
              name="isMobileViewPoster"
              checked={data.isMobileViewPoster}
              onChange={(e) => {
                const { name, checked } = e.target;
                handleCheckboxChange({ target: { name, value: checked } });
              }}
            />
            {data.isMobileViewPoster ? <p>Size - 500px X 75px</p> : <p>Size - 440px X 1060px</p>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Order (Optional)</Form.Label>
            <Form.Control type="text" name="priority" value={data?.priority} onChange={(e) => handleHeadingChnage(e)} />
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <label htmlFor="staticBanner-upload" className="custom-fileSelect">
              Select Banner Image *
            </label>
            <input type="file" name="Gallery Images" id="staticBanner-upload" accept=".jpg, .jpeg, .png .webp" onChange={handleFileUpload} hidden />
          </Form.Group>
          {data.imageUrl && (
            <>
              <div className="mx-auto d-flex flex-column gap-2">
                <Form.Label className="w-100">Preview</Form.Label>
                <img width="400" src={data?.imageUrl} height={"400"} style={{ objectFit: "contain" }} alt="image" className="mx-auto" />
              </div>
            </>
          )}
        </Card.Body>
      </Card>
      <div className="my-5">
        <button type="submit" className="submit-button" onClick={handleSubmit}>
          <p>{"Submit"}</p>
        </button>
      </div>
    </div>
  );
};

export default ScrollCard;
