import React, { useMemo } from "react";

const Pagination = ({ productsPerPage, totalProducts, pageChange, currentPage }) => {
  const pageNumbers = useMemo(() => {
    const arr = [];
    for (let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++) {
      arr.push(i);
    }
    return arr;
  }, [totalProducts, productsPerPage]);

  const activeStyle = { backgroundColor: "white", color: "#007bff", cursor: "pointer" };
  return (
    <nav>
      <ul className="pagination flex-wrap">
        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <a className="page-link" style={currentPage == number ? { ...activeStyle, backgroundColor: "#007bff", color: "white" } : { ...activeStyle }} onClick={(e) => pageChange(e, number)}>
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
