import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

//CSS
import "./MyCart.css";

//Components
import CartSection from "./CartSection";
import { getCartData, addToCart as updateCart, removeFromCart, addToCart } from "../../api/Cart";
import { addSaveForLaterItem, getSaveForLater, deleteSaveForLaterItem } from "../../api/SaveForLaterApi";
import { toast } from "react-toastify";
import useUserCtx from "../../hooks/useUserCtx";
import { useTranslation } from "react-i18next";
import { currencyFormat, formatNumber, isOfferActive } from "../../utils";

//mui
import { Box, Modal, Typography, useMediaQuery } from "@mui/material";
import CartHand from "../../assets/vector/cartHandIcon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProductCard from "../../components/Cards/ProductCard";
import editIcon from "../../assets/vector/edit_outline_blue.svg";

// API
import { checkProdServiceabilityStatus, getIndiProduct, getSearchedProduct, suggestedProducts } from "../../api/Product";

// Utils
import { PAYMENT_STATUS } from "../../utils/Constants";

//icons
import { FaRegSquarePlus } from "react-icons/fa6";
import AddAddress from "../AddAddress/AddAddress";
import { getAddress, setAddressDefault } from "../../api/Address";
import login from "../../assets/png/login.jpg";
import CartProductCard from "../../components/CartProductCard/CartProductCard";
import SEO from "../../components/SEO/SEO";
import useSiteCtx from "../../hooks/useSiteCtx";
import CouponModal from "../../components/CouponModal";
import { getWalletCupon } from "../../api/AdminApis/Cupon";
import { getValidCoupons } from "../../api/Auth";

// Component definitions
const MyCart = () => {
  const { t } = useTranslation();
  const {
    cartArray,
    setUserComboCart,
    setOrderInit,
    setCartArray,
    userContext,
    userWishlist,
    setUserSaveForLater,
    userZip,
    userSaveForLater,
    priceBoxDetails,
    setUserAddress,
    userAddress,
    setPaymentProductArr,
    appliedCoupon,
    payuOffers,
    deliveryCharges,
    userPreBooked,
    setPriceBoxDetails,
  } = useUserCtx();
  const { seoData } = useSiteCtx();

  const [show, setShow] = useState(false);
  const [coupons, setCoupons] = useState([]);

  const [emptyCartFeaturedProducts, setEmptyCartFeaturedProducts] = useState([]);
  const [noOfAddress, setNoOfAddress] = useState(0);
  const nav = useNavigate();
  const isMobile = useMediaQuery("(max-width: 540px)");

  // const location = useLocation();
  const [searchParams] = useSearchParams();
  // const [coupon, setCoupon] = useState("");
  const [openAddInstruction, setOpenAddInstruction] = useState(false);
  // products that are added as combo offer to product in cart
  const [comboProducts, setComboProducts] = useState([]);
  const [prodIdForCoupon, setProdIdForCoupon] = useState("");

  useEffect(() => {
    let userToken = userContext ? userContext.JWT : "";
    if (userToken !== "") {
      const paymentStatus = searchParams.get("paymentStatus");
      const reason = searchParams.get("reason");
      if (!paymentStatus || !reason) return;
      if (paymentStatus === PAYMENT_STATUS.FAILURE) {
        setTimeout(() => {
          toast.error(t("cart.payment-failed"), {
            toastId: "payment-fail",
          });
        }, 200);
        nav("/mycart", { replace: true });
      }
    }
  }, [userContext]);

  useEffect(() => {
    suggestedProducts().then((res) => {
      setEmptyCartFeaturedProducts(res.data?.status === "success" ? res.data.productsWithRatings : []);
    });
  }, []);

  useEffect(() => {
    let userToken = userContext ? userContext.JWT : "";
    if (userToken !== "") {
      fetchNSetCartDetails();
    } else {
      getProductFromId();
    }
  }, [userContext]);

  useEffect(() => {
    let userToken = userContext ? userContext.JWT : "";
    if (userToken !== "") {
      getSaveForLater().then((res) => {
        if (res) {
          setUserSaveForLater({
            loaded: true,
            no_of_save_for_later_items: res.no_of_save_for_later_items,
            save_for_later_items: res.save_for_later_items,
          });
        }
      });
    }
  }, [userContext]);

  const getProductFromId = async () => {
    const prevCartArr = JSON.parse(localStorage.getItem("cart")).cart;

    // Map each product ID to a promise that fetches individual product data
    if (prevCartArr) {
      const fetchPromises = await prevCartArr.map((prod) => fetchProductData(prod._id));

      // Use Promise.all to execute all promises concurrently
      const productDataArray = await Promise.all(fetchPromises);

      productDataArray.forEach((prod) => {
        prevCartArr.forEach((prevProd) => {
          if (prod._id === prevProd._id) {
            prod.quantity = prevProd.quantity;
          }
        });
      });

      setCartArray({
        loaded: true,
        no_of_carts: productDataArray.length,
        cart: productDataArray,
        combo: [],
      });
    }
  };

  const fetchProductData = async (productId) => {
    try {
      return await getIndiProduct(productId);
    } catch (error) {
      // Handle errors if any
      console.error(`Error fetching product data for ID ${productId}:`, error);
      return null; // or any other default value
    }
  };
  const fetchNSetCartDetails = async () => {
    const res = await getCartData();

    if (res) {
      let prod = [];
      for (const item of res.cart) {
        if (item?.productId) {
          let product = { ...item?.productId };
          product["quantity"] = item.qty;
          product["from"] = item.from;
          product["coupon"] = item.couponId ? item.couponId : {};
          const combo = item?.productId?.discount?.combo;
          // fetch child product detail for combo offer if any
          if (combo?.value > 0 && isOfferActive(combo)) {
            const cProd = await fetchComboProduct(combo.childEan);
            const cProdArr = product["comboProductDetails"] ?? [];
            const comboProdsSet = new Set([...cProdArr, cProd]);
            product["comboProductDetails"] = [...comboProdsSet];
            setComboProducts([...comboProdsSet]);
          }
          prod.push(product);
        }
      }

      setCartArray({
        loaded: true,
        no_of_carts: prod.length,
        cart: prod,
        combo: res.combo,
      });
    }
  };

  const addCoupon = async (prod) => {
    setShow(true);
    setProdIdForCoupon(prod?._id);
    getSearchedProduct("slug=" + prod?.slug)
      .then((res) => {
        if (res) {
          const activeCoupons = res?.coupons.filter((coupon) => {
            return coupon.userStatus[0].status === "active";
          });

          if (cartArray.cart.some((cartItem) => cartItem.coupon?._id)) {
            const updatedCoupons = activeCoupons.map((coupon) => {
              const matchingCartItem = cartArray.cart.find((cartItem) => cartItem.coupon?._id === coupon._id);
              if (matchingCartItem) {
                return { ...coupon, status: "applied" };
              }
              return coupon;
            });
            setCoupons(updatedCoupons);
          } else {
            setCoupons(activeCoupons);
          }
        } else {
          setCoupons([]);
        }
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };

  const removeCoupon = async (prodId) => {
    updateCoupon("", prodId);
  };

  // fetches the child product in combo offer
  const fetchComboProduct = async (productEan) => {
    const response = await getSearchedProduct(`ean=${productEan}`);
    const product = response.products[0];
    return product;
  };

  const checkThisProductServiceability = async (pincode, productData) => {
    if (pincode || productData.ean || productData.product_Ean) {
      const item = {
        ean: productData.product_Ean ? productData.product_Ean : productData.ean,
        title: productData.product_name ? productData.product_name : productData.title,
        quantity: productData?.quantity,
      };

      const response = await checkProdServiceabilityStatus(item, pincode);
      const payload = response.data;
      if (payload.status === "success") {
        return response.data;
      } else {
        const cartPro = document.getElementById(productData._id);
        cartPro.style.border = "2px solid red";
        var obj = { data: false };
        return obj;
      }
    }
  };

  const handlePayOutClick = () => {
    if (userContext && userContext?.JWT) {
      if (!userZip.value) {
        toast.error(t("NoUserPincode"));
        return;
      }

      var resultArr = [];
      const promises = cartArray.cart.map(async (prod) => {
        const res = await checkThisProductServiceability(userZip.value, prod);
        if (res || res.data) {
          resultArr.push(res.data);
        }
      });

      Promise.all(promises).then(() => {
        // Check if all elements in resultArr are true
        if (resultArr.length === cartArray.cart.length) {
          const allTrue = resultArr.every((result) => result === true);
          if (allTrue) {
            localStorage.setItem("paymentProductArr", JSON.stringify({ loaded: true, cart: cartArray.cart, combo: cartArray.combo }));
            setPaymentProductArr({ loaded: true, cart: cartArray.cart, combo: cartArray.combo });
            nav("/payment");
          } else {
            toast.error(t("productNotServicable"));
            return;
          }
        }
      });
    } else {
      nav("/login");
    }
  };

  const handleSetAsDefaultAddress = (id) => {
    setAddressDefault(id).then((res) => {
      getAddress().then((res) => {
        if (res) {
          setUserAddress({
            loaded: true,
            no_of_address: res.no_of_address,
            address: res.address,
          });
        }
      });
    });
  };

  const handleAddItemToSaveForLater = (id) => {
    addSaveForLaterItem(id).then((res) =>
      res
        ? (toast.success(t("cart.addedToSaveForLater")),
          removeFromCart(id).then((res) =>
            res
              ? (setUserComboCart([]),
                toast.error(t("cart.remove-from-cart")),
                getCartData().then((res) => {
                  if (res) {
                    let prod = [];
                    res.cart.forEach((item, idx) => {
                      if (item?.productId === null) return;
                      let product = { ...item?.productId };
                      product["quantity"] = item.qty;
                      product["coupon"] = item.couponId ? item.couponId : {};
                      product["from"] = item.from;
                      prod.push(product);
                    });
                    setCartArray({
                      loaded: true,
                      no_of_carts: prod.length,
                      cart: prod,
                      combo: res.combo,
                    });
                  }
                }))
              : ""
          ),
          getSaveForLater().then((res) => {
            setUserSaveForLater({
              loaded: true,
              no_of_save_for_later_items: res.no_of_save_for_later_items,
              save_for_later_items: res.save_for_later_items,
            });
          }))
        : toast.error(t("writeToUs.error"))
    );
  };

  const handleRemoveFromSaveForLater = (id) => {
    deleteSaveForLaterItem(id).then((res) =>
      res
        ? (toast.success(t("cart.removeFromSaveForLater")),
          getSaveForLater().then((res) => {
            setUserSaveForLater({
              loaded: true,
              no_of_save_for_later_items: res.no_of_save_for_later_items,
              save_for_later_items: res.save_for_later_items,
            });
          }))
        : toast.error(t("writeToUs.error"))
    );
  };

  const pageSwitch = (e) => {
    e.preventDefault();
    nav("/");
  };

  const handleOrderInit = (e) => {
    e.preventDefault();
    if (cartArray.no_of_carts > 0) {
      let productId = [];
      let quantity = [];
      cartArray.cart.forEach((item) => productId.push(item._id));
      cartArray.cart.forEach((item) => quantity.push(parseInt(item.quantity)));
      setOrderInit((prev) => ({
        ...prev,
        productId: productId,
        quantity: quantity,
      }));
      nav("/delivery-option");
    } else {
      toast.error(t("cart.add-to-cart"));
    }
  };

  const handleAddToCart = (id, prodData) => {
    updateCart({ id, qty: 1, onsiteGoSelected: prodData?.hierarchyL2 === "Smartphone" ? (process.env.REACT_APP_BASE_URL === "https://alpha-api.olineo.in/api" ? [18588] : [3507]) : null }).then(
      (res) => {
        if (!res) return toast.success(t("writeToUs.error"));
        toast.success(t("product-page.added-to-cart"));
        deleteSaveForLaterItem(id).then((res) => {
          getSaveForLater().then((res) => {
            setUserSaveForLater({
              loaded: true,
              no_of_save_for_later_items: res.no_of_save_for_later_items,
              save_for_later_items: res.save_for_later_items,
            });
          });
        });
        getCartData().then((res) => {
          if (res) {
            let prod = [];
            res.cart.forEach((item, idx) => {
              if (item?.productId === null) return;
              let product = { ...item?.productId };
              product["quantity"] = item.qty;
              product["from"] = item.from;
              product["coupon"] = item.couponId ? item.couponId : {};
              prod.push(product);
            });
            setCartArray({
              loaded: true,
              no_of_carts: prod.length,
              cart: prod,
              combo: res.combo,
            });
          }
        });
      }
    );
  };

  const updateCoupon = async (appliedCouponId, prodId) => {
    let id;
    if (prodId) {
      id = prodId;
    } else {
      id = prodIdForCoupon;
    }
    const productQuantity = cartArray.cart.find((cart) => cart._id === id).quantity;

    addToCart({ id, qty: productQuantity, brand_store_id: 0, onsiteGoSelected: "", appliedCouponId }).then((res) => {
      if (res) {
        setCoupons((coupons) =>
          coupons.map((coupon, i) => {
            if (coupon._id === appliedCouponId) {
              return { ...coupon, status: "applied" };
            } else {
              return { ...coupon, status: "" };
            }
          })
        );
        setShow(false);
        fetchNSetCartDetails();
      }
    });
  };
  // main renderer
  return (
    <div className="page_Wrapper">
      <SEO seoData={seoData} />
      {/* <BreadCrumbs data={breadCrumbsData} /> */}
      {/* removing save for later items condition for now */}

      <div className="w-100 h-100">
        <CartSection comboProducts={comboProducts} resetCartDetails={fetchNSetCartDetails} addCoupon={addCoupon} removeCoupon={removeCoupon} />

        {/* coupon section */}
        <div className="d-flex " style={{ gap: "15px", flexDirection: `${isMobile ? "column" : ""}`, padding: `${isMobile ? "0px 15px" : ""}` }}>
          {userContext && userAddress.loaded ? (
            <div className="d-flex justify-content-evenly flex-column flex-lg-row px-2 px-lg-2 aside-total-price" style={{ width: "50%" }}>
              <div className="address-detail-con user-cart-address-itm d-block d-md-flex  flex-column  ">
                {userAddress?.address ? (
                  <>
                    <div className="text-end cursor-pointer text-xs text-link justify-content-between d-flex" style={{ alignItems: "center" }}>
                      <span className="fw-bold" id="cart-heading">
                        {userAddress.no_of_address} Delivery address Found
                      </span>
                      <span onClick={() => nav("/profile/yourAddresses/newAddress")} style={{ fontSize: "14px" }}>
                        Add New Address <FaRegSquarePlus />
                      </span>
                    </div>

                    <div id="cart-address-detail">
                      <div style={{ background: "#f8f8f8" }}>
                        <div>
                          <span></span>
                          <span>Your Addresses</span>
                        </div>
                        <div>
                          {userAddress.address.map((address, index) => (
                            <div key={index} className="cursor-pointer">
                              <span>
                                <input type="checkbox" checked={address.isDefault} onClick={() => handleSetAsDefaultAddress(address._id)} />
                              </span>
                              <div className="d-flex justify-content-between py-2" style={{ width: "100%", borderBottom: `${index + 1 !== userAddress.address.length && "1px solid"} `, gap: "5px" }}>
                                <div className="" style={{ fontSize: "small" }} onClick={() => handleSetAsDefaultAddress(address._id)}>
                                  <span>
                                    {address.address_line1} {address.landmark} {address.city} - {address.zip}{" "}
                                  </span>
                                  <br />
                                  <span>{address.country}</span>
                                </div>
                                <Link to={"/editaddress"} state={address}>
                                  <img src={editIcon} alt="" />
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <span onClick={() => nav("/profile/yourAddresses/newAddress")} className="align-items-center cursor-pointer text-link mt-3 fs-6 fs-lg-5" id="cart-address-detail2">
                    Add new address <FaRegSquarePlus />
                  </span>
                )}
                <Modal open={openAddInstruction} onClose={() => setOpenAddInstruction(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                  {/* <AddressModal/> */}
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: isMobile ? "340px" : "600px",
                      display: "flex",
                      bgcolor: "background.paper",
                      alignItems: "center",
                      justifyContent: "center",
                      p: 4,
                      outline: "none",
                      padding: isMobile ? "10px" : "30px 20px",
                    }}
                  >
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{
                        fontSize: isMobile ? "14px" : "17px",
                        width: "30%",
                      }}
                    >
                      Add your Address
                      <FaRegSquarePlus />
                    </Typography>
                    <input style={{ width: "70%" }} type="text" className="addressModalInput" />
                  </Box>
                </Modal>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center" style={{ width: `${isMobile ? "100%" : "50%"}` }}>
              <Link to="/login">
                <img height={"320px"} src={login} />
              </Link>
            </div>
          )}
          <div className="cart-coupon flex-column flex-md-row " style={{ width: `${isMobile ? "100%" : "50%"}` }}>
            <div className="aside-total-price user-cart-address-itm">
              <div className="w-100 fs-6 px-4 py-3 d-flex justify-content-center flex-column fw-bold">
                <div className="final-price-comp d-flex justify-content-between">
                  <span className="price-item">Subtotal</span>
                  <span className="text-gray w-25">₹{formatNumber(priceBoxDetails.cartItemsPrice)}</span>
                </div>
                <hr className="border-secondary opacity-75 my-2" style={{ width: "100%" }} />
                <div className="final-price-comp d-flex justify-content-between">
                  <span className="price-item">Pre Book Amount</span>
                  <span className="text-gray w-25" style={{ whiteSpace: "nowrap" }}>
                    {priceBoxDetails.totalPreBookAmount > 0 ? <>₹{formatNumber(priceBoxDetails.totalPreBookAmount)}</> : <> {t("cart.none")}</>}
                  </span>
                </div>
                <hr className="border-secondary opacity-75 my-2" style={{ width: "100%" }} />
                <div className="final-price-comp d-flex justify-content-between">
                  <span className="price-item">Discount</span>
                  <span className="text-gray w-25" style={{ whiteSpace: "nowrap" }}>
                    ₹{formatNumber(priceBoxDetails.totalDiscount)}
                  </span>
                </div>
                {priceBoxDetails.giftCouponAmount > 0 && (
                  <>
                    <hr className="border-secondary opacity-75 my-2" style={{ width: "100%" }} />
                    <div className="final-price-comp d-flex justify-content-between">
                      <span className="price-item">Gift Coupon Applied</span>
                      <span className="text-gray w-25" style={{ whiteSpace: "nowrap" }}>
                        ₹{formatNumber(priceBoxDetails.giftCouponAmount)}
                      </span>
                    </div>
                  </>
                )}
                <hr className="border-secondary opacity-75 my-2" style={{ width: "100%" }} />
                <div className="final-price-comp d-flex justify-content-between">
                  <span className="price-item">Shipping</span>
                  <span className="text-gray w-25">{currencyFormat(priceBoxDetails.totalDeliveryCharge)}</span>
                </div>
                <hr className="border-secondary opacity-75 my-2" style={{ width: "100%" }} />
                <div className="final-price-comp d-flex justify-content-between">
                  <span className="price-item">Coupon Apply</span>
                  <span className="text-gray w-25">{appliedCoupon?.code || t("cart.none")}</span>
                </div>
                <hr className="border-secondary opacity-75 my-2" style={{ width: "100%" }} />
                <div className="final-price-comp d-flex justify-content-between">
                  <span className="price-item c-clack">TOTAL</span>
                  <span className="text-gray w-25">{currencyFormat(priceBoxDetails.totalAmount)}</span>
                </div>
                <hr className="border-secondary opacity-75 my-2" style={{ width: "100%" }} />
              </div>
              <div>
                <button className="yellow-theme-btn" disabled={cartArray.cart.length <= 0} onClick={() => handlePayOutClick()}>
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          {userSaveForLater.no_of_save_for_later_items > 0 && (
            <div className="">
              <div className="cart_Save_Later">
                <div className="save_Later_Header section_Wrapper">
                  <p className="">{t("cart.saved-for-later")}</p>
                </div>
                <div className="cards_Container">
                  {userSaveForLater.no_of_save_for_later_items > 0 ? (
                    userSaveForLater.save_for_later_items.map((item, index) => (
                      <CartProductCard
                        key={index}
                        product={item}
                        saveForLaterItem={true}
                        // handleQuantityInc={handleQuantityInc}
                        // handleQuantityDec={handleQuantityDec}
                        // handleRemoveFromCart={handleRemoveFromCart}
                        handleAddItemToSaveForLater={handleAddItemToSaveForLater}
                        handleAddToCart={handleAddToCart}
                        handleRemoveFromSaveForLater={handleRemoveFromSaveForLater}
                      />
                    ))
                  ) : (
                    <div className="empty_order_sec">
                      <p className="empty_order_text">{t("cart.empty-save-for-later")}</p>
                      <div>
                        <button type="submit" className="submit-button" onClick={pageSwitch}>
                          <p>{t("start-shopping")}</p>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="cart_Footer ">
                <div className="cart_Footer_Left">
                  {/* <p className="footer_Price">₹{`${priceBoxDetails.totalAmount}`}</p> */}
                  <p className="footer_Left_Text">{t("cart.view-price-details")}</p>
                </div>
                <div className="cart_Footer_Right">
                  <button type="submit" className="submit-button" disabled={cartArray.cart.length <= 0} onClick={handleOrderInit}>
                    <p>{t("cart.checkout")}</p>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/*Frequently products */}
      <div className="suggested-product-container">
        <div className="suggested-heading d-flex justify-content-center">
          <div className="sub-head-cart1  d-flex justify-content-start w-100">
            <span className="text4_xl">{t("FrequentlyBroughtTogether")}</span>
          </div>
          {/* <div className="sub-head-cart2" id="head-bar-sugges"></div> */}
        </div>
        <div className="suggested-products d-flex card-display-row flex-lg-row">
          {emptyCartFeaturedProducts.map((e, i) => (
            <ProductCard
              key={`${i}-${e?._id}`}
              id={e?._id ?? ""}
              productImg={e?.images?.[0] ?? null}
              productName={e?.title}
              productDescription={e?.description?.[0]?.split(" ")?.slice(0, 18)?.join(" ") ?? ""}
              productRating={e?.rating ?? 0}
              productPriceObj={e?.price}
              productDiscountObj={e?.discount}
              productSlug={e?.slug}
              wishlist={userWishlist.wishlist_items?.some((obj) => obj?._id?.toString() === e?._id?.toString())}
              cart={cartArray?.no_of_carts > 0 && cartArray?.cart.some((obj) => obj?._id?.toString() === e?._id?.toString())}
              classification={e?.classification}
              preBookData={e?.preBook}
            />
          ))}
        </div>
      </div>

      {show && <CouponModal show={show} onHide={() => setShow(false)} coupons={coupons} updateCoupon={updateCoupon} />}
    </div>
  );
};

export default MyCart;
