import verifyBtn from "../../assets/bharatmahostav/verify2.svg";
import { useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import OfferContext from "../../Contexts/OffersContext";

import { sendOTPToStore, verifyOTPForStore } from "../../api/OfferAPIs";
import SVGLoader from "./SVGLoader";

function Page2() {
  const { changeOfferId, OfferId, changeStoreCode } = useContext(OfferContext);
  const navigate = useNavigate();
  const [storeId, setStoreId] = useState("");
  const [showVerify, setShowVerify] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [loadingFirst, setLoadingFirst] = useState(false);
  const [loadingSecond, setLoadingSecond] = useState(false);

  const [verifiedForStore, setVerifiedForStore] = useState(null);

  const sendOtpToStoreCall = async () => {
    if (!isVerified) {
      try {
        setLoadingFirst(true);
        const payload = {
          storecode: storeId,
          OfferUserID: OfferId,
        };
        const data = await sendOTPToStore(payload);
        if (data?.status === "error") {
          toast.error(data?.message ?? "Something went wrong");
        } else {
          setVerifiedForStore(payload.storecode);
          // setShowVerify(true);
          setStoreName(data?.display_name);
          toast.info(data?.message);
          data?.OfferUserID && changeOfferId(data.OfferUserID);
          data?.store_code && changeStoreCode(data?.store_code);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message ?? "Something went wrong");
      } finally {
        setLoadingFirst(false);
      }
    }
  };

  const onVerifyOtp = async () => {
    try {
      setLoadingSecond(true);
      const payload = {
        otp: storeId,
        OfferUserID: OfferId,
      };

      const data = await verifyOTPForStore(payload);
      if (data?.status === "error") {
        toast.error(data?.message ?? "Something went wrong");
      } else {
        // setShowVerify(false);
        // setIsVerified(true);
        toast.success(data?.message);
        navigate("/quiz-Offer/3");
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoadingSecond(false);
    }
  };

  return (
    <>
      <div
        className="container sub-container text-center position-relative"
        style={{
          maxWidth: "330px",
        }}
      >
        <TextField
          size="small"
          label="OTP"
          variant="filled"
          inputMode="text"
          className="form-control mt-3 store-input"
          type="text"
          required
          placeholder="OTP"
          style={{ width: "300px", zIndex: "999" }}
          value={storeId}
          onChange={(e) => setStoreId(e.target.value)}
          // InputProps={{
          //   readOnly: isVerified,
          // }}
        />

        <button onClick={onVerifyOtp} type="button" className="btn btn-warning mt-3 w-100 fw-bolder page_1_submit_btn" id="submit-otp-btn" disabled={loadingSecond}>
          {loadingSecond ? <SVGLoader /> : <span>Continue</span>}
        </button>
      </div>
    </>
  );
}

export default Page2;
