import React from "react";
import { ListGroup, Modal } from "react-bootstrap";
import DashboardLoader from "../DashboardContent/DashboardLoader";
import { currencyFormat } from "../../utils";

const OrderProductModal = (props) => {
  const { details, loading = false } = props;
  console.log("details.order ", details?.order);
  return (
    <Modal
      {...props}
      size="lg"
      // centered
      className=""
    >
      <Modal.Header closeButton>
        <Modal.Title>Ordered Details</Modal.Title>
      </Modal.Header>
      {loading ? (
        <DashboardLoader />
      ) : (
        <Modal.Body>
          <div className="row mt-2 mb-4">
            <p className="fw-bold fa-lg">User Details</p>
            <div className="row p-2">
              <div className="col-sm-3">
                <p className="mb-0">Full Name</p>
              </div>
              <div className="col-sm-9">
                <p className="text-muted mb-0 fw-bold">{details?.consignee?.fullName}</p>
              </div>
            </div>
            <hr />
            <div className="row p-2">
              <div className="col-sm-3">
                <p className="mb-0">Email Id</p>
              </div>
              <div className="col-sm-9">
                <p className="text-muted mb-0 fw-bold">{details?.consignee?.email}</p>
              </div>
            </div>
            <hr />
            <div className="row p-2">
              <div className="col-sm-3">
                <p className="mb-0">Contact Number</p>
              </div>
              <div className="col-sm-9">
                <p className="text-muted mb-0 fw-bold">{details?.consignee?.mobileNumber}</p>
              </div>
            </div>
            <hr />
          </div>
          {details.order?.classification === "Pre Booking" ? (
            <div className="row">
              <p className="fw-bold fa-lg">Product Details</p>
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>OPIN</th>
                    <th>Product Name</th>
                    <th>Pre-Book Amount</th>
                    <th>Is Refundable</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center"> 1</td>
                    <td>{details.order?.ean}</td>
                    <td>{details.order?.title}</td>
                    <td>{currencyFormat(details.order?.preBook?.amount)}</td>
                    <td>{details.order?.preBook?.isRefundable ? "YES" : "NO"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="row">
              <p className="fw-bold fa-lg">Product Details</p>
              {details.order?.order?.productDetails?.length === 0 || details.order?.order?.productId?.length === 0 || details.order?.order?.item?.length === 0 ? (
                <p className="d-flex justify-content-center align-items-center p-3">Product Details Not Available</p>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Sr.No.</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th>Consignment id</th>
                    </tr>
                  </thead>
                  <tbody>
                    {details.order?.order?.productDetails?.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td>{item?.name}</td>
                        <td>{item?.purchaseQty}</td>
                        <td>{item?._id}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </Modal.Body>
      )}
    </Modal>
  );
};

export default OrderProductModal;
