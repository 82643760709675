import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Rating, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useUserCtx from "../../hooks/useUserCtx";
import { toast } from "react-toastify";
import { addNewProductComment, getStoreDetailsForDeliveredProducts } from "../../api/Product";
import { useNavigate } from "react-router-dom";

function ProductReviewModal({ open, handleClose, setOpenModal, isMobile, productId, consignmentId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [productRating, setProductRating] = useState(0);
  const [deliveryRating, setDeliveryRating] = useState(0);
  const [reviewData, setReviewData] = useState({
    name: "",
    email: "",
    storeReview: "",
    productReview: "",
  });
  const { userContext } = useUserCtx();
  const [storeDetails, setStoreDetails] = useState({
    fc_name: "",
    brand_store_id: "",
  });
  const onInputChange = (event) => {
    const { name, value } = event.target;
    setReviewData({ ...reviewData, [name]: value });
  };

  useEffect(() => {
    if (consignmentId) {
      getStoreDetailsForDeliveredProducts(consignmentId).then((res) => {
        if (res.status === 200 && res.data?.status === "success") {
          setStoreDetails((prev) => ({ ...prev, fc_name: res.data.details.fc_name, brand_store_id: res.data.details.brand_store_id }));
        }
      });
    }
  }, [consignmentId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        storeReview: reviewData.storeReview,
        productReview: reviewData.productReview,
        userId: userContext.id,
        productRating,
        storeRating: deliveryRating,
        productId: productId,
      };
      const response = await addNewProductComment(payload);
      if (response.data.status === "success") {
        toast.success(t(`notifications.CommentForProductAdded`));
        setReviewData({ ...reviewData, reviewText: "" });
        setDeliveryRating(0);
        setProductRating(0);
        setOpenModal(false);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (userContext.id) {
      setReviewData({
        ...reviewData,
        name: userContext.fullName,
        email: userContext.email,
      });
    }
  }, [userContext]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        zIndex: 100000,
      }}
    >
      <div className="section_Wrapper" style={{ padding: "20px 30px", background: "#F2F2F2", display: "flex", flexDirection: "column" }}>
        <DialogTitle id="scroll-dialog-title">Add A Review</DialogTitle>
        <DialogContent>
          {userContext.id ? (
            <>
              <p className="reviewSubHead mb-3">{t("reviewInstruction")}*</p>
              <Box component="form" onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "15px" }}>
                <TextField
                  required
                  id="name-input"
                  label={t("login.name")}
                  name="name"
                  inputProps={{ readOnly: true }}
                  value={reviewData.name}
                  style={{ background: "white" }}
                  onChange={onInputChange}
                />
                <TextField
                  required
                  id="email-input"
                  label={t("email")}
                  type="email"
                  name="email"
                  inputProps={{ readOnly: true }}
                  style={{ background: "white" }}
                  value={reviewData.email}
                  onChange={onInputChange}
                />
                <Typography component="legend" sx={{ marginBottom: "-1rem" }}>
                  {t("ProductRating")}*
                </Typography>
                <Rating
                  precision={0.5}
                  name="product-rating"
                  value={productRating}
                  onChange={(event, newValue) => {
                    setProductRating(newValue);
                  }}
                />
                <TextField
                  required
                  id="product-review-input"
                  label={t("ProductReview")}
                  multiline
                  rows={4}
                  name="productReview"
                  style={{ background: "white" }}
                  value={reviewData.productReview}
                  onChange={onInputChange}
                />
                {storeDetails.fc_name !== "" && (
                  <Typography component="legend" sx={{ marginBottom: "-1rem", fontWeight: "bold" }}>
                    Store that accepted the order: {storeDetails.fc_name} - {storeDetails.brand_store_id}{" "}
                  </Typography>
                )}
                <Typography component="legend" sx={{ marginBottom: "-1rem" }}>
                  {t("ProductDeliveryRating")}*
                </Typography>
                <Rating
                  precision={0.5}
                  name="delivery-rating"
                  value={deliveryRating}
                  onChange={(event, newValue) => {
                    setDeliveryRating(newValue);
                  }}
                />
                <TextField
                  required
                  id="delivery-review-input"
                  label={t("ProductDeliveryReview")}
                  multiline
                  rows={4}
                  name="storeReview"
                  style={{ background: "white" }}
                  value={reviewData.storeReview}
                  onChange={onInputChange}
                />

                <div className="submit_Button_3" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <button type="submit" className="submit-button" style={{ background: "#1B325E", width: "40%" }}>
                    <p style={{ color: "white" }}>{t("addReview")}</p>
                  </button>
                  <div style={{ width: "70%" }}></div>
                </div>
              </Box>
            </>
          ) : (
            <div className="mt-3 text-center">
              Login to add reviews
              <button className="submit-button py-2 h-auto w-25 mt-3" style={{ background: "#1B325E" }} onClick={() => navigate("/login")}>
                <p style={{ color: "white" }}>Login</p>
              </button>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button className="header_sign_in_btn" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ProductReviewModal;
